import http from './api.core';
import { RequestMethod } from './api.core';
import __constants from '../common/constants';
import { AxiosResponse } from 'axios';


export class TokenManagementAPI {

    tokenManagementSummary(callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementSummaryPage/15/en?_=1650643784890
        // GET https://mobileuat.citibusinessonline.com/mobileTokenManagementSummaryPage/15/en?_=1650650357434
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}mobileTokenManagementSummaryPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            __constants.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : mockTokenManagementSummaryResponse) as Promise<AxiosResponse<any>>;
    }

    tokenRegistrationDetail(callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementListPage/15/en?_=1650643784891
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}mobileTokenManagementListPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            __constants.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : mockTokenRegistrationDetailResponse) as Promise<AxiosResponse<any>>;
    }

    // POST https://mobileuat.citibusinessonline.com/unRegisterTokenSequence/15/en
    // request 
    // {softTokenSerialNumber: "FDM4537645", deviceCode: "34687706935", sequenceNumber: "05"}
    // response
    // {"success":true,"message":""}
    deleteTokenRegistration(postData: any, callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementListPage/15/en?_=1650643784891
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}unRegisterTokenSequence/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            { softTokenSerialNumber: postData.serialNumber, deviceCode: postData.deviceCode, sequenceNumber: postData.sequenceNumber },
            callBack,
            __constants.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : { "success": true, "message": "" }) as Promise<AxiosResponse<any>>;
    }

}

const mockTokenRegistrationDetailResponse = {
    "success": true, "message": "", "body": {
        "header": "Active Registrations",
        "registeredDateHeader": "Registered Date",
        "lastSuccessdulPinAttemptHeader": "Last Successful PIN Attempt",
        "deviceTypeHeader": "Device Type",
        "deviceNameHeader": "Device Name",
        "actionHeader": "Action",
        "notAvailableMeaning": "*Not Available - Please refer to the help for more details",
        "deleteText": "Delete",
        "notAvailable": "Not Available",
        "cancelText": "Cancel",
        "noItemsToDisplay": "No items to display",
        "currentlyActiveList": [
            {
                "registeredDate": "04/25/2022",
                "lastSuccessfulPinAttempt": "04/25/2022",
                "sortKeylastSuccessfulPinAttempt": 1650894624180,
                "deviceName": "moto g pure",
                "deviceType": "motorola moto g pure",
                "screenRowIdentifier": -1,
                "serialNumber": "FDM4537645",
                "deviceCode": "34687706935",
                "sequenceNumber": "05",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device moto g pure. Select Delete to confirm."
            },
            {
                "registeredDate": "04/18/2022",
                "lastSuccessfulPinAttempt": "04/25/2022",
                "sortKeylastSuccessfulPinAttempt": 1650894743985,
                "deviceName": "Steampunk",
                "deviceType": "OnePlus IN2025",
                "screenRowIdentifier": -1,
                "serialNumber": "FDM4537645",
                "deviceCode": "20633834266",
                "sequenceNumber": "04",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device Steampunk. Select Delete to confirm."
            }
        ]
    }
}

const mockTokenManagementSummaryResponse = {
    "success": true, "message": "", "body": {
        "header": "Mobile Token Management",
        "summaryText": "Mobile Token Registration Summary", "currentlyActiveText": "Currently Active",
        "availableForUseText": "Available for use",
        "warningMessageText": "Please note: You can only limit of 10, you wi.",
        "buttonText": "View Details", "currentlyActive": 1, "availableForUse": 9
    }
}

const tokenManagementApi = new TokenManagementAPI()

export default tokenManagementApi;