import _ from "lodash";
import { useEffect, useState } from "react";
import { maskAllExceptUnderLineAndLastFour } from "../../utils/mask.util";
import "./list-card.style.scss";

interface ListCardComponentProps {
    id?: string;
    highlightHeader?: string;
    highlightSubHeader?: any;
    ariaLabelData?: string;
    ariaLabelHeader?: string;
    ariaLabelSubHeader?: string;
    data?: { [key: string]: any };
    onClick?: Function;
    onClickDataContainer?: Function;
    showMask?: boolean // prop to see if the subHeader needs to be masked
    isNotListClickable?: boolean
}

const ListCardComponent = (p: ListCardComponentProps) => {

    /**
     * if isHover true, then show the subHeader as masked
     */
    const [hover, setHover] = useState(false);


    const [subHeaderRole, setSubHeaderRole] = useState('');
    const [subHeader, setSubHeader] = useState('');
    const [subHeaderAriaLabel, setSubHeaderAriaLabel] = useState('');



    useEffect(() => {
        if (p.showMask) {
            if (hover) {
                setSubHeader(p.highlightSubHeader)
                setSubHeaderAriaLabel(p.highlightSubHeader);
            }
            else {
                const maskedValue = maskAllExceptUnderLineAndLastFour(p.highlightSubHeader.toString());
                setSubHeaderAriaLabel(maskedValue)
                setSubHeader(maskedValue)
            }
            setSubHeaderRole('')
        }
        else {
            setSubHeader(p.highlightSubHeader);
            setSubHeaderAriaLabel(p.highlightSubHeader);
            setSubHeaderRole('');
        }
    }, [p.highlightSubHeader, p.showMask, hover])

    const onClick = () => {
        if (p.onClick) {
            p.onClick();
        }
    };

    const onClickDataContainer = () => {
        if (p.onClickDataContainer) {
            p.onClickDataContainer();
        }
    };

    /**
     * method to unmask the subHeader
     * @param e 
     */
    const onHover = (e: any) => {
        e.preventDefault();
        setHover(true)
    }

    /**
     * Method to mask the subHeader
     * @param e 
     */
    const onHoverOver = (e: any) => {
        e.preventDefault();
        setHover(false);
    }


    return (
        <div
            className={`list-card-container ${p.showMask && 'no-cursor'}`}
            id={p.id}
            key={p.id}
            onClick={(e: any) => {
                onClick();
            }}
        >
            <div className="list-highlightHeader"
                tabIndex={0}
                onFocus={(e) => p.showMask ? onHover(e) : ''}
                onBlur={(e) => p.showMask ? onHoverOver(e) : ''}
                aria-label={`${p.highlightHeader ? p.highlightHeader : ''} ${p.highlightSubHeader ? p.highlightSubHeader : ''}`}>

                <span aria-hidden="true">
                    {p.highlightHeader}
                </span>

                {p.highlightSubHeader ? (
                    <span
                        onMouseEnter={(e) => p.showMask ? onHover(e) : ''}
                        onMouseLeave={(e) => p.showMask ? onHoverOver(e) : ''}
                        className={`${p.showMask && 'show-pointer'}`}
                        aria-hidden="true">
                        {subHeader}
                    </span>
                ) : ("")}
            </div>

            <div
                className={`list-data-container ${p.isNotListClickable ? 'cursor-event' : ''}`}
                role={`${p.isNotListClickable ? 'none' : 'button'}`}
                tabIndex={0}
                aria-label={p.ariaLabelData}
                onClick={(e: any) => {
                    onClickDataContainer();
                }}
            >
                {_.map(p.data, (val: string, key: string) => {
                    return (
                        <div className="list-data-subcontainer" key={key} aria-hidden="true">
                            <span aria-hidden="true" className="text-medium key">
                                {key}
                            </span>
                            <span
                                aria-hidden="true"
                                className="text-small accounts-flex accounts-text-right"
                            >
                                {val}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListCardComponent;
