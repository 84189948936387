import __httpApi from './api.core';
import { RequestMethod } from './api.core';
import kGENERAL from "../common/constants/GENERAL";
import kPIWIK from "../common/constants/PIWIK";
import { AxiosResponse } from 'axios';

let MESSAGES_MOCKS: any

export interface MessagesResponse {

}

export class MessagesApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/messages.mock.ts').default
        MESSAGES_MOCKS = m
    }

    messagesMenuPage(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}messagesMenuPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MESSAGES,
            'messagesMenuPage',
            MESSAGES_MOCKS?.MESSAGE_MENU_PAGE) as Promise<AxiosResponse<MessagesResponse>>;
    }

    citibankMessageList(params: { [key: string]: any }, callBack?: Function) {
        //To check pagination
        let mockResponse = MESSAGES_MOCKS?.CITI_BANK_MESSAGE_LIST;
        if (params.displayStartIndex === 15) {
            mockResponse = MESSAGES_MOCKS?.CITI_BANK_MESSAGE_LIST_1;
        } else if (params.displayStartIndex === 30) {
            mockResponse = MESSAGES_MOCKS?.CITI_BANK_MESSAGE_LIST_2;
        }

        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}citibankMessageListPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.MESSAGES,
            'citibankMessageListPage',
            mockResponse) as Promise<AxiosResponse<MessagesResponse>>;
    }

    citibankMessageTextPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}citibankMessageTextPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.MESSAGES,
            'citibankMessageTextPage',
            MESSAGES_MOCKS?.CITI_BANK_MESSAGE_TEXT_PAGE) as Promise<AxiosResponse<MessagesResponse>>;
    }

    citibankMessageDeletePage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}citibankMessageDeletePage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.MESSAGES,
            'citibankMessageDeletePage',
            MESSAGES_MOCKS?.CITI_BANK_MESSAGE_DELETE_PAGE) as Promise<AxiosResponse<MessagesResponse>>;
    }

    citibankMessageSavePage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}citibankMessageSavePage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.MESSAGES,
            'citibankMessageSavePage',
            MESSAGES_MOCKS?.CITI_BANK_MESSAGE_SAVE_PAGE) as Promise<AxiosResponse<MessagesResponse>>;
    }
}

const messagesApi = new MessagesApi()

export default messagesApi;
