import { useEffect, useState } from "react";
import { Dropdown as IcgdsDropdown } from "@citi-icg-172888/icgds-react";
import _ from "lodash";
import { Icon } from "@citi-icg-172888/icgds-react";
import CBLInput from "../cbusol-input/CBLInput";
import "./dropdown.style.scss";
import kFX_PULSE from "../../common/constants/FX_PULSE";

interface DropdownProps {
    className?: string;
    disabled?: boolean;
    visible?: boolean;
    value?: string;
    menuStyle?: any;
    menuClass?: string;
    defaultLabel?: string;
    label?: string;
    ariaLabel?: string;
    showLoading?: boolean;

    popupClass?: string;
    triggerClass?: string;

    onChange?: any;
    onVisibleChange?: any;
    onSelect?: any;

    itemArray?: any;
    isCurrencyDropdown?: boolean;
}

interface CurrencyDropdownProps {
    visible?: boolean;
    ariaLabel?: string;
    inputText?: string;
    searchRef?: any;

    onChange?: any;
    onVisibleChange?: any;
    onClose?: any;
    onInputFocus?: any;
    onInputChange?: any;
    onKeyDown?: any;

    itemArray?: any;
    currencyArray?: string[];
    options?: any;
}

type Props = DropdownProps & CurrencyDropdownProps;

const Dropdown = (p: Props) => {
    const currency = p.currencyArray ? p.currencyArray : [];

    const [openDropDown, setOpenDropDown] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        if (p.currencyArray) {
            if (p.isCurrencyDropdown && currency.length === p.options.length) {
                setIsAllSelected(true);
            } else {
                setIsAllSelected(false);
            }
        }
    }, [p.currencyArray]);

    const onChange = (value: any) => {
        if (p.onChange) {
            p.onChange(value);
        }
    };

    const onVisibleChange = (visible: boolean) => {
        setOpenDropDown(_.isBoolean(visible) ? visible : false);

        if (p.onVisibleChange) {
            p.onVisibleChange(visible);
        }
    };

    const onSelect = (selected: any) => {
        setSelectedValue(selected);
        setOpenDropDown(false);

        if (p.onSelect) {
            p.onSelect(selected);
        }
    };

    /*
     *Currency dropdown related code
     */
    const onClose = () => {
        if (p.onClose) {
            p.onClose();
        }
    };

    const onInputFocus = () => {
        if (p.onInputFocus) {
            p.onInputFocus();
        }
    };

    const onInputChange = (e: any) => {
        if (p.onInputChange) {
            p.onInputChange(e);
        }
    };

    const onKeyDown = () => {
        if (p.onKeyDown) {
            p.onKeyDown();
        }
    };

    /*
     *ADA Related code starts
     */
    useEffect(() => {
        const ele: Element | null = document.querySelector("#cbusol-view");
        if (ele) {
            if (openDropDown) {
                ele.setAttribute("aria-hidden", "true");
            } else {
                ele.removeAttribute("aria-hidden");
            }
        }
        return () => {
            if (ele) {
                ele.removeAttribute("aria-hidden");
            }
        };
    }, [openDropDown]);

    /* Hide the child elements of dropdown input box to prevent from focusing on each of them */
    useEffect(() => {
        const className = `.${p.className}`;
        const dropDownEle: HTMLElement | null = document.querySelector(className);
        if (dropDownEle) {
            dropDownEle.setAttribute("aria-live", "polite");
            dropDownEle.setAttribute("aria-atomic", "true");

            const inputEle = dropDownEle.getElementsByTagName("input");
            if (inputEle.length > 0) {
                inputEle[0].classList.add("dropdown-input");
                const subInputEle: HTMLElement | null =
                    document.querySelector(`.dropdown-input`);
                subInputEle?.setAttribute("aria-hidden", "true");
            }

            const spanEle = dropDownEle.getElementsByTagName("span");
            if (inputEle.length > 0) {
                spanEle[0].classList.add("dropdown-value");
                const subEle: HTMLElement | null =
                    document.querySelector(`.dropdown-value`);
                subEle?.setAttribute("aria-hidden", "true");
            }

            const iEle = dropDownEle.getElementsByTagName("i");
            if (inputEle.length > 0) {
                iEle[0].classList.add("dropdown-icon");
                const subIcon: HTMLElement | null =
                    document.querySelector(`.dropdown-icon`);
                subIcon?.setAttribute("aria-hidden", "true");
            }

            const userSelectedText = isAllSelected
                ? "All Currencies selected"
                : p.isCurrencyDropdown && currency.length <= 0
                    ? "No Currencies selected"
                    : p.label !== undefined
                        ? p.value !== p.label
                            ? ` ${p.value} selected `
                            : ""
                        : p.value !== p.defaultLabel
                            ? ` ${p.value} selected `
                            : "";
            dropDownEle.setAttribute("tabIndex", "0");
            dropDownEle.setAttribute(
                "aria-label",
                `${p.defaultLabel} Dropdown, ${userSelectedText}, ${openDropDown
                    ? "expanded, double tap to collapse"
                    : "collapsed, double tap to expand"
                }`
            );

            dropDownEle.setAttribute("aria-expanded", `${openDropDown}`);
            dropDownEle.setAttribute(
                "aria-disabled",
                `${p.showLoading ? "true" : "false"}`
            );

            const dropDownEleID = dropDownEle.id;
            dropDownEle.setAttribute("aria-controls", `${dropDownEleID}`);
        }
    }, [
        openDropDown,
        p.value,
        p.showLoading,
        p.className,
        p.label,
        p.defaultLabel,
        isAllSelected,
        currency.length,
        p.isCurrencyDropdown,
    ]);

    useEffect(() => {
        if (openDropDown) {
            _.delay(() => {
                const className =
                    p.menuClass !== undefined
                        ? `.${p.menuClass} .lmn-dropdown-nvda-list`
                        : ".lmn-dropdown-nvda-list";
                const dropDownNvdaEle = document.querySelector(className);

                if (dropDownNvdaEle)
                    dropDownNvdaEle.setAttribute("aria-hidden", `${openDropDown}`);

                const dropDownEle = document.querySelector(".lmn-dropdown");

                if (dropDownEle) {
                    const parentNode: HTMLElement | null = dropDownEle?.parentElement;
                    if (parentNode) {
                        parentNode.setAttribute("style", "height: 0");
                    }

                    dropDownEle.setAttribute("aria-modal", "true");
                    dropDownEle.setAttribute("role", "dialog");
                    const selectEle: HTMLElement | null = document.querySelector(
                        ".lmn-dropdown-menu-wrapper"
                    );

                    //ADA | For Apply button in the dropdown footer
                    _.delay(() => {
                        const dropDownFooterEle: HTMLElement | null =
                            document.querySelector(".lmn-dropdown-footer");
                        if (dropDownFooterEle) {
                            dropDownFooterEle.removeAttribute("role");
                            dropDownFooterEle.removeAttribute("aria-label");
                            dropDownFooterEle.removeAttribute("tabindex");
                            const parentApplyBtnEle: HTMLElement | null =
                                document.querySelector(".lmn-justify-content-end");
                            if (parentApplyBtnEle) {
                                const applyBtnEle: any = parentApplyBtnEle.childNodes[0];
                                if (applyBtnEle) {
                                    applyBtnEle.onclick = function () {
                                        onClose();
                                    };
                                    applyBtnEle.setAttribute("aria-label", "Apply button");
                                    applyBtnEle.setAttribute("role", "button");
                                    applyBtnEle.setAttribute("tabindex", "0");
                                }
                            }
                        }
                    }, 200);

                    if (selectEle) {
                        const ulEle: HTMLElement | null =
                            document.querySelector(".lmn-dropdown-menu");
                        if (ulEle) {
                            ulEle.setAttribute("role", "listbox");
                            ulEle.setAttribute("tabIndex", "0");
                            ulEle.setAttribute(
                                "aria-label",
                                `listbox, ${p.defaultLabel}, double-tap to activate`
                            );
                            const listEle = document.querySelectorAll(".lmn-dropdown-item");
                            _.each(listEle, (val: Element) => {
                                val.setAttribute("tabIndex", "0");
                                val.setAttribute("role", "option");
                                if (p.isCurrencyDropdown) {
                                    // if (val.textContent !== null) {
                                    if (val.classList.contains("active")) {
                                        // if (p.currencyArray?.includes(val.textContent)) {
                                        val.setAttribute(
                                            "aria-label",
                                            `${val.textContent}, selected`
                                        );
                                    } else {
                                        val.setAttribute(
                                            "aria-label",
                                            `${val.textContent}, double tap to select`
                                        );
                                    }
                                    // }
                                } else {
                                    if (val.textContent === selectedValue) {
                                        val.setAttribute(
                                            "aria-label",
                                            `${val.textContent}, selected`
                                        );
                                        (val as HTMLElement)?.focus();
                                    } else {
                                        val.setAttribute(
                                            "aria-label",
                                            `${val.textContent}, double tap to activate`
                                        );
                                    }
                                }
                            });
                        }
                    }
                }
            }, 100);
        }
    }, [
        openDropDown,
        selectedValue,
        p.currencyArray,
        p.menuClass,
        p.defaultLabel,
        p.isCurrencyDropdown,
    ]);

    /*
     *ADA Related code ends
     */
    const _renderCurrencyDropdown = () => {
        return (
            <IcgdsDropdown
                className={p.className}
                multiple
                label={
                    currency.length > 1 && !isAllSelected
                        ? `${currency[0]}, ${currency[1]}${currency.length > 2 ? ` +${currency.length - 2}` : ""
                        }`
                        : currency.length === 0
                            ? "Currencies"
                            : isAllSelected
                                ? "All Currencies"
                                : `${currency[0]}`
                }
                value={currency}
                onClose={onClose}
                onVisibleChange={onVisibleChange}
                visible={p.visible}
                style={{ width: "50%", marginTop: "5px" }}
                checkAllLabel={kFX_PULSE.ALL_CURRENCIES}
                placeholder={kFX_PULSE.CURRENCIES}
                enableCheckAll={p.inputText?.length === 0}
                onChange={onChange}
                dropdownRender={(optionsList, applyBtn) => {
                    return (
                        <>
                            <div className="lmn-dropdown-header lmn-dropdown-header-search">
                                <div className="lmn-input-text lmn-search lmn-search-alt dropdown-input">
                                    <Icon
                                        aria-hidden={true}
                                        type="search"
                                        className="lmn-input-prefix-icon lmn-font-size-sm"
                                    />
                                    <CBLInput
                                        placeholder="Search"
                                        ariaLabel="Search option to filter currencies"
                                        value={p.inputText}
                                        ref={p.searchRef}
                                        onFocus={onInputFocus}
                                        onChange={onInputChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </div>
                            </div>
                            {optionsList}
                            {applyBtn}
                        </>
                    );
                }}
            >
                {p.itemArray?.map((i: any) => (
                    <IcgdsDropdown.Item key={i} value={i} wrap>
                        <span>{i}</span>
                    </IcgdsDropdown.Item>
                ))}
            </IcgdsDropdown>
        );
    };

    return (
        <>
            {p.isCurrencyDropdown ? (
                _renderCurrencyDropdown()
            ) : (
                <IcgdsDropdown
                    pureMenu
                    className={p.className}
                    disabled={p.disabled}
                    visible={p.visible}
                    value={p.value}
                    label={p.label}
                    menuStyle={p.menuStyle}
                    menuClass={p.menuClass}
                    aria-label={p.ariaLabel}
                    popupClass={p.popupClass}
                    triggerClass={p.triggerClass}
                    onChange={onChange}
                    onVisibleChange={onVisibleChange}
                    onSelect={onSelect}
                >
                    {_.map(p.itemArray, (value) => {
                        return (
                            <IcgdsDropdown.Item
                                key={value}
                                value={value}
                                className={`dropdown-item ${p.value === value ? " active" : ""
                                    }`}
                            />
                        );
                    })}
                </IcgdsDropdown>
            )}
        </>
    );
};

export default Dropdown;
