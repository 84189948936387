import { useEffect, useState } from "react"
import _ from 'lodash';
import React, { ReactNode } from "react";

interface CurrencyInputProps {
    //behavior control
    maxAmount?: number
    fractionDigits: number
    max?: number

    // events
    onAmountChange?: Function
    onKeyPress?: Function

    type?: string,
    label?: string,
    labelChildern?: ReactNode,
    required?: boolean
    showHiddenIcon?: boolean
    value?: string
    placeholder?: string
    onChange?: any
    onBlur?: any
    onFocus?: any,
    onKeyDown?: React.KeyboardEventHandler
    disabled?: boolean,
    maxLength?: number,
    autoFocus?: boolean,
    isBusinessCode?: boolean,
    mask?: boolean,
    numericInput?: boolean
    prefix?: string
    wrapperClass?: string
    bodyClass?: string
    ariaLabelInput?: string,
    ariaLabel?: string
    id?: string,
    locale?: string,
    ariaRequired?: boolean
}

const CurrencyInput = (p: CurrencyInputProps) => {

    const [maskedValue, setMaskedValue] = useState<string>(_.get(p, 'value', '0.00'));



    useEffect(() => {
        console.log('Masked:', maskedValue, 'PValue:', p.value)
        if (p && p.value && maskedValue === '0.00') {
            setMaskedValue(p.value ? p.value : '0.00');
        }
        if (p && p.value && p.value === '0.00') {
            setMaskedValue('0.00')
        }
    }, [p])

    // useEffect(() => {
    //     if (p.onAmountChange) {
    //         p.onAmountChange(maskedValue)
    //     }
    // }, [maskedValue]);

    const formatCurrency = (value: number) => {

        const currency_config = { style: 'currency', currency: 'USD', minimumFractionDigits: p.fractionDigits, maximumFractionDigits: p.fractionDigits };

        const formatter = () => {
            if (p.locale && '' !== p.locale) {
                return new global.Intl.NumberFormat(p.locale, currency_config);
            }
            return new global.Intl.NumberFormat('en-US', currency_config);
        }

        //remove any currency symbol prefixed to the amount
        return formatter().format(value).substring(1);
    }

    const convertToNumber = (n: any) => {
        if (typeof n === 'number') return n;
        const charStrippedNumber = n.toString().replace(/[^0-9-]/g, '');
        //console.log('Number after character stripping', charStrippedNumber);
        return Number(charStrippedNumber);
    }

    const onChange = (e: any) => {
        const textFieldValue = e.target.value;
        if (textFieldValue.toString().length > 14 || (p.maxAmount && Number(textFieldValue.toString().replace(/[^0-9\.-]+/g, "")) >= p.maxAmount)) {
            return;
        }
        const [v, fc] = checkMaxAndUpdate(textFieldValue)
        if (p.onChange && fc) {
            p.onChange(e, v, fc);
        }
        if (p.onAmountChange && fc) {
            p.onAmountChange(e, v, fc)
        }
    }

    const checkMaxAndUpdate = (n: any) => {
        const [v, fc] = convertAndFormat(n);
        if (!p.maxAmount || v <= p.maxAmount) {
            setMaskedValue(fc)
            return [v, fc]
        } else {
            return [convertToCentsFirstCurrency(fc), fc]
        }
    }

    const convertAndFormat = (n: any): [number, string] => {
        const v = convertToCentsFirstCurrency(n);
        const fc = formatCurrency(v);
        return [v, fc]
    }

    const convertToCentsFirstCurrency = (n: any) => {

        let backup = n;

        if (typeof n === 'string') {

            backup = convertToNumber(n);

            if (backup % 1 !== 0) {
                backup = backup.toFixed(p.fractionDigits)
            }

        } else {
            backup = Number.isInteger(n) ? Number(n) * (10 ** p.fractionDigits) : n.toFixed(p.fractionDigits)
        }

        const ret = convertToNumber(backup) / (10 ** p.fractionDigits)

        return ret;
    }

    const generateInputId = () => {
        if (p.id) {
            return p.id;
        }

        const customId = 'input-' + _.get(p, 'type', 'currency') + '-' + window.location.hash + '-' + _.get(p, 'label', 'default');
        return customId.replace(/#|\//g, '').replace(/ /g, '-').toLocaleLowerCase();
    }

    return (
        <>
            <div className={`cbusol-input-group ${p.wrapperClass} cbip-${p.id ? p.id : ''}`}>

                {
                    p.label ?
                        <label tabIndex={0} htmlFor={p.label} className="input-label" aria-label={`${p.ariaLabel ? p.ariaLabel : p.label}${p.required ? ', Required' : ''} `}>
                            {
                                p.required ?
                                    <span aria-hidden="true" style={{ color: '#B60000' }}>
                                        {`* `}
                                    </span>
                                    : null
                            }
                            <span aria-hidden="true">{p.label}</span>
                        </label> :
                        null
                }

                {/* For Currency amount field in Foreign wire */}
                {
                    p.labelChildern
                }
                {/* end */}

                <div className={`cbip-group ${p.bodyClass}`}>
                    {
                        _.isString(p.prefix) && p.prefix.length > 0 ?
                            <>
                                <div className="businesslabel-container">
                                    <span className="business-label" style={{ color: `${p.disabled ? '#676E74' : ''}` }}>
                                        {
                                            p.prefix
                                        }
                                    </span>
                                </div>
                            </>
                            : null
                    }

                    <div>
                        <input
                            id={generateInputId()}
                            aria-required={p.ariaRequired}
                            aria-label={p.ariaLabelInput ? p.ariaLabelInput : p.label}
                            className={`cbusol-input ${p.disabled ? 'disabled' : ''}`}
                            type={'tel'}
                            onChange={onChange}
                            onBlur={e => { if (p.onBlur) p.onBlur(e) }}
                            onFocus={e => { if (p.onFocus) p.onFocus(e) }}
                            // onKeyDown={p.onKeyDown}
                            value={maskedValue}
                            //placeholder={p.mask ? this.state.focusLost ? maskMiddleText(this.state.unmaskedValue) : p.value : p.value}
                            disabled={p.disabled}
                            readOnly={p.disabled}
                            maxLength={p.maxLength}
                            autoFocus={p.autoFocus}
                            placeholder={p.placeholder}
                            max={p.max}
                        />
                    </div>
                </div>
            </div>
        </>
    )

}

export default CurrencyInput;