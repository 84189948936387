import { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../context/application-context";
import LandingHeader from "../header/header.component";
import __constants from "../../common/constants";
import _ from "lodash";
import { Button, Loading } from "@citi-icg-172888/icgds-react";
import { APP_LOCATIONS } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import { registerNotifIfNeeded } from "./push-notif.util";
import PushNotifHistory from "./push-notif-history.component";
import './push-notif.style.scss';



const PushNotificationsLanding2 = () => {

    const context = useContext(ApplicationContext);

    const location = useLocation();

    // const [loadingMessage, setLoadingMessage] = useState(__constants.LOADING);
    // const [alertMessage, setAlertMessage] = useState('');
    // const [success, setSuccess] = useState(false);
    const [registered, setRegistered] = useState(false);

    // const [deniedAuth, setDeniedAuth] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const [requestPushRegister, setRequestPushRegister] = useState(false);
    const [pushRegisterLoader, setPushRegisterLoader] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [redirectFromPushLogin, setRedirectFromPushLogin] = useState(false);



    useEffect(() => {
        // checkRegistration();
        checkUrl();
    }, []);

    const checkRegistration = async () => {

        context.DEVICE?.getPushNotifInfo((resp: any) => {

            if (resp === undefined || resp === null) {
                console.log('Push Notifications - getPushNotifInfo failed');
                return;
            }

            const _registered = _.get(resp, 'enrolled', false);

            if (_registered === false) {
                console.log('Push Notifications - Not registered');
                if (context.Security.isSignedIn()) {
                    registerForPushNotifications();
                    return;
                }

                console.log('Push Notifications - Redirecting to MFA Home', context);
                context.Router.navigate(APP_LOCATIONS.MfaHome, { notifications: true })

                return;
            }

            setRegistered(true);



        }, (error: any) => { });
    }

    const checkUrl = async () => {
        console.log('Push Notifications - Checking URL');
        const requestRegister = _.get(location, 'state.data.requestRegister', false);
        const redirect = _.get(location, 'state.data.pushNotificationLogin', false);
        setRedirectFromPushLogin(redirect);
        console.log('Push Notifications - requestRegister: ', requestRegister);
        if (requestRegister === false) {
            checkRegistration();
            return;
        }
        setPushRegisterLoader(true);
        setRequestPushRegister(requestRegister);
        registerForPushNotifications();
    }


    const registerForPushNotifications = async () => {
        console.log('Push Notifications - Registering for push notifications');
        setPushRegisterLoader(true);
        registerNotifIfNeeded(true, pushNotificationRegisterResult)
    }

    const pushNotificationRegisterResult = (registered: boolean, success: boolean) => {
        setPushRegisterLoader(false);
        if (success === true) {
            setRegistered(registered);
            return;
        }

        console.log('Push Notifications - Failed to register for push notifications');

        setErrorMessage('Failed to register for push notifications');

    }


    const renderUI = () => {

        if (pushRegisterLoader) {
            return <Loading
                tip='Enrolling in notifications...'
                horizontal
                className='loading-center'
            />
        }

        if (errorMessage !== '' && errorMessage !== undefined) {
            return <div className="error-message">
                <p className="error-text">
                    {errorMessage}
                </p>
            </div>
        }

        if (registered) {
            return <PushNotifHistory deniedAuth={false} />
        }

        return <div className="message-container">
            <p className="message-text" style={{ textAlign: 'center' }}>
                Login to View Notifications
            </p>
        </div>
    }

    return (
        <>
            <LandingHeader
                title={registered ? undefined : __constants.PUSH_NOTIF}
                showNotifSwitch={showHistory}
                notifChecked={registered}
            //handleNotifChecked={_handleNotifChecked}
            />
            <div className='react-container auto-overflow'>
                {
                    renderUI()
                }
            </div>
        </>
    )
}

export default PushNotificationsLanding2;