import __httpApi from './api.core';
import { RequestMethod } from './api.core';
import kGENERAL from "../common/constants/GENERAL";
import kPIWIK from "../common/constants/PIWIK";
import { AxiosResponse } from 'axios';

let MOCKED_API: any// from './mocks/mocks';
let INTERNAL_TRANSFER_MOCKS: any

export interface InternalTransfersResponse {

}

export class InternalTransfersApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        // const file  = 
        // const mockPath1 = isProd ? './mocks/prod.mock.ts':'./mocks/mocks.ts';
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default;
        // const m2 = isProd ? './mocks/prod.mock.ts':'./mocks/internal-transfer.mock.ts'
        const m2 = require(isProd ? './mocks/prod.mock.ts' : './mocks/internal-transfer.mock.ts').default
        MOCKED_API = m
        INTERNAL_TRANSFER_MOCKS = m2
    }

    // Setup internal-transfers

    getFromAccounts(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}transferFromAccountPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "itgetFromAccounts",
            MOCKED_API?.INTERNAL_TRANSFERS?.FROMACCOUNTS
        )
    }

    getToAccounts(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}transferToAccountPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "itgetToAccounts",
            MOCKED_API?.INTERNAL_TRANSFERS?.TOACCOUNTS
        )
    }

    getTransfersPage(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}transfersPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "itTransfersPage",
            MOCKED_API?.INTERNAL_TRANSFERS?.ACCOUNTS
        )
    }

    reviewTransfersPendingPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}transfersPendingPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'transfersPendingPage',
            MOCKED_API?.INTERNAL_TRANSFERS?.PENDING) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

    confirmTransfersPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}transfersConfirmPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'transfersConfirmPage',
            MOCKED_API?.INTERNAL_TRANSFERS?.CONFIRM) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

    plannedAndPendingTransfersListPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}accountTransfers/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'plannedAndPendingTransfersListPage',
            INTERNAL_TRANSFER_MOCKS?.MICRO_PLANNED) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }
    completedTransfersPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}completedTransfersPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'completedTransfersPage',
            INTERNAL_TRANSFER_MOCKS?.COMPLETED_LIST_PAGE) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

    plannedAndPendingTransfersDetailPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}plannedAndPendingTransfersDetailPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'plannedAndPendingTransfersDetailPage',
            INTERNAL_TRANSFER_MOCKS?.DETAILS) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

    plannedAndPendingTransfersDetailSubmitPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}plannedAndPendingTransfersDetailSubmitPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'plannedAndPendingTransfersDetailSubmitPage',
            MOCKED_API?.INTERNAL_TRANSFERS?.PLANNED_PENDING_SUBMIT_PAGE) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

    actionOnInternalTransferTransaction(refId: string, action: 'SUSPEND' | 'REACTIVATE' | 'CANCEL', callBack?: Function) {
        const payload = {
            transferReferenceNumber: refId,
            transferIndicator: "ACCOUNT_TRANSFER",
            actionType: action
        }
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}/transfers/pendingActivity/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            payload,
            callBack,
            kPIWIK.ChannelEnum.TRANSFERS,
            'actionOnInternalTransferTransaction',
            INTERNAL_TRANSFER_MOCKS?.ACTION_SUSPEND_TRANSACTION) as Promise<AxiosResponse<InternalTransfersResponse>>;
    }

}

const __instance__ = new InternalTransfersApi();

export default __instance__;
