export class ActionRequiredContext {

    actionRequired?: boolean;
    countDownDay?: number;
    tokenRequired?: boolean;
    tokenUser?: boolean;
    hasPhoneNumbers?: boolean; // check if non-token user has OTP support device
    hasSystemAdmin?: boolean; // check if non-token user has system admin
    ccbUser?: boolean; // check if user is CCB user
    ccbSynced?: boolean; // check if capture flow (phone +email) completed in GW
    phoneNumbers?: Array<{ [key: string]: any }>; // phone numbers in profile
    primaryEmail?: string;
    emailList?: Array<string> | [];
    gwSyncRequired?: boolean; // sync-up phone number / email to Sales force & GW database or not
    emailCaptureRequired?: boolean; // check if do OTP capture (with only Email steps) for CCB(Not-Synced) User

    setActionRequired(actionRequired: boolean) {
        console.log('ActionRequiredContext.setActionRequired', actionRequired);
        this.actionRequired = actionRequired;
    }

    isActionRequired(): boolean | undefined {
        return this.actionRequired;
    }

    setCountDownDay(day: number) {
        console.log('ActionRequiredContext.setCountDownDay', day);
        this.countDownDay = day;
    }

    getCountDownDay(): number | undefined {
        return this.countDownDay;
    }

    setTokenRequired(tokenRequired: boolean) {
        console.log('ActionRequiredContext.setTokenRequired', tokenRequired);
        this.tokenRequired = tokenRequired;
    }

    isTokenRequired(): boolean | undefined {
        return this.tokenRequired;
    }

    setTokenUser(tokenUser: boolean) {
        console.log('ActionRequiredContext.setTokenUser', tokenUser);
        this.tokenUser = tokenUser;
    }

    isTokenUser(): boolean | undefined {
        return this.tokenUser;
    }

    setHasPhoneNumbers(hasPhoneNumbers: boolean) {
        console.log('ActionRequiredContext.setHasPhoneNumbers', hasPhoneNumbers);
        this.hasPhoneNumbers = hasPhoneNumbers;
    }

    getHasPhoneNumbers(): boolean | undefined {
        return this.hasPhoneNumbers;
    }

    setHasSystemAdmin(hasSystemAdmin: boolean) {
        console.log('ActionRequiredContext.setHasSystemAdmin', hasSystemAdmin);
        this.hasSystemAdmin = hasSystemAdmin;
    }

    getHasSystemAdmin(): boolean | undefined {
        return this.hasSystemAdmin;
    }

    setCCBUser(ccbUser: boolean) {
        console.log('ActionRequiredContext.setCCBUser', ccbUser);
        this.ccbUser = ccbUser;
    }

    isCCBUser(): boolean | undefined {
        return this.ccbUser;
    }

    setCCBSynced(ccbSynced: boolean) {
        console.log('ActionRequiredContext.setCCBSynced', ccbSynced);
        this.ccbSynced = ccbSynced;
    }

    isCCBSynced(): boolean | undefined {
        return this.ccbSynced;
    }

    setPhoneNumbers(numbers: Array<{ [key: string]: any }> | undefined) {
        console.log('ActionRequiredContext.setPhoneNumbers');
        this.phoneNumbers = numbers;
    }

    getPhoneNumbers() {
        return this.phoneNumbers;
    }

    setPrimaryEmail(email: string) {
        console.log('ActionRequiredContext.setPrimaryEmail');
        this.primaryEmail = email;
    }

    getPrimaryEmail() {
        return this.primaryEmail;
    }

    setEmailList(emailList: Array<string> | []) {
        console.log('ActionRequiredContext.setEmailList');
        this.emailList = emailList;
    }

    getEmailList() {
        return this.emailList;
    }

    setGWSyncRequired(syncRequired: boolean) {
        console.log('ActionRequiredContext.setGWSyncRequired', syncRequired);
        this.gwSyncRequired = syncRequired;
    }

    isGWSyncRequired() {
        return this.gwSyncRequired;
    }

    setEmailCaptureRequired(emailCaptureRequired: boolean) {
        console.log('ActionRequiredContext.setEmailCaptureRequired', emailCaptureRequired);
        this.emailCaptureRequired = emailCaptureRequired;
    }

    isEmailCaptureRequired() {
        return this.emailCaptureRequired;
    }
}

const __instance__ = new ActionRequiredContext();
export default __instance__;