import { useContext, useEffect, useState } from 'react';
import RouteConfig from './routes/route.config';
// import RouteConfigExample from './routes/route.config.example';
import ApplicationContext from './context/application-context';
import { ROUTES as routes } from './routes/routes';
import { DEVICE_TYPE } from './device/xdevice';
import WarningBanner from './components/banner/error-banner.component';
import BUILD_UTIL from './utils/build.util';
import { preLogin } from './utils/common.util';

/**
 * Time in secs, a device check has to be performed
 */
const DEVICE_CHECK_INTERVAL = 45;

/**
 * Text value to appear when DEVICE is null/undefined
 */
const DEVICE_ERROR_TEXT = "There's been an exception, please close the CitiBusinesss Mobile App by swipping the App upwards, then restarting it.";

function App() {

  const context = useContext(ApplicationContext);

  let deviceTimer: NodeJS.Timeout | undefined = undefined;

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {

    deviceTimer = setInterval(() => {

      if (BUILD_UTIL.isDev()) {
        // console.log('You should see this every 11 secs');
      }

      check_device();

    }, DEVICE_CHECK_INTERVAL * 1000);

    //setDeviceTimer(native_check_timer);
    //console.log(native_check_timer);

  }, [])

  const check_device = () => {
    if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {

      //console.log('Device Type:', window.THE_DEVICE_TYPE)

      clearInterval(deviceTimer);
      clearTimeout(deviceTimer);

      if (window.Android !== undefined) {
        //console.log('DEVICE', window.Android)

        setShowBanner(false);
      } else {
        //console.log('DEVICE', window.Android)
        //clearInterval(timer);
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
          setShowBanner(true);
        }
      }
    }
  }

  const onBannerCloseClick = () => {
    console.log(onBannerCloseClick);
    setShowBanner(false);
    if (deviceTimer)
      clearInterval(deviceTimer);
  }

  useEffect(() => {
    document.body.classList.add('icgds', 'lmn-theme-light');

    preLogin();
  }, []);

  return (
    <>
      {
        showBanner &&
        <WarningBanner text={DEVICE_ERROR_TEXT} closeCallBack={onBannerCloseClick} />
      }
      <RouteConfig routes={routes} />
    </>
  );
}

export default App;