import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { FToggleSwitchBox } from '../../ui-kit/toggle/switch.component';
import { Loading } from '@citi-icg-172888/icgds-react';
import Button from '../../ui-kit/button/button';
import __constants from '../../common/constants';
import __req from '../../api/accounts.api';
import __pnReq from '../../api/push-notif.api';
import { clearTimer } from '../../utils/timer.util';
import { APP_LOCATIONS } from '../../routes/routes';
import ApplicationContext from '../../context/application-context';
import NotificationItem from './templates/notification-item.kit';
import { DEVICE_TYPE } from '../../device/xdevice';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';

interface Props {
    deniedAuth: boolean;
}

const notificationsMock = [
    {
        header: 'Unusual activity detected',
        content: 'We detected an unusual activity on your account. Please click on notification for more information.',
        actionText: 'Go to Security Center',
        time: '2 hours ago',
        type: 1
    },
    {
        header: 'New Device Registered',
        content: 'A new device has been registered to your account. If this isn\'t you, please call our customer care.',
        actionText: 'Manage Devices',
        time: '3 hours ago',
        type: 1
    },
    {
        header: 'Outgoing Wire Approval',
        content: 'A wire transfer has been initiated from your account. Please click on notification for more information.',
        actionText: 'View Wire Transfer',
        time: '4 hours ago',
        type: 2

    },
    {
        header: 'New Device Registered',
        content: 'A new device has been registered to your account. If this was not you, please call our customer care.',
        actionText: 'Manage Devices',
        time: '7 hours ago',
        type: 1
    },
]

function PushNotifHistory(props: Props) {
    const context = useContext(ApplicationContext);

    const { deniedAuth } = props;

    const [history, setHistory] = useState(Array(0));
    const [showDeniedView, setShowDeniedView] = useState(deniedAuth);
    const [showLoading, setShowLoading] = useState(true);
    const [showNoData, setShowNoData] = useState(false);
    const [filter, setFilter] = useState(0);

    useEffect(() => {
        // TODO - request notifications history with JWT token
        // get JWT token from client via device function - getPushNotifJWTToken()
        context.DEVICE?.getPushNotifJWTToken((resp: object) => {
            console.log('Push Notifications - getPushNotifJWTToken succeed: ', resp);
        }, (error: object) => {
            console.log('Push Notifications - getPushNotifJWTToken failed: ', error);
        });

        // Assuming request history succeed! - setHistory(data)
        //setShowLoading(false);
        fetchHistory();
    }, []);

    useEffect(() => {
        setShowDeniedView(deniedAuth);
    }, [deniedAuth]);

    const fetchHistory = async () => {
        console.log('Push Notifications: Fetching History')
        setShowLoading(true);
        __pnReq.getPushNotifHistory("0", pnHistorySuccess).then((response) => {
            // console.log('Push Notifications - history response: ', response);
            if (response.status !== WAIT_FOR_IOS_CALLBACK) {
                // console.log('Push Notifications - history response: ', response);
                pnHistorySuccess(response.data)
            } else {
                console.log('waiting for ios call', response);
            }
        }).catch((error) => {
            console.log('Push Notification - history failed: ', error);
            setShowLoading(false);
            setShowNoData(true);
        })
    }

    const pnHistorySuccess = (r: any) => {
        // console.log('Push Notifications - history response: ', r);

        if (!r.success) {
            setShowLoading(false);
            setShowNoData(true);
            return;
        }
        const data = _.get(r, 'data', []);

        setShowLoading(false);

        console.log('Push Notifications - history data: ', data);

        setHistory(data);

        // if (typeof data === 'object' && data.length > 0) {
        //     setHistory(data);
        // } else {
        //     setHistory([...notificationsMock])
        // }
    }

    function _openDeviceSettings() {
        context.DEVICE.openDeviceSettings();
    }

    function _onActionClicked() {
        if (context.Security.isSignedIn()) {
            // TODO - redirect to related screen directly
            return;
        }

        context.Router.navigate(APP_LOCATIONS.MfaHome, {
            autoBioLogin: true
        });
    }

    function _onSwitchSelect(v: string, i: number) {
        console.log('Push Notification - history switch select: ', i);
        if (i === 0) {
            clearTimer(window.LOGOUT_TIMER_REACT);
            context.DEVICE.goToNativePage(APP_LOCATIONS.MfaHome);
        } else if (i === 1) {
            if (context.Security.isSignedIn()) {
                context.Router.navigate(APP_LOCATIONS.SignInHome);
            } else {
                context.Router.navigate(APP_LOCATIONS.MfaHome);
            }
        }
    }

    function _renderContent() {

        if (showLoading) {
            return <Loading
                tip='Fetching history...'
                horizontal
                className='loading-center'
            />
        }

        if (showNoData) {
            return <>
                <FToggleSwitchBox
                    static={true}
                    selectedItemIndex={1}
                    items={[
                        __constants.GENERATE_TOKEN_SWITCH_MOBILE_TOKEN,
                        __constants.GENERATE_TOKEN_SWITCH_MOBILE_APP
                        // __constants.GENERATE_TOKEN_SWITCH_NOTIF
                    ]}
                    onItemSelect={_onSwitchSelect}
                />
                <div className="notifications-filter">
                    <span className="title">History</span>
                </div>
                <span className='no-data'>{__constants.NO_DATA}</span>
            </>
        }

        return <>
            {
                <FToggleSwitchBox
                    static={true}
                    selectedItemIndex={1}
                    items={[
                        __constants.GENERATE_TOKEN_SWITCH_MOBILE_TOKEN,
                        __constants.GENERATE_TOKEN_SWITCH_MOBILE_APP
                        // __constants.GENERATE_TOKEN_SWITCH_NOTIF
                    ]}
                    onItemSelect={_onSwitchSelect}
                />
            }
            {
                showDeniedView
                    ? <div className='notif-denied-container'>
                        <p className='text-medium'>{__constants.PUSH_NOTIF_VERIBAGE.DEINED.WARNING}</p>
                        <p className='text-small'>{__constants.PUSH_NOTIF_VERIBAGE.DEINED.CONTENT}</p>
                        <Button color='ghost' className='notif-denied-change-btn' onClick={_openDeviceSettings}>{
                            __constants.PUSH_NOTIF_VERIBAGE.DEINED.CHANGE_BTN
                        }</Button>
                    </div>
                    : null
            }
            <div className="notifications-filter">
                <span className="title">History</span>
                {/* Render Dropdown here */}
                <select name="ni-filter" id="ni-filter"
                    onChange={(e) => {
                        setFilter(parseInt(e.target.value));
                    }}
                    style={{ border: '1px solid #d4d4d48f', padding: '3px 8px' }}>
                    <option value={0}>All</option>
                    <option value={1}>Security</option>
                    <option value={2}>Wire</option>
                    <option value={3}>Other</option>
                </select>
            </div>
            <div className="notifications-history">
                {
                    notificationsMock.map((s, i) => {
                        if (filter === s.type) {
                            return <NotificationItem key={i}
                                content={s.content}
                                actionText={s.actionText}
                                header={s.header}
                                time={s.time}
                                actionClicked={_onActionClicked}
                            />
                        }
                        if (filter === 0) {
                            return <NotificationItem key={i}
                                content={s.content}
                                actionText={s.actionText}
                                header={s.header}
                                time={s.time}
                                actionClicked={_onActionClicked}
                            />
                        }
                        return null;
                    })
                }
            </div>
        </>;
    }

    return (
        <>
            {
                _renderContent()
            }
        </>
    );
}

export default PushNotifHistory;