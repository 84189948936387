import React, { CSSProperties, useState } from 'react';
import _ from '../../lodash';
import './switch.style.scss'
import { analyticsBranded } from '../../utils/piwik.util';
import { addLogoutTimer } from '../../utils/timer.util';
interface Props {
  items?: Array<string>,
  onItemSelect?: Function,
  selectedItemIndex?: number,
  style?: CSSProperties,
  static?: boolean,
  className?: string,
  resetTimer?: boolean
}


export const FToggleSwitchBox = (props: Props) => {

  const [selectedItemIndex, setSelectedItemIndex] = useState(props.selectedItemIndex ? props.selectedItemIndex : 0)

  const resetTimer = props.resetTimer ? props.resetTimer : true;


  const onItemSelect = (v: string, i: number) => {

    setSelectedItemIndex(i)
    if (resetTimer) {
      window.LOGOUT_TIMER_REACT = addLogoutTimer()
    }

    if (_.isFunction(props.onItemSelect)) {
      const page = `${v}`;
      props.onItemSelect(v, i);
      analyticsBranded(6, `Toggle ${page}`);
    }

  }


  const getSelectedItemIndex = () => {
    return props.selectedItemIndex ? props.selectedItemIndex : selectedItemIndex;
  }

  const getAriaLabel = (value: string, index: number) => {
    const ariaLabel = (props.static ? (props.selectedItemIndex === index) : (getSelectedItemIndex() === index))
      ? `Selected ${value}, Button`
      : `${value}, Button, Double tap to activate`;

    return ariaLabel;
  }

  return (
    <>
      <div className="citi-toggle-switch" role="tablist" style={props.style}>
        {
          props.items?.map((value: string, index: number) => {
            return (
              // ADA - C41630-17705
              <div
                id={`switch-${value?.replace(/ /g, '-').toLocaleLowerCase()}-${index}`}
                tabIndex={getSelectedItemIndex() === index ? 0 : -1}
                role="tab"
                aria-selected={getSelectedItemIndex() === index}
                aria-label={getAriaLabel(value, index)}
                key={index}
                className={`citi-toggle-switch-item ${(props.static ? (props.selectedItemIndex === index) : (getSelectedItemIndex() === index)) ? 'active' : ''} ${props.className}`}
                onClick={() => onItemSelect(value, index)}
                onKeyDown={(e) => e.key === "Enter" && onItemSelect(value, index)}
                style={{ textAlign: 'center' }}
              >
                <div style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center'
                }}>
                  {value}
                </div>
              </div>
            );
          })
        }
      </div>
    </>
  );


}