import { useContext } from "react";
import { handleBackOnLeftMenus } from "../../utils/common.util";
import ApplicationContext from "../../context/application-context";
import LandingHeader from "../../components/header/header.component";
import __constants from '../../common/constants';
import { AccordionItemContent, RAccordion } from "../accordion/accordion.component";
import './left-menu-accordion.style.scss';

interface LeftMenuAccordionProps {
    accordionContent: any;
}

const LeftMenuAccordion = (p: LeftMenuAccordionProps) => {
    let context = useContext(ApplicationContext);

    const onBackClick = () => {
        handleBackOnLeftMenus();
    }

    const _openInbrowseriOS = (link: string) => {
        var json = { "url": link };
        context.DEVICE.openInBrowser(json);
    }


    const generateAccordionContent = (href: string) => {
        return <a onClick={() => { _openInbrowseriOS(href) }} className='left-menu-content' href={href} target="_blank" rel="noopener noreferrer">
            <i className="lmnicon lmnicon-link-external" style={{ fontSize: '1.3rem', marginRight: 8 }} />
            <span>{__constants.VIEW_LINK}</span>
        </a>
    }

    const accordionContent: Array<AccordionItemContent> =
        p.accordionContent.map((item: any) => {
            return {
                header: item.header,
                content: generateAccordionContent(item.content)
            }
        })

    const uiRenderContent = () => {

        return <RAccordion content={accordionContent} contentClass='no-border' />
    }

    
    const createUI = () => {
        return <>

            <LandingHeader showBackBtn onBackClick={onBackClick} hideHeaderSpace={true} />
            <div className='react-container top-40 left-menu-item-container' style={{ width: '100%', maxWidth: '100%' }}>
                {uiRenderContent()}
            </div>
        </>
    }
    return createUI();
}
export default LeftMenuAccordion