import _, { set } from 'lodash';
import PushNotifContent from './push-notif-content.component';
import __constants from '../../common/constants';
import { APP_LOCATIONS } from '../../routes/routes';
import routerCtx from '../../context/router-context';
import deviceCtx from '../../device/xdevice';
import securityCtx from '../../context/security-context';
import toastProvider from '../../context/toast-provider.context';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import __req from '../../api/push-notif.api';
import BUILD_UTIL from '../../utils/build.util';

let deviceToken: string = '';
let registerCallback: Function | undefined = undefined;

/**
 * Present notifications when user click on received notifications and login successfully 
 */
const _parseNotifContent = (data: { [key: string]: any }): { [key: string]: any } => {
    // const sourceId: string = data['sourceId'];
    return {
        pathname: APP_LOCATIONS.WireTransactionList, // sourceId
        data: data['details']
    }
}

const _handleBadgeDeductResp = (resp: { [key: string]: any }) => {
    console.log('Push Notifications - badgeDeduct - ', _.get(resp, 'success', false));
}

const _onClose = () => {
    toastProvider.showPushNotifContentToast(false);

    deviceCtx?.clearPushNotifContent();
    deviceCtx?.decreasePushNotifBadge();

    __req.badgeDeduct([__constants.PUSH_NOTIF_SOURCES.CCB], _handleBadgeDeductResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleBadgeDeductResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch((error) => {
            console.log('Push Notifications - badgeDeduct - failed ', error);

            _handleBadgeDeductResp(error);
        });
}

export const openNotifications = () => {
    deviceCtx?.getPushNotifContent((resp: object) => {
        if (!resp) {
            return;
        }

        console.log('Push Notifications - getPushNotifContent succeed ', resp);

        const notiContent = _parseNotifContent(resp);
        const { pathname, data } = notiContent;
        routerCtx?.navigate(pathname, { data });

        toastProvider.showPushNotifContentToast(true, <PushNotifContent
            title={'Wires'}
            data={data}
            onClose={_onClose}
        />);
    }, (error: object) => {
        console.log('Push Notifications - getPushNotifContent failed ', error);
    });
}

/**
 * enroll/unenroll notification on server
 */

const _handleCallback = (register: boolean, success: boolean) => {
    registerCallback && registerCallback(register, success);
}

const _handleUnRegisterResp = (resp: { [key: string]: any }) => {
    console.log('Push Notification - resp of unregisterPushNotif: ', resp);

    const success = _.get(resp, 'success', false);
    if (success) {
        deviceCtx.setPushNotifEnroll({ enrolled: false, enrolledToken: '' });
    }
    _handleCallback(false, success);
}

const _handleRegisterResp = (resp: { [key: string]: any }) => {
    console.log('Push Notification - resp of registerPushNotif: ', resp);

    const success = _.get(resp, 'success', false);
    if (success) {
        deviceCtx.setPushNotifEnroll({ enrolled: true, enrolledToken: deviceToken });
        deviceCtx.setPushNotifJWTToken(resp.jwtToken);
        // const jwt = _.get(resp, 'jwt', '');
        // if(jwt==='' || jwt===null){
        //     console.log('pn token is empty');
        // }else{
        //     localStorage.setItem('pnwt', jwt);
        // }
    }
    _handleCallback(true, success);
}

const _sendUnRegisterNotifReq = (data: { [key: string]: any }) => {
    __req.unregisterPushNotif(data, _handleUnRegisterResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleUnRegisterResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch((error) => {
            _handleUnRegisterResp(error);
        });
}

const _sendRegisterNotifReq = (data: { [key: string]: any }) => {
    __req.registerPushNotif(data, _handleRegisterResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleRegisterResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch((error) => {
            _handleRegisterResp(error);
        });
}

const _sendNotifReq = (register: boolean, deviceInfo: object) => {
    deviceCtx.getTokenSequenceAndSerialNumber((resp: any) => {
        const serialNumber = _.get(resp, 'serialNumber', '');
        const sequenceNumber = _.get(resp, 'sequenceValue', '');

        deviceInfo = { ...deviceInfo, ...{ serialNumber, sequenceNumber } };

        if (register) {
            _sendRegisterNotifReq(deviceInfo);
            return;
        }

        _sendUnRegisterNotifReq(deviceInfo);
    }, (error: object) => {
        console.log('Push Notifications - getTokenSequenceAndSerialNumber failed ', error);
        _handleCallback(register, false);
    }, {});
}

export const registerNotifIfNeeded = (register: boolean, registerFun?: Function) => {

    if (BUILD_UTIL.isDev()) {
        setTimeout(() => {
        registerFun && registerFun(true, true);
        }, 5000);
        return;
    }

    registerCallback = registerFun;

    deviceCtx.getPushNotifDeviceInfo((deviceInfo: object) => {
        if (!deviceInfo) {
            _handleCallback(register, false);
            return;
        }

        console.log('Push Notifications - getPushNotifDeviceInfo succeed ', deviceInfo);
        deviceToken = _.get(deviceInfo, 'deviceToken', '');

        const userP86Id = securityCtx?.getUserP86Id();
        if (!userP86Id || userP86Id.length === 0) {
            deviceCtx.getUserP86Id((userP86Data: any) => {
                console.log('Push Notifications - getUserP86Id succeed ');

                securityCtx.setUserP86Id(userP86Data.userP86Id);
                _sendNotifReq(register, deviceInfo);
            }, (error: object) => {
                console.log('Push Notifications - getUserP86Id failed ', error);
            }, []);

            return;
        }

        _sendNotifReq(register, deviceInfo);
    }, (error: object) => {
        console.log('Push Notifications - getPushNotifDeviceInfo failed ', error);
        _handleCallback(register, false);
    });
}

