import { CSSProperties } from 'react';
import './alert.style.scss';
import { APP_LOCATIONS } from "../../routes/routes";
import { useHistory } from 'react-router';
import __constants from '../../common/constants';

interface AlertProps {
    type: string; //'success', 'warning', 'error'
    title?: string;
    content?: string;
    htmlContent?: string;
    containsHyperlink?: boolean;
    dynamicContentWithHyperlink?: boolean;
    initialContent?: string;
    hyperlink?: string;
    hyperlinkContent?: string;
    concludingContent?: string;
    style?: CSSProperties;
    className?: string;
    iconClassName?: string;
    ariaNotifyDisabled?: boolean; // ADA - don't notify user when show Alert if this field is true.
    role?: any;
    ariaLevel?: any;
    ariaLabel?: any;
    htmlContentAriaLabel?: any
}

const Alert = (props: AlertProps) => {
    let history = useHistory();

    const {
        type,
        title,
        content,
        htmlContent,
        containsHyperlink,
        dynamicContentWithHyperlink,
        initialContent,
        hyperlink,
        hyperlinkContent,
        concludingContent,
        style,
        className,
        iconClassName,
        ariaNotifyDisabled,
        role,
        ariaLevel,
        ariaLabel,
        htmlContentAriaLabel
    } = props;

    const _renderContentView = () => {
        let alertTitle = 'Success';
        let containerClass = 'alert-container-success';
        let iconClass = 'lmnicon-check-circle alert-icon-success';

        if (type.toLowerCase() === 'warning') {
            alertTitle = 'Warning';
            containerClass = 'alert-container-warning';
            iconClass = 'lmnicon-alert-circle alert-icon-warning';
        }

        if (type.toLowerCase() === 'error') {
            alertTitle = 'Error';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-alert-triangle alert-icon-error';
        }

        if (type.toLowerCase() === 'Confirm Cancel') {
            alertTitle = 'Warning';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-alert-triangle alert-icon-error';
        }

        if (type.toLowerCase() === 'warning-question') {
            alertTitle = 'Warning';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-alert-triangle alert-icon-error';
        }

        if (type.toLowerCase() === 'sessionsuspended') {
            alertTitle = 'Session Suspended';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-alert-triangle alert-icon-error';
        }

        if (type.toLowerCase() === 'locked') {
            alertTitle = 'Locked';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-alert-triangle alert-icon-error';
        }

        if (type.toLowerCase() === 'info') {
            alertTitle = 'Information';
            containerClass = 'alert-container-error';
            iconClass = 'lmnicon-info-circle-alt alert-icon-error';
            // <i class="lmnicon lmnicon-info-circle-alt"></i>
        }
        if (type.toLowerCase() === 'notification') {
            alertTitle = 'Notification';
            containerClass = 'alert-container-notification';
            iconClass = 'lmnicon-info-circle-o alert-icon-notification';
        }

        return (
            <div className={`cbusol-alert-container ${containerClass} ${className}`} style={style}>
                {/* Hide decorative icons */}
                <i aria-hidden={true} className={`lmnicon ${iconClass} ${iconClassName}`} />
                <div className='alert-container'>
                    <p className='alert-title' role={ariaNotifyDisabled ? undefined : role ? role : 'alert'} aria-level={ariaLevel ? ariaLevel : ''} aria-label={`${(ariaLabel ? ariaLabel + ',\n' : '') + (title ? title : alertTitle)}`}>{title ? title : alertTitle}</p>
                    {content ? !containsHyperlink ? <p aria-label={content} className='alert-content'>{content} </p> : null : null}
                    {htmlContent
                        ? !containsHyperlink
                            ? <p tabIndex={0} aria-label={htmlContentAriaLabel ? htmlContentAriaLabel : `${htmlContent}`}
                                className='alert-content' dangerouslySetInnerHTML={{ __html: htmlContent }}> 
                            </p>
                            : null
                        : null
                    }
                    {containsHyperlink ?
                        <div>
                            <p className='alert-hyperlink-content'>
                                {__constants.ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_1}
                                <span onClick={() => { history.push(APP_LOCATIONS.ForgotPasswordPage) }} className='alert-hyperlink'> {__constants.ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_2}</span>
                                {__constants.ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_3}
                            </p>
                        </div> : null
                    }
                    {dynamicContentWithHyperlink ?
                        <div>
                            <p className='alert-hyperlink-content'>
                                {initialContent}
                                <span onClick={() => { history.push(hyperlink ? hyperlink : '') }} className='alert-hyperlink-underline'> {hyperlinkContent}</span>
                                {concludingContent}
                            </p>
                        </div> : null
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            {_renderContentView()}
        </>
    );
}

export default Alert;