import kPIWIK from "../common/constants/PIWIK";
import kGENERAL from "../common/constants/GENERAL";
import { AxiosResponse } from 'axios';
import securityCtx from '../context/security-context';
import tokenHttpApi, { RequestMethod } from './api.token.regestration.api';

export interface VerifyTokenResponse {
    success: boolean, //true
    twelveDigitActivationCode: string
}

export interface RegisterStep2Response {
    serverEncryptedServerNonce: string,
    serverEncryptedPublicKeyAndNonce: string,
    serverInitialVector: string,
    serverInitialVector2: string,
    activationMessage1: string,
    success: boolean,
    genericError: any,
    message: string
}

export interface VerifySoftTokenResponse0 {
    serverInitialVector3: string,
    activationMessage2: string,
    success: boolean,
    message: string,
    errors: Array<JsonObj>
}
export interface JsonObj {
    name: string,
    value: string
}

export interface VerifySoftTokenResponseVo1 {
    displayInstanceWarning: boolean,
    instanceWarningIndex: number,
    success: boolean,
    message: string,
    jwtToken: string,
    registerNotifSuccess: boolean
}



export class VerifyTokenAPI {

    submitVerifySoftTokenModel(a: any, callBack?: Function, callBackFailure?: Function): Promise<AxiosResponse<VerifySoftTokenResponseVo1>> {
        console.log("submitVerifySoftTokenModel.....0");
        // console.log(a);
        const postData = {
            ...a
        }
        console.log("submitVerifySoftTokenModel.....1");
        // console.log(postData);
        return tokenHttpApi.makeHttpRequest(
            RequestMethod.POST,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}submitVerifySoftTokenPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            postData,
            callBack,
            kPIWIK.ChannelEnum.LOGIN,
            "submitVerifySoftTokenPage",
            callBackFailure) as Promise<AxiosResponse<VerifySoftTokenResponseVo1>>;
    }

    activateTokenInstanceModel(activateTokenLicense1NativeResponse: any, callBack?: Function, callBackFailure?: Function): Promise<AxiosResponse<VerifySoftTokenResponse0>> {
        console.log("activateTokenInstanceModel.....0");
        // console.log(activateTokenLicense1NativeResponse);
        const postData = {
            clientInitialVector2: activateTokenLicense1NativeResponse.clientInitialVector2,
            encryptedServerNonce: activateTokenLicense1NativeResponse.clientEncryptedServerNonce,
            deviceCode: activateTokenLicense1NativeResponse.deviceCode
        }
        if (activateTokenLicense1NativeResponse.clientInitialVector2 !== undefined && activateTokenLicense1NativeResponse.clientInitialVector2 !== "") {
            console.log("activateTokenInstanceModel.....1");
            // console.log(postData);
            return tokenHttpApi.makeHttpRequest(
                RequestMethod.POST,
                `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}submitActivateTokenInstancePage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
                null,
                postData,
                callBack,
                kPIWIK.ChannelEnum.LOGIN,
                "submitActivateTokenInstancePage",
                callBackFailure) as Promise<AxiosResponse<VerifySoftTokenResponse0>>;
        } else {
            console.log("activateTokenInstanceModel..... duplicate");
            return new Promise((resolve, reject) => {
                reject({ data: "duplicate call" })
            });
        }
    }

    activateTokenLicenseModel(activatePassword0NativeReponse: any, callBack?: Function, callBackFailure?: Function): Promise<AxiosResponse<RegisterStep2Response>> {
        const postData = {
            userP86Id: securityCtx?.getUserP86Id(),
            clientKey: activatePassword0NativeReponse.clientEncryptedPublicKeyAndNonce,
            clientInitialVector: activatePassword0NativeReponse.clientInitialVector,
            app: true,
            appVersion: window.DEVICE_INFO.version,
            version: window.DEVICE_INFO.version
        }

        return tokenHttpApi.makeHttpRequest(
            RequestMethod.POST,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}submitActivateTokenLicensePage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            postData,
            callBack,
            kPIWIK.ChannelEnum.LOGIN,
            "submitActivateTokenLicensePage",
            callBackFailure) as Promise<AxiosResponse<RegisterStep2Response>>;

    }
    //    Request URL: https://mobile.citibusinessonline.com/citibusinessMobileLogin
    //    Request Method: POST
    //    Accept: application/json, text/javascript, */*; q=0.01
    //    Content-Type: application/x-www-form-urlencoded

    fetchVerificationCode(sixDigitActivationCode: string, callBack?: Function, callBackFailure?: Function): Promise<AxiosResponse<VerifyTokenResponse>> {
        const postData = {
            "sixDigitActivationCode": sixDigitActivationCode
        }

        return tokenHttpApi.makeHttpRequest(
            RequestMethod.POST,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}fetchVerificationCode/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            postData,
            callBack,
            kPIWIK.ChannelEnum.LOGIN,
            "fetchVerificationCode",
            callBackFailure) as Promise<AxiosResponse<VerifyTokenResponse>>;
    }
}

const __instance__ = new VerifyTokenAPI();

export default __instance__;
