import React from 'react';
import { createRoot } from 'react-dom/client';
import { CssProvider } from '@citi-icg-172888/icgds-react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './common/styles/common.scss';
import { DEVICE_TYPE } from './device/xdevice';
import { disableConsoleLogs } from './utils/build.util';
import { addLogoutTimer } from './utils/timer.util';
import { ACTIVATE_DEVICE_WORKER } from './device/device.util';



//disableConsoleLogs()
window.extendSession = addLogoutTimer
window.REACT_APPLICATION = true;
window.isMobile = 'Y';
if (window.IOS_FLAG_SETBYDEVICE) {
  window.THE_DEVICE_TYPE = DEVICE_TYPE.WEB
}
else {
  window.THE_DEVICE_TYPE = DEVICE_TYPE.WEB
}
window.DEVICE_INFO = {};
window.CALLBACK_POOL = {};
window.ERROR_CALLBACK_POOL = {};
window.IOS_DEVICE_SUCCESS_CALLBACK_POOL = {};
window.IOS_DEVICE_ERROR_CALLBACK_POOL = {};
window.p86Cache = null;
console.log("window.THE_DEVICE_TYPE:" + window.THE_DEVICE_TYPE);

window.ExpireSession = undefined

window.CONFIG_TRIGGER_KEEP_ALIVE = false;

/**
 * This method is called by the native as soon as javascript interface in 
 * native is attached to the WebView
 * This method requires data, about the app
 *  - appVersion
 *  - deviceOs
 *  - model
 */
window.ACTIVATE_NATIVE_WORKER = ACTIVATE_DEVICE_WORKER;
window.AndroidSuccessCallbackPool = {};
window.AndroidFailureCallBackPool = {};

const root = createRoot(document.getElementById('react-view')!, { identifierPrefix: 'react-view' });
root.render(
  <CssProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CssProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
