import {
    Page404,
    LandingPage,
    ForgotPassword,
    ChangePassword,
    ResetPassword,
    SendEmailPage,
    LoginAll,
    ContactUsComponent,
    FaqComponent,
    PrivacyPage,
    CookiePolicy,
    TermsOfUsePage,
    OnlineUserAgreement,
    SessionSuspendedPage,
    MFALoginAll,
    //SwitchAccount
    IssueNewPassword,
    SuspendReactivateUser
} from '../components';
import FXVideoComponent from '../components/foreign-exchange/ui-kit/fx-demo-video/fx-demo-video.component';
import {
    ChangePin,
    VerifyTokenRegistration,
    PushNotifLanding
} from '../components/mfa.chunk.index';
import SwitchAccount from '../components/mfa/switch-account/switch-account';
import {
    TMXChallengePhoneOTP,
    TMXChallengeEmailOTP,
    EnrollmentPage
} from '../components/pre-signon.chunk.index';
import {
    SignInHome,
    ActionRequiredPage,
    AccountsPage,
    AccountsList,
    CreditAccountsList,
    AccountTransactionList,
    ApprovalsHome,
    ApprovalsTransactionTypes,
    ApprovalsTransactionList,
    DepositCheque,
    DepositCheckHome,
    MobileTokenHome,
    MobileTokenManagementHome,
    MobileTokenManagementDetail,
    PaymentsLanding,
    BillPayInitiate,
    ReviewPastBillPayments,
    ITInitiateLanding,
    InternalTransferTransactionList,
    WiresInitiateLanding,
    WireTransactionsLanding,
    InstantPayments,
    SAApprovals,
    MessagesLanding,
    MessageList,
    PositivePay,
    PositivePayDetails,
    RequireUserAgreement,
    EmailCapturePrimaryPage,
    RequireTokenRegistration,
    LoansBusinessInfo,
    TypesOfLoans,
    LoanDetails,
    TLMortgages,
    LoCTrades,
    LoanActivities,
    ForeignExchange,
} from '../components/signin-home.chunk.index';

import IRouteDef from "./route-def";

export enum APP_LOCATIONS {
    Login = "/login",
    HomePage = "/",
    SignOnPage = "/sign-in",
    SwitchAccount = "/switch-account",
    RegisterTokenPage = "/register-token",
    VerificationCodePage = "/verification-code",
    ForgotPIN = "/forgot-pin",
    ChangePIN = "/change-pin",
    MfaHome = '/generate-tokens',
    ForgotPasswordPage = "/forgot-password",
    ChangePasswordComponent = "/change-password",
    ResetPasswordComponent = "/reset-password",
    SendEmailPage = "/forgot-password-send-email",
    Approvals = "/approvals",
    ApprovalsTransactionTypes = '/approvals-transaction-types',
    ApprovalsTransactionList = '/approvals-transaction-list',
    DepositAccounts = "/accounts/deposit-account",
    CheckingAccounts = "/deposit-account/checking",
    ImmaAccounts = "/deposit-account/imma",
    SavingsAccounts = "/deposit-account/savings",
    CDsAccounts = "/deposit-account/cd",
    SavingsAccountTransactions = "/savings/transactions",
    CheckingAccountTransactions = "/checking/transactions",
    IMMAAccountTransactions = "/imma/transactions",
    CDAccountTransactions = "/cd/transactions",
    LineOfCreditAccountTransactions = "/credit/transactions",
    LoanAccountTransactions = "/loan/transactions",
    CreditCardAccountTransactions = "/credit-card/transactions",
    CreditAccounts = "/accounts/credit-account",
    LinesOfCredit = "/credit-account/credit",
    Loan = "/credit-account/loan",
    CreditCards = "/credit-account/credit-card",
    ControlAccounts = "/accounts/control-account",
    EscrowAccounts = "/control-account/escrow-accounts",
    DepositAdministratorAccounts = "/control-account/deposit-administrator-accounts",
    ExchangeManagementAccounts = "/control-account/exchange-management-accounts",
    SecurityDepositAccounts = "/control-account/security-deposit-accounts",
    EscrowTransactions = "/escrow-accounts/transactions",
    DepositAdministratorTransactions = "/deposit-administrator-accounts/transactions",
    ExchangeManagementTransactions = "/exchange-management-accounts/transactions",
    SecurityDepositTransactions = "/security-deposit-accounts/transactions",
    PushNotifLanding = "/push-notif-landing",
    MfaPushNotifEnroll = "/push-notif-enroll",
    ContactUs = "/contact-us",
    Faqs = "/faq",
    Privacy = "/privacy-statement",
    CookiePolicy = "/cookie-policy",
    TermsOfUse = "/terms-of-use",
    ActionRequired = "/action-required",
    NotFound = '/not-found',
    OnlineUserAgreement = '/online-user-agreement',
    SignInHome = '/signInHome',
    MobileTokenManagement = '/mobile-token-management',
    MobileTokenEdit = "/manage-tokens-registration",
    DepositCheques = '/cheque-deposits',
    PaymentsTransfersHome = '/payments-transfers',
    DepositCheckLanding = '/deposit-check-home',
    BillPayInitiate = '/bill-pay-initiate',
    ReviewPastBillPayments = '/review-past-bill-payments',
    InternalTransferInitiate = '/internal-transfers-initiate',
    TMXChallengePhoneOTP = '/tmx-challenge-phone-otp',
    TMXChallengeEmailOTP = '/tmx-challenge-email-otp',
    BANKING_ENROLLMENT = '/banking-enrollment',
    PostivePayExceptions = '/positive-pay-exceptions',
    PostivePayDispositioned = '/positive-pay-dispositioned',
    PositivePayDetails = '/positive-pay-checkspage',
    EmailCapturePrimaryPage = '/email-capture-primaryPage',
    EmailVerifyPrimaryPage = '/email-verify-primaryPage',
    EmailChangePrimaryPage = '/email-change-primaryPage',
    //internal transfers list
    InternalTransferTransactionList = '/internal-transfers-transactions',
    WireTransactionList = '/wire-transactions',
    SessionSuspended = '/session-suspended',
    WiresInitiate = '/wire-initiate',
    InstantPayments = '/instant-payments',
    //System Administration
    IssueNewPasswordComponent = '/issue-new-password',
    SuspendReactivateUserComponent = '/suspend-reactivate-user',
    SAApprovals = '/system-admin-approvals',
    MessageLanding = '/messages',
    MessageList = '/message-list',

    //Require User Agreement
    RequireUserAgreement = '/require-user-agreement',
    RequireTokenRegistration = '/require-token-registration',

    //Loans
    BusinessInfo = '/loans-business-info',
    TypeOfLoans = '/type-of-loans',
    TLMortgagesLoans = '/tl-mortgages',
    LoCTrades = '/loc-trades',
    LoanDetails = '/loan-details',
    LoanActivities = '/loan-activities',

    //FX-Pulse
    ForeignExchange = '/foreign-exchange',
    FXDemoVideo = '/fx-demo-video'
}
export const ROUTES: IRouteDef[] = [
    {
        path: APP_LOCATIONS.SwitchAccount,
        component: SwitchAccount,
        analytics: {
            eventCategory: "Switch Account"
        }
    },
    {
        path: APP_LOCATIONS.InstantPayments,
        component: InstantPayments,
        analytics: {
            eventCategory: 'RealTimePayment Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.WireTransactionList,
        component: WireTransactionsLanding,
        analytics: {
            eventCategory: 'Wires Transaction List'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.WiresInitiate,
        component: WiresInitiateLanding,
        analytics: {
            eventCategory: 'Wire Initiate Landing'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SessionSuspended,
        component: SessionSuspendedPage,
        analytics: {
            eventCategory: 'Session Suspended'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.InternalTransferTransactionList,
        component: InternalTransferTransactionList,
        analytics: {
            eventCategory: 'Internal Transfers Transaction List'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.BANKING_ENROLLMENT,
        component: EnrollmentPage,
        analytics: {
            eventCategory: 'Banking Enrollment'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.TMXChallengePhoneOTP,
        component: TMXChallengePhoneOTP,
        analytics: {
            eventCategory: 'TMX Challenge Phone OTP'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.TMXChallengeEmailOTP,
        component: TMXChallengeEmailOTP,
        analytics: {
            eventCategory: 'TMX Challenge Email OTP'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.BillPayInitiate,
        component: BillPayInitiate,
        analytics: {
            eventCategory: 'Make Bill Payments'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ReviewPastBillPayments,
        component: ReviewPastBillPayments,
        analytics: {
            eventCategory: 'Review Past Bill Payments'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.InternalTransferInitiate,
        component: ITInitiateLanding,
        analytics: {
            eventCategory: 'Setup Internal Transfers'
        },
        secure: true
    },

    {
        path: APP_LOCATIONS.PaymentsTransfersHome,
        component: PaymentsLanding,
        analytics: {
            eventCategory: 'Payments & Transfers Home'
        },
        secure: true

    },
    {
        path: APP_LOCATIONS.DepositCheques,
        component: DepositCheque,
        analytics: {
            eventCategory: 'Deposit Cheques'
        },
        secure: true

    },
    {
        path: APP_LOCATIONS.DepositCheckLanding,
        component: DepositCheckHome,
        analytics: {
            eventCategory: 'Deposit Cheques'
        },
        secure: true,
        mobileOnly: true
    },
    {
        path: APP_LOCATIONS.DepositAccounts,
        component: AccountsPage,
        analytics: {
            eventCategory: 'Deposit Accounts Tab'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.PushNotifLanding,
        component: PushNotifLanding,
        analytics: {
            eventCategory: 'MFA Notifications Landing Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MfaPushNotifEnroll,
        component: MobileTokenHome,
        analytics: {
            eventCategory: 'MFA Notifications Enroll and Access Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MfaHome,
        component: MobileTokenHome,
        analytics: {
            eventCategory: 'Mobile Token Home'
        },
        secure: true,
        mobileOnly: false
    },
    {
        path: APP_LOCATIONS.Approvals,
        component: ApprovalsHome,
        analytics: {
            eventCategory: 'Approvals Home Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ApprovalsTransactionTypes,
        component: ApprovalsTransactionTypes,
        analytics: {
            eventCategory: 'Approvals Transaction'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SAApprovals,
        component: SAApprovals,
        analytics: {
            eventCategory: 'System Admin Approvals'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ApprovalsTransactionList,
        component: ApprovalsTransactionList,
        analytics: {
            eventCategory: 'Awaiting Transaction Approval Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MobileTokenManagement,
        component: MobileTokenManagementHome,
        analytics: {
            eventCategory: 'Mobile Token Management'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MobileTokenEdit,
        component: MobileTokenManagementDetail,
        analytics: {
            eventCategory: 'Manage Mobile Token'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.CreditAccounts,
        component: AccountsPage,
        analytics: {
            eventCategory: 'Credit Accounts Tab'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.CheckingAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Checking Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ImmaAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'IMMA Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SavingsAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Savings Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.EscrowAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Escrow Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.DepositAdministratorAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Deposit Administrator Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ExchangeManagementAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Exchange Management Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SecurityDepositAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'Security Deposit Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.EscrowTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Escrow Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ExchangeManagementTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Exchange Management Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.DepositAdministratorTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Deposit Administrator Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SecurityDepositTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Security Deposit Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SavingsAccountTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Savings Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.CheckingAccountTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'Checking Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.IMMAAccountTransactions,
        component: AccountTransactionList,
        analytics: {
            eventCategory: 'IMMA Account Transactions Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.CDAccountTransactions,
        component: AccountTransactionList,
        secure: true
    },
    {
        path: APP_LOCATIONS.LineOfCreditAccountTransactions,
        component: AccountTransactionList,
        secure: true
    },
    {
        path: APP_LOCATIONS.LoanAccountTransactions,
        component: AccountTransactionList,
        secure: true
    },
    {
        path: APP_LOCATIONS.CreditCardAccountTransactions,
        component: AccountTransactionList,
        secure: true
    },

    {
        path: APP_LOCATIONS.CDsAccounts,
        component: AccountsList,
        analytics: {
            eventCategory: 'CD Accounts Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.LinesOfCredit,
        component: CreditAccountsList,
        analytics: {
            eventCategory: 'Lines Of Credit Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.Loan,
        component: CreditAccountsList,
        analytics: {
            eventCategory: 'Loan Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.CreditCards,
        component: CreditAccountsList,
        analytics: {
            eventCategory: 'Credit Cards Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ControlAccounts,
        component: AccountsPage,
        analytics: {
            eventCategory: 'Control Accounts Tab'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SignOnPage,
        component: LoginAll,
        analytics: {
            eventCategory: 'Sign On Page'
        }
    },
    {
        path: APP_LOCATIONS.ChangePIN,
        component: ChangePin,
        analytics: {
            eventCategory: 'Change PIN Page'
        }
    },
    {
        path: APP_LOCATIONS.ForgotPIN,
        component: MFALoginAll,
        analytics: {
            eventCategory: 'Forgot PIN Page'
        }
    },
    {
        path: APP_LOCATIONS.RegisterTokenPage,
        component: MFALoginAll,
        analytics: {
            eventCategory: 'Register Token Page'
        }
    },
    {
        path: APP_LOCATIONS.VerificationCodePage,
        component: VerifyTokenRegistration,
        analytics: {
            eventCategory: 'Verification Code Page'
        }
    },
    {
        path: APP_LOCATIONS.ForgotPasswordPage,
        component: ForgotPassword,
        analytics: {
            eventCategory: 'Forgot Password Page'
        }
    },
    {
        path: APP_LOCATIONS.ChangePasswordComponent,
        component: ChangePassword,
        analytics: {
            eventCategory: 'Change Password Page'
        }
    },
    {
        path: APP_LOCATIONS.ResetPasswordComponent,
        component: ResetPassword,
        analytics: {
            eventCategory: 'Reset Password Page'
        }
    },
    {
        path: APP_LOCATIONS.SendEmailPage,
        component: SendEmailPage,
        analytics: {
            eventCategory: 'Send Email Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ContactUs,
        component: ContactUsComponent,
        analytics: {
            eventCategory: 'Contact Us Page'
        }
    },
    {
        path: APP_LOCATIONS.Faqs,
        component: FaqComponent,
        analytics: {
            eventCategory: 'FAQs Page'
        }
    },
    {
        path: APP_LOCATIONS.Privacy,
        component: PrivacyPage,
        analytics: {
            eventCategory: 'Privacy Page'
        }
    },
    {
        path: APP_LOCATIONS.CookiePolicy,
        component: CookiePolicy,
        analytics: {
            eventCategory: 'Cookie Policy Page'
        }
    },
    {
        path: APP_LOCATIONS.TermsOfUse,
        component: TermsOfUsePage,
        analytics: {
            eventCategory: 'Terms Of Use Page'
        }
    },
    {
        path: APP_LOCATIONS.ActionRequired,
        component: ActionRequiredPage,
        analytics: {
            eventCategory: 'Action Required Page'
        },
        secure: true
    },
    {

        path: APP_LOCATIONS.OnlineUserAgreement,
        component: OnlineUserAgreement,
        analytics: {
            eventCategory: 'Online User Agreement Page'
        }
    },
    {
        path: APP_LOCATIONS.SignInHome,
        component: SignInHome,
        secure: true,
        analytics: {
            eventCategory: 'Sign In Home Page'
        }
    },
    {
        path: APP_LOCATIONS.NotFound,
        component: Page404,
        analytics: {
            eventCategory: 'Not Found Page'
        }
    },
    {
        path: APP_LOCATIONS.IssueNewPasswordComponent,
        component: IssueNewPassword,
        analytics: {
            eventCategory: 'Issue New Password Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.SuspendReactivateUserComponent,
        component: SuspendReactivateUser,
        analytics: {
            eventCategory: 'Suspend OR Reactivate User Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MessageLanding,
        component: MessagesLanding,
        analytics: {
            eventCategory: 'Message Landing Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.MessageList,
        component: MessageList,
        analytics: {
            eventCategory: 'Message List'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.PostivePayExceptions,
        component: PositivePay,
        analytics: {
            eventCategory: 'Positive Pay Exceptions'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.PostivePayDispositioned,
        component: PositivePay,
        analytics: {
            eventCategory: 'Positive Pay Dispositioned'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.PositivePayDetails,
        component: PositivePayDetails,
        analytics: {
            eventCategory: 'Positive Pay Checks Page'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.EmailCapturePrimaryPage,
        component: EmailCapturePrimaryPage,
        analytics: {
            eventCategory: 'Capture email primary page'
        }
    },
    {
        path: APP_LOCATIONS.EmailVerifyPrimaryPage,
        component: EmailCapturePrimaryPage,
        analytics: {
            eventCategory: 'Verify email primary page'
        }
    },
    {
        path: APP_LOCATIONS.EmailChangePrimaryPage,
        component: EmailCapturePrimaryPage,
        analytics: {
            eventCategory: 'Change email primary page'
        }
    },
    {
        path: APP_LOCATIONS.RequireUserAgreement,
        component: RequireUserAgreement,
        analytics: {
            eventCategory: 'User Agreement'
        }
    },
    {
        path: APP_LOCATIONS.RequireTokenRegistration,
        component: RequireTokenRegistration,
        analytics: {
            eventCategory: 'Require Token Registration'
        }
    },
    {
        path: APP_LOCATIONS.BusinessInfo,
        component: LoansBusinessInfo,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.TypeOfLoans,
        component: TypesOfLoans,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.TLMortgagesLoans,
        component: TLMortgages,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.LoCTrades,
        component: LoCTrades,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.LoanDetails,
        component: LoanDetails,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.LoanActivities,
        component: LoanActivities,
        analytics: {
            eventCategory: 'Loans'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.ForeignExchange,
        component: ForeignExchange,
        analytics: {
            eventCategory: 'FX Pulse'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.FXDemoVideo,
        component: FXVideoComponent,
        analytics: {
            eventCategory: 'FX Pulse'
        },
        secure: true
    },
    {
        path: APP_LOCATIONS.HomePage,
        component: LandingPage,
        analytics: {
            eventCategory: 'Home Page'
        }
    },
];