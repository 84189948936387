import { useEffect, useState } from "react";
import { DatePicker as IcgdsDatePicker } from '@citi-icg-172888/icgds-react';
import __constants from "../../common/constants";
import _ from "lodash";
import moment from "moment";

interface DatePickerProps {
    allowClear?: boolean,
    disabled?: boolean,
    format?: string,
    placeholder?: string,
    triggerClass?: string,
    className?: string,
    showDateInput?: boolean,
    showToday?: boolean,
    disabledDate?: any,
    onChange?: Function,
    dateRender?: any,
    disableToday?: boolean,

    //For input textfield
    defaultValue?: any,
    onFocus?: any,
    calendarClass?: string,
    inputAriaLabel?: string,
    inputInnerHTML?: string

    //For filter, need to aria-hidden for filter search modal
    classNameForAriaHidden?: string;
    renderTriggerElement?: any;
    open?: any;
    onOpenChange?: Function;

    //For Fx-pulse
    onShowDateChange?: Function;
}

const DatePicker = (p: DatePickerProps) => {
    const allowClear = p.allowClear !== undefined ? p.allowClear : false;
    const showDateInput = p.showDateInput !== undefined ? p.showDateInput : true;
    const showToday = p.showToday !== undefined ? p.showToday : true;
    const disableToday = p.disableToday !== undefined ? p.disableToday : false;

    const [value, setValue] = useState(p.defaultValue ? p.defaultValue : null);
    const [datePickerMode, setDatePickerMode] = useState<string>();
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        if (p.defaultValue) {
            setValue(p.defaultValue);
        }
    }, [p.defaultValue]);

    /*
    *ADA Related code ends
    */
    useEffect(() => {
        if (showDatePicker) {
            _.delay(() => {
                const nodes = document.querySelector('.lmn-datepicker-header')?.getElementsByTagName('a');
                if (nodes && nodes.length > 0) {
                    _.each(nodes, (val: HTMLElement) => {
                        val.role = "button";
                        if (val.className === 'lmn-datepicker-month-select') {
                            val.setAttribute("tabIndex", "-1");
                            val.focus();
                        } else if (val.className === 'lmn-datepicker-next-year-btn' || val.className === 'lmn-datepicker-prev-year-btn') {
                            val.setAttribute("aria-hidden", "true");
                        }
                    })
                }

                if (showDateInput) {
                    //ADA- Hide visible clear button from screen reader
                    document.querySelector('.lmn-datepicker-input-wrap')?.getElementsByTagName('a')[0].classList.add("clear-button");
                    const clearButtonEle: HTMLElement | null = document.querySelector(`.clear-button`);
                    clearButtonEle?.setAttribute("aria-hidden", "true");
                }

                //if DatePickerMode is already date, set aria-label directly in setAccessibilityAttribute, else set datePickerMode to 'date'
                datePickerMode === 'date' ? setAccessibilityAttribute() : setDatePickerMode('date');
            }, 100);
        }
    }, [showDatePicker]);

    useEffect(() => {
        if (showDatePicker) {
            _.delay(() => {
                setAccessibilityAttribute();
            }, 300);
        }
    }, [datePickerMode]);

    /*
    *ADA | Android - fix Able to browse outside modal 
    */

    useEffect(() => {
        const classNameToFind = p.classNameForAriaHidden ? p.classNameForAriaHidden : '#cbusol-view';
        const ele: Element | null = document.querySelector(classNameToFind);
        if (ele) {
            if (showDatePicker) {
                ele.setAttribute('aria-hidden', 'true');
            } else {
                ele.removeAttribute('aria-hidden');
            }
        }
        return () => {
            if (ele) {
                ele.removeAttribute('aria-hidden');
            }
        }

    }, [showDatePicker])

    /* ADA - as per mode selection, focus to respected element */
    const setAccessibilityAttribute = (currentMode?: string) => {
        const mode = currentMode === null || currentMode === undefined ? datePickerMode : currentMode;

        //For input textfield
        if (showDateInput) {

            // inputDateEle.setAttribute('aria-label', `${window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS && date && date.length > 0 ? 'Select Date' : `Select Date, ${date && date.length > 0 ? date + ', ' : ''}Text field, Double tap to edit`}`);

            const inputDateWrapEle: Element | null = document.querySelector('.lmn-datepicker-panel .lmn-datepicker-date-input-wrap');
            if (inputDateWrapEle) {
                const inputDateEle: Element | null = document.querySelector('.lmn-datepicker-panel .lmn-datepicker-input');
                if (inputDateEle) {
                    inputDateEle.setAttribute('aria-label', p.inputAriaLabel ? p.inputAriaLabel : 'Select Date');
                    inputDateEle.setAttribute('role', 'textbox');
                    inputDateEle.setAttribute('tabindex', '0');
                }
                const labelEle = inputDateWrapEle.getElementsByTagName('label').length

                if (labelEle === 0 && inputDateEle) {
                    const inputHeaderLabel = document.createElement("label");
                    inputHeaderLabel.setAttribute("class", "text-small");
                    inputHeaderLabel.innerHTML = p.inputInnerHTML ? p.inputInnerHTML : 'Select Date';
                    inputHeaderLabel.setAttribute("style", "color:black; text-align:left; width:100%; display:flex");
                    inputHeaderLabel.setAttribute("aria-label", inputHeaderLabel.innerHTML);
                    inputDateWrapEle.insertBefore(inputHeaderLabel, inputDateEle);
                }
            }
        }

        //Make Year header panel readable when mode is year
        const datePickerYearHeaderEle: Element | null = document.querySelector('.lmn-datepicker .lmn-datepicker-year-panel-header');
        if (datePickerYearHeaderEle) {
            const childNodeCount = datePickerYearHeaderEle?.childNodes.length
            if (childNodeCount) {
                for (let i = 0; i < childNodeCount; i++) {
                    const aTagEle = datePickerYearHeaderEle.getElementsByTagName('a')[i];
                    if (aTagEle) {
                        aTagEle?.setAttribute('role', 'button');
                        //focus to header
                        if (aTagEle.className === "lmn-datepicker-year-panel-decade-select") {
                            if (mode === "year") {
                                aTagEle?.setAttribute('tabIndex', '-1');
                                aTagEle?.focus();
                            }
                        }
                    }
                }
            }
        }

        //For year selection body elements when mode is year
        const yearSelectionBodyTable: HTMLElement | null = document.querySelector(".lmn-datepicker-year-panel-table")
        if (yearSelectionBodyTable) {
            yearSelectionBodyTable.setAttribute("role", "presentation");
            const yearSelectionBodyChildEles = yearSelectionBodyTable.querySelector(".lmn-datepicker-year-panel-tbody")?.getElementsByTagName("td")
            if (yearSelectionBodyChildEles) {
                for (var i = 0; i < yearSelectionBodyChildEles.length; i++) {
                    var tdTagEle = yearSelectionBodyChildEles[i];
                    tdTagEle.setAttribute("aria-hidden", "false");

                    const className = tdTagEle.classList;
                    let ariaLabel = tdTagEle.title;
                    if (className.contains('lmn-datepicker-year-panel-selected-cell')) {
                        ariaLabel = ariaLabel + ' Selected';
                    }

                    const aTagEle = tdTagEle.querySelector(".lmn-datepicker-year-panel-year");
                    if (aTagEle) {
                        if (mode === "year") {
                            aTagEle.setAttribute("aria-hidden", "false");
                            aTagEle.setAttribute('role', 'button');
                            aTagEle.setAttribute("aria-label", ariaLabel);
                        } else {
                            aTagEle.removeAttribute("aria-label");

                        }
                    }
                }
            }
        }

        //Make Month header panel readable when mode is month
        const datePickerMonthHeaderEle: Element | null = document.querySelector('.lmn-datepicker .lmn-datepicker-month-panel-header');
        if (datePickerMonthHeaderEle) {
            const childNodeCount = datePickerMonthHeaderEle?.childNodes.length
            if (childNodeCount) {
                for (let i = 0; i < childNodeCount; i++) {
                    const aTagEle = datePickerMonthHeaderEle.getElementsByTagName('a')[i];
                    if (aTagEle) {
                        aTagEle?.setAttribute('role', 'button');
                        //focus to header
                        if (aTagEle.className === "lmn-datepicker-month-panel-year-select") {
                            if (mode === "month") {
                                aTagEle?.setAttribute('tabIndex', '-1');
                                aTagEle?.focus();
                            }
                        }
                    }
                }

            }
        }

        //For month selection bdoy elements when mode is month
        const monthSelectionBodyTable: HTMLElement | null = document.querySelector(".lmn-datepicker-month-panel-table")
        if (monthSelectionBodyTable) {
            monthSelectionBodyTable.setAttribute("role", "presentation");
            const monthSelectionBodyChildEles = monthSelectionBodyTable.querySelector(".lmn-datepicker-month-panel-tbody")?.getElementsByTagName("td")
            if (monthSelectionBodyChildEles) {
                for (var j = 0; j < monthSelectionBodyChildEles.length; j++) {
                    var tdTagElement = monthSelectionBodyChildEles[j];
                    tdTagElement.setAttribute("aria-hidden", "false");

                    const className = tdTagElement.classList;
                    let ariaLabel = tdTagElement.title;

                    //Take full month name based on index fromt the constant file
                    const monthIndex = (3 * (tdTagElement.parentNode as HTMLTableRowElement).rowIndex) + tdTagElement.cellIndex;
                    if (monthIndex < __constants.WIRES_INITIATE.MONTHS.length) {
                        ariaLabel = __constants.WIRES_INITIATE.MONTHS[monthIndex];
                    }

                    if (className.contains('lmn-datepicker-month-panel-current-cell')) {
                        ariaLabel = ariaLabel + ' Current month';
                    }
                    if (className.contains('lmn-datepicker-month-panel-selected-cell')) {
                        ariaLabel = ariaLabel + ' Selected';
                    }

                    const aTagEle = tdTagElement.querySelector(".lmn-datepicker-month-panel-month");
                    if (aTagEle) {
                        if (mode === "month") {
                            aTagEle.setAttribute("aria-hidden", "false");
                            aTagEle.setAttribute('role', 'button');
                            aTagEle.setAttribute("aria-label", ariaLabel);
                        } else {
                            aTagEle.removeAttribute("aria-label");
                        }
                    }
                }
            }
        }


        const datePickerBodyEle: Element | null = document.querySelector('.lmn-datepicker .lmn-datepicker-body');
        const datePickerHeaderMonYearSelectEle: HTMLElement | null = document.querySelector('.lmn-datepicker .lmn-datepicker-my-select');
        const dateSelectionHeaderEleClassNames = ['.lmn-datepicker-prev-month-btn', '.lmn-datepicker-month-select',
            '.lmn-datepicker-year-select', '.lmn-datepicker-next-month-btn'];

        if (mode !== null && mode !== undefined && mode !== 'date') {
            //When mode is year/month, date selection header should not be accessible
            if (datePickerBodyEle) {
                datePickerBodyEle.setAttribute("aria-hidden", "true");
            }
            if (datePickerHeaderMonYearSelectEle) {
                datePickerHeaderMonYearSelectEle.setAttribute("aria-hidden", "true");

                //All <a> child of date selection header
                const dateSelectionHeaderAllChild = document.querySelector(".lmn-datepicker-header")?.getElementsByTagName("a");
                if (dateSelectionHeaderAllChild) {

                    for (var k = 0; k < dateSelectionHeaderEleClassNames.length; k++) {
                        var aTagEle = document.querySelector(dateSelectionHeaderEleClassNames[k]);
                        if (aTagEle) {
                            aTagEle.setAttribute("aria-hidden", "true");
                        }
                    }
                }
            }
        } else {
            //When mode is date, date selection header should be accessible
            if (datePickerBodyEle) {
                datePickerBodyEle.setAttribute("aria-hidden", "false");
            }
            if (datePickerHeaderMonYearSelectEle) {
                datePickerHeaderMonYearSelectEle.setAttribute("aria-hidden", "false");

                datePickerHeaderMonYearSelectEle.setAttribute("tabIndex", "-1");
                datePickerHeaderMonYearSelectEle.focus();
                //All <a> child of date selection header
                const dateSelectionHeaderAllChild = document.querySelector(".lmn-datepicker-header")?.getElementsByTagName("a")
                if (dateSelectionHeaderAllChild) {
                    for (var s = 0; s < dateSelectionHeaderEleClassNames.length; s++) {
                        var aTagElement = document.querySelector(dateSelectionHeaderEleClassNames[s]);
                        if (aTagElement) {
                            aTagElement.setAttribute("aria-hidden", "false");
                            aTagElement.setAttribute("role", "button");
                        }
                    }
                }
            }
        }

        const tableEle: Element | null = document.querySelector(".lmn-datepicker-table");
        //For weekdays header
        if (tableEle) {
            tableEle.setAttribute("role", "presentation");
            const tHeadEle = tableEle.getElementsByTagName('thead')[0];
            if (tHeadEle) {
                const subEle = tHeadEle.getElementsByTagName('tr')[0];
                if (subEle) {
                    const subThEle = subEle.getElementsByTagName('th');
                    if (subThEle) {
                        for (let i = 0; i < subThEle.length; i++) {
                            const thEle = subThEle[i];
                            thEle?.removeAttribute("title");
                            if (mode !== null && mode !== undefined && mode !== 'date') {
                                thEle?.setAttribute("aria-hidden", "true");
                                thEle?.removeAttribute("tabIndex");
                            } else {
                                thEle?.setAttribute('aria-label', `${__constants.WIRES_INITIATE.DAYS[i]}`)
                                thEle?.setAttribute("tabIndex", "0");
                                thEle?.setAttribute("aria-hidden", "false");
                            }

                            const spanEleArray = thEle.querySelector(`.lmn-datepicker-column-header-inner`);
                            if (spanEleArray) {
                                spanEleArray?.setAttribute("aria-hidden", "true");
                            }

                        }
                    }
                }
            }
        }

        //For date elements
        const dateSelectionBodyChildEles = document.querySelector(".lmn-datepicker-tbody")?.getElementsByTagName("td")
        if (dateSelectionBodyChildEles) {

            for (var r = 0; r < dateSelectionBodyChildEles.length; r++) {
                var tdElement = dateSelectionBodyChildEles[r];
                tdElement.setAttribute("aria-hidden", "false");
                const className = tdElement.classList;

                let ariaLabel = tdElement.title;
                ariaLabel = ariaLabel + ' ' + __constants.WIRES_INITIATE.DAYS[tdElement.cellIndex];

                if (className.contains('lmn-datepicker-next-month-btn-day')) {
                    ariaLabel = ariaLabel + ' Next month date, please select next month first';
                }

                if (className.contains('lmn-datepicker-today')) {
                    if (disableToday) {
                        ariaLabel = ariaLabel + ' Today button disabled';
                    }
                    else {
                        ariaLabel = ariaLabel + ' Current date';
                    }
                }

                if (className.contains('lmn-datepicker-selected-day')) {
                    ariaLabel = ariaLabel + ' Selected';
                }

                const divTagEle = tdElement.querySelector('.lmn-datepicker-date');
                if (divTagEle) {
                    if (mode !== 'year' && mode !== 'month') {
                        divTagEle.setAttribute("aria-label", ariaLabel);
                        divTagEle.setAttribute("tabIndex", "0");
                        divTagEle.setAttribute("role", "button");
                    } else {
                        divTagEle.removeAttribute("aria-label");
                        divTagEle.removeAttribute("tabIndex");
                    }
                }
            }
        }
    }
    /*
    *ADA Related code ends
    */

    const onChange = (date: moment.Moment | null, dateString: string) => {
        if (p.onChange) {
            p.onChange(date, dateString)
        }

        setValue(date);
    }

    const onDatePickerOpen = (status: boolean | undefined) => {
        if (_.isBoolean(status)) {
            setShowDatePicker(status);
        }

        if (p.onOpenChange) {
            p.onOpenChange(value)
        }
    }

    const onPanelChange = (value: moment.Moment | null, mode: string) => {
        setDatePickerMode(mode);
    }

    const onShowDateChange = (value: moment.Moment | null) => {
        _.delay(() => {
            setAccessibilityAttribute();
        }, 300);

        if (p.onShowDateChange) {
            p.onShowDateChange(value)
        }
    }

    return (
        <IcgdsDatePicker
            allowClear={allowClear}
            disabled={p.disabled}
            format={p.format}
            placeholder={p.placeholder ? p.placeholder : 'Select date'}
            value={value} // the defaultValue don't displayed on iOS 16.x app. Set value to defaultValue and Update when the selected time is changing.
            triggerClass={p.triggerClass}
            className={p.className}
            showDateInput={showDateInput}
            showToday={showToday}
            disabledDate={p.disabledDate ? p.disabledDate : false}
            onChange={onChange}
            onOpenChange={onDatePickerOpen}
            onPanelChange={onPanelChange}
            onShowDateChange={onShowDateChange}
            dateRender={p.dateRender}

            //For input textfield
            defaultValue={p.defaultValue}
            onFocus={p.onFocus}
            calendarClass={p.calendarClass}
            renderTriggerElement={p.renderTriggerElement}
            // open={p.open ? p.open : showDatePicker}
        />
    );
}

export default DatePicker;
