import { useState, useEffect, useContext } from "react";
import __constants from '../../common/constants';
import __req from '../../api/messages.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import MessageTextPage from "./message-text-page.component";
import './message-list.style.scss';
import _ from "lodash";
import moment from 'moment';
import { Loading } from '@citi-icg-172888/icgds-react';
import Button from "../../ui-kit/button/button";
import LandingHeader from "../header/header.component";
import ApplicationContext from "../../context/application-context";
import { APP_LOCATIONS } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import Alert from '../..//ui-kit/alert/alert.component';
import { addLogoutTimer } from '../../utils/timer.util';
import ListCardComponent from "../../ui-kit/list-card/list-card.component";

function MessageListPage() {
    const [showLoading, setShowLoading] = useState(true);
    const [messageList, setMessageList] = useState([]);
    const [showNoData, setShowNoData] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');
    const [showMessageText, setShowMessageText] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState<any>(); // ADA focus to selected row on close of detail schedule model
    const [selectedMessage, setSelectedMessage] = useState<any>();
    const [responseBody, setResponseBody] = useState<any>();
    const [currentDisplayStartIndex, setCurrentDisplayStartIndex] = useState(0);
    const [asofDate, setAsofDate] = useState('');

    const context = useContext(ApplicationContext)
    const location = useLocation();
    const savedMessages: any = _.get(location, "state.data.savedMessages", false);

    useEffect(() => {
        _sendReqForMessageList();
    }, []);

    useEffect(() => {
        const ele: Element | null = document.querySelector('#cbusol-view');
        if (ele) {
            if (showMessageText) {
                ele.setAttribute('aria-hidden', 'true');
            } else {
                ele.removeAttribute('aria-hidden');
            }
        }
        return () => {
            if (ele) {
                ele.removeAttribute('aria-hidden');
            }
        }

    }, [showMessageText]);

    function userAction() {
        window.LOGOUT_TIMER_REACT = addLogoutTimer();
    }

    // ADA focus to selected row on close of detail schedule model
    function focusToLastSelectedRow() {
        if (selectedRowId !== null && selectedRowId !== undefined && selectedRowId.length > 0) {
            _.delay(() => {
                const currentSelectedEle = document.getElementById(selectedRowId);
                if (currentSelectedEle) {
                    currentSelectedEle.focus();
                }
            }, (400));
        }
    }

    /**
     * API calls
     */
    function _sendReqForMessageList(isNext?: boolean | null, isRefresh?: boolean) {
        userAction();
        let displayStartIndex = 0;
        if (isRefresh !== null && isRefresh !== undefined) {
            displayStartIndex = currentDisplayStartIndex
        } else if (isNext !== null) {
            if (isNext) {
                //Next clicked
                displayStartIndex = currentDisplayStartIndex + messageList.length;
            } else {
                //Previoud clicked
                displayStartIndex = currentDisplayStartIndex - messageList.length;
            }
        }
        if (displayStartIndex !== currentDisplayStartIndex) {
            setCurrentDisplayStartIndex(displayStartIndex);
        }
        const params = {
            "direction": 0,
            "displayStartIndex": displayStartIndex,
            "savedMessages": savedMessages
        };

        setShowLoading(true);
        __req
            .citibankMessageList(params, _handleMessageListResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleMessageListResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setShowLoading(false);
                setErrorInfo(__constants.REQ_FAILED);
            });
    }

    function _handleMessageListResp(resp: { [key: string]: any }) {
        setShowLoading(false);
        setShowNoData(false);
        setErrorInfo('');

        if (resp && resp.success && resp.body && resp.body.messages) {

            const body = resp.body;
            setResponseBody(body);

            const asOfDate = _.get(resp.body, "asofDate", false);
            setAsofDate(moment(asOfDate).format('MMMM D[,] YYYY'));

            //focus to header on page load
            setTimeout(() => {
                const headerEle: HTMLElement | null = document.querySelector('.header-content .header-text');
                if (headerEle) {
                    headerEle?.setAttribute('tabIndex', '0');
                    headerEle.focus();
                }
            }, 150);

            const messages = resp.body.messages;
            if (messages !== undefined && messages !== null && messages.length > 0) {
                setMessageList(messages);
            } else {
                setShowNoData(true);
            }
        } else {
            setErrorInfo(__constants.REQ_FAILED);
        }
    }

    const onPreviousClick = () => {
        _sendReqForMessageList(false);
    }

    const onNextClick = () => {
        _sendReqForMessageList(true);
    }

    //UI functions
    const _renderSingleMessage = (message: { [key: string]: any }, index: number) => {
        const messageDataArray = {
            [responseBody.date]: message.messageDate,
            [responseBody.from]: message.from,
            [responseBody.to]: message.to,
            [responseBody.subject]: message.text,
        };

        let ariaLabel = '';
        _.map(messageDataArray, (val: string, key: string) => {
            ariaLabel = ariaLabel + (ariaLabel.length > 0 ? ', ' : '') + `${key} ${val.toString()}`
        })
        ariaLabel = ariaLabel + ', Double tap to see details of this Message';

        return (
            <>
                <ListCardComponent
                    id={message.referenceNumber + index}
                    key={message.referenceNumber}
                    highlightHeader={message.referenceNumber}
                    ariaLabelData={ariaLabel}
                    ariaLabelHeader={`Heading 2, ${message.referenceNumber}`}
                    data={messageDataArray}
                    onClick={() => {
                        userAction();
                        setSelectedMessage(message);
                        setShowMessageText(true);
                        setSelectedRowId(message.referenceNumber + index);
                    }}
                />
            </>
        )
    }

    function _renderAsOfDate() {
        return (
            (asofDate && asofDate.length > 0)
                ?
                <span tabIndex={0} className='message-date-text'>
                    {`as of ${asofDate}`}
                </span>
                : null
        )
    }

    function _renderFooterButtons() {
        return (
            <div className="message-footer-button-wrap">
                {responseBody.previousPage &&
                    <Button outline
                        style={{ color: '#0066b3', borderColor: '#0066b3', flex: 1, margin: '10px 10px 0px 10px' }}
                        onClick={onPreviousClick}>
                        {responseBody.previous}
                    </Button>
                }
                {responseBody.hasMore &&
                    <Button outline
                        style={{ color: '#0066b3', borderColor: '#0066b3', flex: 1, margin: '10px 10px 0px 10px' }}
                        onClick={onNextClick}>
                        {responseBody.next}
                    </Button>
                }
            </div>
        )
    }

    return (
        <>
            <LandingHeader
                showBackBtn
                onBackClick={() => {
                    context.Router.navigate(APP_LOCATIONS.MessageLanding, {
                        isFromMessageList: true,
                    })
                }} />
            <div className="react-container bg-gray top-40 auto-overflow message-list">
                {
                    showLoading
                        ?
                        <Loading
                            tip={__constants.LOADING}
                            horizontal
                            className="loading-center"
                        />
                        :
                        <>
                            {errorInfo.length > 0 ?
                                <div style={{ marginBottom: '20px' }} aria-label={`Error ${errorInfo}`}>
                                    <Alert
                                        type='error'
                                        content={errorInfo}
                                    />
                                </div> : null
                            }

                            {_renderAsOfDate()}

                            {showNoData
                                ? (<span className="no-data">{__constants.NO_DATA}</span>)
                                : null
                            }

                            {!showNoData && !(errorInfo.length > 0) && responseBody !== undefined
                                ?
                                <>
                                    {messageList.map((message: { [key: string]: any }, index: number) => {
                                        return (
                                            _renderSingleMessage(message, index)
                                        );
                                    })}

                                    {_renderFooterButtons()}
                                </>
                                : null
                            }
                        </>
                }


            </div>

            {showMessageText ?
                <MessageTextPage
                    show={showMessageText}
                    messageData={selectedMessage}
                    savedMessages={savedMessages}
                    displayIndex="0"
                    onClose={(refreshList: boolean) => {
                        setShowMessageText(false);
                        if (refreshList) {
                            _sendReqForMessageList(null, true);
                        }
                        focusToLastSelectedRow();
                    }} />
                : null}
        </>
    );
}

export default MessageListPage;