import './notification-item.scss';

const NotificationItem = ({
    // key,
    content,
    actionText,
    header,
    time,

    actionClicked
}) => {

   return <div className="notification-item">
        <div className="ni-header">
            <h4 className="ni-header-text">{header}</h4>
            <span className="time-text">{time}</span>
        </div>
        <div className="ni-content">
            <p className="content-text">
                {content}
            </p>
        </div>
        <div className="ni-actions">
            <button className="ni-action-button" onClick={actionClicked}>{actionText}</button>
        </div>
    </div>

}

export default NotificationItem;