import React, { useContext, useEffect, useState } from 'react';
import { Loading } from '@citi-icg-172888/icgds-react';
import LandingHeader from '../header/header.component';
import __constants from '../../common/constants'
import ApplicationContext from '../../context/application-context';
import ContextData from '../../context/context-data.interface';
import { RAccordion, AccordionItemContent } from '../../ui-kit/accordion/accordion.component';
import {
    feedbackLink,
    defaultData,
    parseData
} from './contact-us.util';
import _ from 'lodash';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import { handleBackOnLeftMenus } from '../../utils/common.util';

interface Props {
    history?: any;
}

interface State {
    contactUsData?: { [key: string]: any } | null;
    showLoading?: Boolean;
}

export class XContactUsComponent extends React.Component<Props, State> {

    context: ContextData | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            contactUsData: null,
            showLoading: true
        }
    }

    handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }

    handleContactUsResponse = (response?: any) => {
        let contactUsData = defaultData();

        const data = _.get(response, 'body', null);
        if (data) {
            contactUsData = parseData(data);
        }

        const clonedData = _.cloneDeep(contactUsData);

        let feedback = clonedData.content[clonedData.content.length - 1];
        feedback.content = this.generateFeedbackContent();

        clonedData.content.push(this.contactUsVersion());
        this.setState({
            contactUsData: clonedData,
            showLoading: false
        });
    }

    openInbrowseriOS() {
        this.context?.DEVICE.openInBrowser({ "url": feedbackLink });
    }

    generateFeedbackContent = () => {
        return <a onClick={() => { this.openInbrowseriOS() }} className='privacy-content' href={feedbackLink} target="_blank" rel="noopener noreferrer">
            <i className="lmnicon lmnicon-link-external" style={{ fontSize: '1.3rem', marginRight: 8 }} />
            <span>{__constants.VIEW_LINK}</span>
        </a>
    }

    generateAccordionContent = () => {
        return (
            <div>

                <p className='text-medium'>Version: 17.14.0 4_27_1</p>
                <p className='text-medium'>Date: 10/11/2024 02:33:43</p>
                <p className='text-medium'>Endpoint: https://mobilerelative.citibusinessonline.com/</p>
            </div>
        )
    }

    contactUsVersion = () => {
        const appVersion = {
            header: __constants.CONTACTUS_VERSION,
            content: this.generateAccordionContent()
        }

        return appVersion;
    }

    componentDidMount() {
        //do the api call to fetch data
        this.context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    this.context?.API.COMMON_API.contactUsInfo(this.handleContactUsResponse)
                        .then((response: any) => {
                            //console.log('====>', response)
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                this.handleContactUsResponse(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => this.handleContactUsResponse(error));
                } else {
                    this.handleContactUsResponse();
                }
            },
            (error: any) => {
                this.handleContactUsResponse();
            },
        );
    }


    render() {
        return (
            <>
                <LandingHeader title={__constants.CONTACT_US} showBackBtn onBackClick={this.handleBackButtonClick} />

                <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ width: '100%', maxWidth: '100%' }}>
                    {
                        this.state.showLoading
                            ? <Loading tip={__constants.LOADING} horizontal className='loading-center' />
                            : <>
                                <p className="text-medium">
                                    {
                                        this.state.contactUsData?.forAssistance
                                    }
                                </p>
                                <RAccordion content={
                                    (this.state.contactUsData?.content as Array<AccordionItemContent>)
                                } />
                            </>
                    }
                </div>
            </>
        );
    }
}


const ContactUsComponent = (p: Props) => {

    const context = useContext(ApplicationContext);

    const [contactUsData, setContactUsData] = useState<{ [key: string]: any } | null>(null);

    const [showLoading, setShowLoading] = useState<boolean | undefined>(true);


    useEffect(() => {

        //do the api call to fetch data
        context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    context?.API.COMMON_API.contactUsInfo(handleContactUsResponse)
                        .then((response: any) => {
                            //console.log('====>', response)
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                handleContactUsResponse(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => handleContactUsResponse(error));
                } else {
                    handleContactUsResponse();
                }
            },
            (error: any) => {
                handleContactUsResponse();
            },
        );

        //focus to header on page load
        _.delay(() => {
            const header: HTMLElement | null = document.querySelector('.header-content .header-text');
            if (header) {
                header.setAttribute("tabIndex", "0");
                header.focus();
            }
        }, (400));

    }, []);


    const handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }

    const handleContactUsResponse = (response?: any) => {
        let contactUsData = defaultData();

        const data = _.get(response, 'body', null);
        if (data) {
            contactUsData = parseData(data);
        }

        const clonedData = _.cloneDeep(contactUsData);

        let feedback = clonedData.content[clonedData.content.length - 1];
        feedback.content = generateFeedbackContent();

        clonedData.content.push(contactUsVersion());
        setContactUsData(clonedData);
        setShowLoading(false);

    }

    const openInbrowseriOS = () => {
        context?.DEVICE.openInBrowser({ "url": feedbackLink });
    }

    const generateFeedbackContent = () => {
        return <a onClick={() => { openInbrowseriOS() }} className='privacy-content' href={feedbackLink} target="_blank" rel="noopener noreferrer">
            <i className="lmnicon lmnicon-link-external" style={{ fontSize: '1.3rem', marginRight: 8 }} />
            <span>{__constants.VIEW_LINK}</span>
        </a>
    }

    const generateAccordionContent = () => {
        return (
            <div>

                <p className='text-medium'>Version: 17.14.0 4_27_1</p>
                <p className='text-medium'>Date: 10/11/2024 02:33:43</p>
                <p className='text-medium'>Endpoint: https://mobilerelative.citibusinessonline.com/</p>
            </div>
        )
    }

    const contactUsVersion = () => {
        const appVersion = {
            header: __constants.CONTACTUS_VERSION,
            content: generateAccordionContent()
        }

        return appVersion;
    }

    return (
        <>
            <LandingHeader title={__constants.CONTACT_US} showBackBtn onBackClick={handleBackButtonClick} />

            <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ width: '100%', maxWidth: '100%' }}>
                {
                    showLoading
                        ? <Loading tip={__constants.LOADING} horizontal className='loading-center' />
                        : <>
                            <p className="text-medium">
                                {
                                    contactUsData?.forAssistance
                                }
                            </p>
                            <RAccordion content={
                                (contactUsData?.content as Array<AccordionItemContent>)
                            } />
                        </>
                }
            </div>
        </>
    );

}


export default ContactUsComponent;


XContactUsComponent.contextType = ApplicationContext;