import _ from 'lodash';
import React, { Suspense } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import IRouteDef from './route-def';
import Loading from '../components/Loading/loading-panel';
import { addLogoutTimer } from '../utils/timer.util';
import { analytics, getPageInfo } from '../utils/piwik.util';
import ErrorBoundary from '../components/error-boundary/error-boundary.component';
import Page404 from '../components/404-not-found/404.component';
import { analyticsBranded } from '../utils/piwik.util';
import { getPiwikPageInfo } from '../utils/piwik.util';
import BUILD_UTIL from '../utils/build.util';
import device, { DEVICE_TYPE } from '../device/xdevice';
import ContextData from '../context/context-data.interface';
import ApplicationContext from '../context/application-context';
import ToastUI from '../components/common-ui/toast-ui/toast.component';


export default class RouteConfig extends React.Component<{ routes: IRouteDef[] }, {}>{

    render() {
        return (
            <>
                <Router>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            {
                                this.props.routes.map((routes, i) => {
                                    return <ChildRouter key={i} {...routes} />;
                                })
                            }
                        </Switch>
                    </Suspense>
                </Router>
                <ToastUI />
            </>
        );
    }
}


export class ChildRouter extends React.Component<IRouteDef>{

    context: ContextData | undefined

    constructor(props: IRouteDef) {
        super(props);
    }

    componentDidMount() {

        console.log(this.props.path, 'w.a', window.Android);
        //{execute:f}

        const pageInfo = getPageInfo(this.props.path)
        if (pageInfo) {
            analytics(pageInfo.page, pageInfo.channel);
        }
        const piwikPageInfo = getPiwikPageInfo(this.props.path)
        if (piwikPageInfo) {
            if (piwikPageInfo.analytics) {
                // console.log("componentDidMount() --> PAGE_URL is = " + piwikPageInfo.path);
                analyticsBranded(3, piwikPageInfo.analytics.eventCategory, window.location.href, false);
            }
        }

        if (window.DEVICE_INFO_BAK) {
            const os = window.DEVICE_INFO_BAK.mobileOs;
            if (os === 'Android' || os === 'android') {
                window.THE_DEVICE_TYPE = DEVICE_TYPE.ANDROID
            }
            else if (os === 'iOS' || os === 'IOS' || os === 'ios') {
                window.THE_DEVICE_TYPE = DEVICE_TYPE.IOS
            }
        }

        if (window.BioCatchApi) {
            window.BioCatchApi.changeContext(this.props.path);
        }
        device.changeBioCatchContext(this.props.path);

        if (this.context?.Router.__requireSetup()) {
            if (this.props.history) this.context.Router.__setHistory(this.props.history)
        }
    }

    render() {
        if (this.props.secure && BUILD_UTIL.isProduction()) {
            console.log('adding logout timer from child router...', this.props)
            window.LOGOUT_TIMER_REACT = addLogoutTimer(ChildRouter.name);
        }

        if (this.props.mobileOnly) {
            if (!device.isMobile()) {
                return <Page404 {...this.props} />
            }
        }


        return (
            <ErrorBoundary fallback={<Page404 />}>
                <Route
                    exact={this.props.path === '/'}
                    path={this.props.path}
                    render={props => {
                        // pass the sub-routes down to keep nesting
                        // if (this.props.mobileApp) {

                        //     if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
                        //         return <this.props.component {...props} routes={this.props.routes} />
                        //     } else {
                        //         return <Page404 {...props} />
                        //     }

                        // } else {
                        return <this.props.component {...props} routes={this.props.routes} />
                        //}
                    }
                    }
                />
            </ErrorBoundary>
        );
    }
}

ChildRouter.contextType = ApplicationContext;
