import { Icon } from "@citi-icg-172888/icgds-react";
import React from "react";
import { AccordionItemContent } from "./accordion.component";

interface Props {
    accordionItem: AccordionItemContent;
    isActive: boolean;
    index: number;
    contentClass?: string;
    onClick?: any;
}

interface State { }

export default class AccordionItem extends React.Component<Props, State> {
    /* ADA - focus on the sub menu items */

    componentDidMount() {
        const ele: HTMLElement | null = document.getElementById("accordionItem-0");

        const divNodes = ele?.getElementsByTagName("div");

        if (divNodes && divNodes.length > 0) {
            for (let i = 0; i < divNodes.length; i++) {
                const childEle: string | null = (divNodes[
                    i
                ].className += ` accordion-submenu-item${i}`);

                if (childEle) {
                    const subEle: HTMLElement | null = document.querySelector(
                        `.accordion-submenu-item${i}`
                    );

                    //subEle?.setAttribute("tabIndex", "0");
                }
            }
        }
    }


    _handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        const { index, onClick } = this.props;

        if (e.key === "Enter") {
            onClick(index);
        }
    };

    _renderHeader = () => {
        const { isActive, index, onClick } = this.props;
        const { header, htmlHeader } = this.props.accordionItem;

        let ariaLabelHeader: any;

        /* ADA | Check if header is an element and set aria-label */

        if (React.isValidElement(header)) {
            ariaLabelHeader = header.props.children[0];
        } else {
            ariaLabelHeader = header;
        }

        if (htmlHeader) {
            ariaLabelHeader = htmlHeader.replace(/[$&#174;%]/g, "");

            return (
                <div
                    className={`accordion-item-header-container`}
                    tabIndex={0}
                    id={`accordion-item-header-container-${index}`}
                    role="button"
                    aria-label={`${isActive
                        ? ariaLabelHeader + ", Expanded"
                        : ariaLabelHeader + ", Collapsed"
                        }`}
                    onClick={() => {
                        const accordionEle: HTMLElement | null = document.getElementById(`accordion-item-header-container-${index}`);
                        accordionEle?.focus();
                        onClick(index);
                    }}
                >
                    <div
                        className={`accordion-item-header ${isActive ? "active" : ""}`}
                        aria-hidden="true"
                        dangerouslySetInnerHTML={{ __html: htmlHeader }}
                    />

                    <Icon className="expand-collapse-icon" aria-hidden="true" type={`${isActive ? "up" : "down"}`} />
                </div>
            );
        }

        return (
            <div
                className={`accordion-item-header-container`}
                id={`accordion-item-header-container-${index}`}
                tabIndex={0}
                role="button"
                aria-label={`${isActive
                    ? ariaLabelHeader + ", Expanded, Double tap to collapse"
                    : ariaLabelHeader + ", Collapsed, Double tap to expand"
                    }`}
                onClick={() => {
                    const accordionEle: HTMLElement | null = document.getElementById(`accordion-item-header-container-${index}`);
                    accordionEle?.focus();
                    onClick(index);
                }}
            >
                <div aria-hidden="true" className={`accordion-item-header ${isActive ? "active" : ""}`}>
                    {header}
                </div>
                <Icon className="expand-collapse-icon" aria-hidden="true" type={`${isActive ? "up" : "down"}`} />
            </div>
        );
    };

    _renderContent = () => {
        const { isActive, index } = this.props;
        const { content, htmlContent } = this.props.accordionItem;

        if (htmlContent) {
            return (
                <div
                    id={`accordionItem-${index}`}
                    data-item="accordion-item-content"
                    className={`accordion-item-content ${isActive ? "active" : ""}`}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            );
        }

        return (
            <div
                id={`accordionItem-${index}`}
                data-item="accordion-item-content"
                className={`accordion-item-content ${isActive ? "active" : ""}`}
            >
                {content}
            </div>
        );
    };

    render() {
        return (
            <>
                <div
                    id={(window.location.hash + '-accordion-item-' + this.props.index).replace(/#|\//g, '').toLocaleLowerCase()}
                    tabIndex={0}
                    className={`accordion-item ${this.props.contentClass}`}
                    key={this.props.index}
                    onKeyDown={this._handleKeyDown}
                >
                    <dl>
                        <dt>{this._renderHeader()}</dt>

                        <dd>{this._renderContent()}</dd>
                    </dl>
                </div>
            </>
        );
    }
}
