import _ from 'lodash';
import { APP_LOCATIONS, ROUTES } from "../routes/routes";
import { compareVersion, removeChildForBottomNav } from './common.util';
import device, { DEVICE_TYPE } from '../device/xdevice'
import { logOut } from './common.util';
import toastProvider from '../context/toast-provider.context';

export function clearTimer(intervalId?: NodeJS.Timeout) {
    if (!intervalId) {
        return;
    }

    clearTimeout(intervalId);
}

function addTimer(callback: Function, interval?: number, contd?: boolean): NodeJS.Timeout | undefined {


    if (!_.isFunction(callback)) {
        return;
    }
    console.log('adding..interval....');
    const ti = interval ? interval : 1000 * 60 * 5;
    window.REACT_LGT_TIMER_START = new Date().getTime() + ti;
    console.log('target interval time....');

    if (window.location.pathname === APP_LOCATIONS.SignOnPage) {
        return;
    }

    window.SHOW_TIMER_POPUP = { show: false };
    return setInterval(() => {
        const now = new Date().getTime();

        // console.log('Time Remaining', (window.REACT_LGT_TIMER_START - now) / 1000)

        if ((window.CONFIG_TRIGGER_KEEP_ALIVE === true)
            && allowPopupForTimer()
            && (window.REACT_LGT_TIMER_START - now) <= (0.5 * 60 * 1000)
        ) {

            // console.log('less than a minute remaining...')

            if (!window.SHOW_TIMER_POPUP || (window.SHOW_TIMER_POPUP && !window.SHOW_TIMER_POPUP.show)) {
                device.showLogoutTimer(window.REACT_LGT_TIMER_START);

                window.SHOW_TIMER_POPUP = { show: true };
                toastProvider.showTimerPopUp();
            }
        }

        if ((now - window.REACT_LGT_TIMER_START) >= 0 && (window.location.pathname !== APP_LOCATIONS.SignOnPage)) {
            console.log('clear interval...');
            toastProvider.showTimerPopUp(false);

            clearTimer(window.LOGOUT_TIMER_REACT);
            callback();
        }
        if (stopTimerConstraint(window.location.pathname)) {
            clearTimer(window.LOGOUT_TIMER_REACT);
        }
    }, 1000);
}

function allowPopupForTimer(): boolean {

    const hash = (window.location.hash as string).slice(1);

    const route = ROUTES.find(s => s.path === hash)

    if (route) {
        return route?.secure || false;
    }

    return false;
}

function stopTimerConstraint(path?: string) {
    return (path === APP_LOCATIONS.SignOnPage || path === APP_LOCATIONS.MfaHome)
}



export function addLogoutTimer(from?: string): NodeJS.Timeout | undefined {
    if (from || from !== '') {
        console.log('addLogout Timer called from', from);
    }

    clearTimer(window.LOGOUT_TIMER_REACT);

    if (window.location.pathname === APP_LOCATIONS.SignOnPage) {
        return;
    }


    const postLogoutPage = APP_LOCATIONS.HomePage;

    const logoutHash = `#${postLogoutPage}`;
    if (window.location.hash === logoutHash) {
        return;
    }

    console.log("addLogoutTimer......");
    return addTimer(() => {
        console.log("addTimer logging out.....");
        clearTimer(window.LOGOUT_TIMER_REACT);
        clearTimer(window.deviceCheckTimer);
        window.location.assign(logoutHash);
        removeChildForBottomNav();
        logOut();
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                // device.changeBrowserURL("mfa_logout",1);
                console.log("5 addTimer logout");
                if (compareVersion('16')) {
                    return;
                }
            } else {
                if (compareVersion('16')) {
                    return;
                }

                device.changeBrowserURL("", 1);
            }
        }
    });
}