const kSYSTEM_ADMINISTRATION = {
    USER_SELECTION: 'User Selection',
    SELECT_USER: 'Select User',
    SORT_BY_USER_NAME: 'Sort by User Name',
    SORT_BY_USER_ID: 'Sort by User Id',
    STATUS: 'Status',
    USER_NAME: 'User Name',
    USER_ID: 'User Id',
    OK: 'OK',

    ISSUE_NEW_PASSWORD: {
        TITLE: 'Issue New Password',
        PASSWORD_INSTRUCTIONS: 'Enter your password and click submit to generate a temporary password for the selected user',
        ENTER_PASSWORD: 'Enter Password',
        PASSWORD_CONFIRMATION: 'is about to be issued a new password',
        TEMPORARY_PASSWORD_SENT_TO: 'Temporary Password Sent To',
        OK: 'OK',
    },
    APPROVALS: {
        TITLE: 'System Admin Approvals',
        TRANSACTION_TYPE: {
            CHANGE_USER_ENTITLEMENTS: 'CHANGE_USER_ENTITLEMENTS',
            SA_CHANGE_USER_INFORMATION: 'SA_CHANGE_USER_INFORMATION',
            SA_UPDATE_USER_AND_ENTITLEMENTS: 'SA_UPDATE_USER_AND_ENTITLEMENTS',
            COPY_ENT: 'COPY_ENT',
            BULK_COPY_ENT: 'BULK_COPY_ENT',
            SA_COPY_USER_PROFILE: 'SA_COPY_USER_PROFILE',
            NEW_USER: 'NEW_USER',
            SA_ADD_A_NEW_ADMIN_USER: 'SA_ADD_A_NEW_ADMIN_USER',
            SA_DELETE_WORK_FLOW: 'SA_DELETE_WORK_FLOW',
            SA_CREATE_WORK_FLOW: 'SA_CREATE_WORK_FLOW',
            SA_MODIFY_WORK_FLOW: 'SA_MODIFY_WORK_FLOW',
            SA_UNLOCK_TOKEN: 'SA_UNLOCK_TOKEN'
        }
    },

    SUSPEND_REACTIVATE_USER: {
        TITLE: 'Suspend or Reactivate a User',
        SUSPENSION_AND_REACTIVATION_DATE: 'Suspension and Reactivation Date',
        SUSPENSION_DATE: 'Suspension Date',
        REACTIVATION_DATE: 'Reactivation Date',
        INSTRUCTION_SELECT_USER: 'Select the user whose system access you want to change.',
        INSTRUCTION_DATE: 'Suspended users must be reactivated within one year of the date of suspension.',
        DATE_FORMAT: "mm/dd/yyyy",

        ERRORS: {
            SUSPENSION_DATE_SHOULD_BEFORE_REACTIVATION_DATE: 'Suspension date must be a day before Reactivation date.',
            REACTIVATION_DATE_IS_WITHIN_ONE_YEAR_OF_SUSPENSION_DATE: 'Please enter a reactivation date that is within one year of the suspension date.',
            SUSPENSION_DATE_REACTIVATION_DATE_CANNOT_SAME: 'The Suspension date and Reactivation date cannot be same.',
            SUSPENSION_DATE_90_DAYS_IN_ADVANCE_OF_CURRENT_DATE: 'Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.',
        }
    }
};

export default kSYSTEM_ADMINISTRATION;
