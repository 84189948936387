import { Icon, Loading } from "@citi-icg-172888/icgds-react";
import _ from "lodash";
import __constants from '../../common/constants';

export const renderLoader = (msg: string = 'Loading..', marginTop: number = 50) => {
    return (
        <>
            <div className="react-container top-40 auto-overflow" style={{ padding: '20px' }}>
                <div className="tkn-loader" style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: marginTop + '%' }}>
                    <Loading key="lding-tkn" /> {msg}
                </div>
            </div>
        </>
    );
}

export const renderError = (error: string = ' Cannot Fetch Data', showRetry: boolean = false, onRetry: Function = () => { }) => {
    return (
        <>
            <div className="react-container top-40 auto-overflow generic-error" style={{ padding: '0px' }}>
                <div className="tkn-loader" style={{ display: 'flex', flex: '1', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div>
                        <div className="lmn-row" style={{ textAlign: 'center', display: 'block', fontSize: '1.9rem', color: '#9f0f0f' }}>
                            <i className="lmnicon lmnicon-alert-triangle"></i>
                        </div>
                        <p className="error" style={{ textAlign: 'center', fontSize: '0.95rem', color: '#9f0f0f' }}>
                            {error}
                        </p>
                        {
                            showRetry ?
                                <p className="error" style={{ textAlign: 'center', fontSize: '0.95rem', color: '#9f0f0f' }}>
                                    <span className="button" role="button" aria-label='Click to retry' onClick={() => { onRetry() }}>
                                        <Icon type='reload' size='large' /> Retry
                                    </span>
                                </p> : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export const renderSessionSuspended = (logOut: Function, isLogin: boolean = false) => {
    return (
        <>
            <div>
                <div className="lmn-modal-mask" role="presentation" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}></div>
                <div tabIndex={-1} className="lmn-modal-wrap lmn-modal-centered" role="dialog" aria-modal="true">
                    <div role="document" className="lmn-modal" style={{ width: '98%', fontSize: '0.85rem', marginTop: '1rem' }}>
                        <div className="lmn-modal-content">
                            <div style={{ display: "flex", alignItems: "center", margin: "1.2rem 0.5rem 0.0rem 0.8rem", fontSize: '1.2rem', fontWeight: 'bold', color: '#0C2C72' }}>
                                <i aria-hidden="true" className="lmnicon lmnicon-alert-triangle alert-icon-error"></i>
                                <span style={{ paddingLeft: '0.5rem' }}>{__constants.TMX_CHALLENGE_SESSION_SUSPENDED}</span>
                            </div>
                            <div className="lmn-modal-body" style={{ fontWeight: 'bold', color: '#252F3D' }}>
                                {
                                    <div style={{ textAlign: 'left' }}>
                                        <p>
                                            {isLogin ? __constants.TMX_SESSION_SUSPENDED_LOGIN_CONTENT : __constants.TMX_SESSION_SUSPENDED_WIRE_CONTENT}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="lmn-modal-footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div>
                                    <button className="lmn-btn lmn-btn-primary css-1k07eg" type="button" style={{ width: "4.8rem", height: "2.4rem" }}
                                        onClick={() => logOut()}
                                    >OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export const loadScript = (scriptPath: string, id: string, callback?: Function) => {
    const scriptHandle = document.getElementById(id);
    if (!scriptHandle) {
        const scriptElement = document.createElement('script');
        scriptElement.src = scriptPath;
        document.body.appendChild(scriptElement);

        scriptElement.onload = () => { if (_.isFunction(callback)) callback(); }
    }

    if (scriptHandle && callback) callback();
}

export const MandatorySignSpan = () => {
    return (
        <span aria-hidden="true" style={{ color: '#B60000' }}>
            {`${__constants.MANDATORY_SIGN} `}
        </span>
    )
}