import _ from 'lodash';
import http from './api.core';
import { RequestMethod } from './api.core';
import { AxiosResponse } from 'axios';
import __constants from '../common/constants';
import kPIWIK from "../common/constants/PIWIK";
import kGENERAL from "../common/constants/GENERAL";
import securityCtx from '../context/security-context';
import { getFingerPrint, getUserAgent1 } from '../utils/api.util';
import { DEVICE_TYPE } from '../device/xdevice';
// import MOCKED_API from './mocks/mocks';
import ENCRYPT from '../utils/crypto.util';

let MOCKED_API: any;

export interface RegisterTokenResponse {
    //{"success":false,"message":"","tokenSerialNo":"tokenSerialNo","userP86":"userP86"}
    success: boolean,
    message: string,
    tokenSerialNo: string,
    userP86: string,
    activationPassword?: string,
    genericException?: boolean,
    errors?: Array<JsonObj>,
    verificationCodeSize: number,
    cdcb: boolean,
    cdcbRebrand: boolean,
    cdcbLogin: boolean,
    cdcbLoginId: string,
    unRegisterNotifSuccess: boolean;
    pushNotifications: boolean;
}
export interface JsonObj {
    name: string,
    value: string
}

const LOCAL_STORE_KEYS = {
    JW: 'jw'
}

export interface KeepAliveResponse {
    keepAlive: boolean,
    emulated?: boolean
}

export class LoginApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        // const file  =
        // const mockPath = isProd ? './mocks/prod.mock.ts':'./mocks/mocks.ts';
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default
        MOCKED_API = m
    }

    registerToken(businessCode: string, userCode: string, password: string, otp1: string, notifDeviceInfo?: { [key: string]: any }, callBack?: Function, akamaiHeader?: string): Promise<AxiosResponse<RegisterTokenResponse>> {
        console.log("registerToken AKAMAI HEADER: ", akamaiHeader)
        let body = {
            "businessCode": `${businessCode}`,
            "username": `${userCode}`,
            "password": `${password}`,
            "tokenCode": `${otp1}`,
            "registerTokenType": "REGISTER",
            "app": true,
            "appVersion": __constants.APP_VERSION,
            "version": __constants.APP_VERSION
        }

        if (notifDeviceInfo) {
            const { deviceId, deviceToken, serialNumber, sequenceNumber } = notifDeviceInfo;
            body = {
                ...body, ...{
                    deviceId,
                    deviceToken,
                    serialNumber,
                    sequenceNumber,
                    uid: securityCtx?.getUserP86Id()
                }
            };
        }

        const headers: any = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiHeader) && akamaiHeader.length > 0) {
            headers["x-acf-sensor-data"] = akamaiHeader;
            headers["user-agent1"] = getUserAgent1();
        }

        return http
            .makeHttpRequest(
                RequestMethod.POST,
                `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}submitRegisterTokenPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
                headers,
                body,
                callBack,
                kPIWIK.ChannelEnum.LOGIN,
                "submitRegisterTokenPage") as Promise<AxiosResponse<RegisterTokenResponse>>;
    }



    keepAlive(callBack?: Function): Promise<AxiosResponse<KeepAliveResponse>> {

        const current_timestamp = (new Date()).getTime()

        // 30 seconds threshold
        const delta = 30 * 1000;

        if (window.KEEP_ALIVE_TRIGGER_TIMESTAMP !== undefined || window.KEEP_ALIVE_TRIGGER_TIMESTAMP !== null) {

            if ((current_timestamp - window.KEEP_ALIVE_TRIGGER_TIMESTAMP) < delta) {

                console.log('Keep alive treshold not hit. Emulating response');

                return new Promise((resolve, reject) => {
                    resolve({
                        config: {},
                        data: { keepAlive: true, emulated: true },
                        headers: {},
                        status: 200,
                        statusText: "OK",
                    })
                })

            }
        }

        console.log('Triggering server keep alive')

        window.KEEP_ALIVE_TRIGGER_TIMESTAMP = current_timestamp;

        return http.makeHttpRequest(
            RequestMethod.GET,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}mfaKeepAlive/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.RCD_KEEP_ALIVE,
            'mfaKeepAlive'
        );
    }

    mobileKeepAlive(callBack?: Function): Promise<AxiosResponse<KeepAliveResponse>> {

        const current_timestamp = (new Date()).getTime()

        // 30 seconds threshold
        const delta = 30 * 1000;

        if (window.KEEP_ALIVE_TRIGGER_TIMESTAMP !== undefined || window.KEEP_ALIVE_TRIGGER_TIMESTAMP !== null) {

            if ((current_timestamp - window.KEEP_ALIVE_TRIGGER_TIMESTAMP) < delta) {

                console.log('Keep alive treshold not hit. Emulating response');

                return new Promise((resolve, reject) => {
                    resolve({
                        config: {},
                        data: { keepAlive: true, emulated: true },
                        headers: {},
                        status: 200,
                        statusText: "OK",
                    })
                })

            }
        }

        console.log('Triggering server keep alive')

        window.KEEP_ALIVE_TRIGGER_TIMESTAMP = current_timestamp;

        return http.makeHttpRequest(
            RequestMethod.GET,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}mobileKeepAlive/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.RCD_KEEP_ALIVE,
            'mfaKeepAlive'
        );
    }

    login(
        businessCode: string, userCode: string, pw: string, otp1: string, callBack?: Function, remember?: boolean,
        akamaiHeader?: string, serialNumber?: string, sequenceNumber?: string, transmitToken?: string, cdcbLoginId?: string, cdcbEncPassword?: string,
        isMfaNotifications?: boolean
    ) {
        console.log("LOGIN API");
        var deviceType = "app";
        var headers: any = {
            "Accept": "application/json, text/javascript",
            "Content-Type": "application/x-www-form-urlencoded"
        };

        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
            deviceType = "browser";
        } else if (window.isAkamaiMobile && _.isString(akamaiHeader) && akamaiHeader.length > 0) {
            headers["x-acf-sensor-data"] = akamaiHeader;
            headers["user-agent1"] = getUserAgent1();
        }



        const ticketId = securityCtx?.getTMXTicketId();
        const tmxSessionId = securityCtx?.getTMXSessionId();
        const formPost = {
            "j_businesscode": `${businessCode}`,
            "j_username": `${userCode}`,
            "j_password": `${pw}`,
            "j_tokencode": `${otp1}`,
            "j_encrypt_pw": ENCRYPT(pw),
            "j_mfa_notifications": (isMfaNotifications === true),
            //"sequenceNumber": null,
            //"csid": null,
            "rememberCodes": remember,
            //"jw": null,
            "fingerPrint": getFingerPrint(),
            "clientType": deviceType,//"browser",
            "jailBroken": false,
            "rooted": false,
            "appVersion": __constants.APP_VERSION,
            "authenticationMode": "mobileToken",
            "unlock": true,
            "deviceRecognitionBinding": false,
            "mobileScreenResolution": window.screen.width + "X" + window.screen.height,
            "mobileOs": (window.DEVICE_INFO && window.DEVICE_INFO.mobileOs) ? window.DEVICE_INFO.mobileOs : "",
            "ticketId": ticketId ? ticketId : "",
            "tmxSessionId": tmxSessionId ? tmxSessionId : "",
            "softTokenSerialNumber": serialNumber,
            "softTokenSequenceNumber": sequenceNumber,
            "transmitToken": transmitToken ? transmitToken : "",
            "transmitUserId": "cbs-" + securityCtx?.getUserP86Id(),
            "transmitLoginId": securityCtx?.getBiometricsInfo()?.boundBiometric,
            cdcbLoginId,
            cdcbEncPassword
        }

        const body = formPost;

        return http.makeHttpRequest(RequestMethod.POST_FORM, '/citibusinessMobileLogin', headers, body, callBack, kPIWIK.ChannelEnum.LOGIN,
            "login", MOCKED_API?.LOGIN?.CITI_BUSINESS_LOGIN
        );

    }

    changeUpdatePassword(postData: any, callBack?: Function, akamaiData?: string) {
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                "x-acf-sensor-data": akamaiData,
                "user-agent1": getUserAgent1()
            }
        }
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementListPage/15/en?_=1650643784891
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}changeUpdatePasswordPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            { oldPassword: postData.oldPassword, newPassword: postData.newPassword, confirmPassword: postData.confirmPassword },
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            mockResponseChangeUpdatePassword) as Promise<AxiosResponse<any>>;
    }

    resetPassword(params: { [key: string]: any }, callBack?: Function, akamaiData?: string) {
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                "x-acf-sensor-data": akamaiData,
                "user-agent1": getUserAgent1()
            }
        }
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}changeUpdatePasswordPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            mockResponseChangeUpdatePassword) as Promise<AxiosResponse<any>>;
    }

    fetchRememberedUserDetails(callBack?: Function) {
        const body = {
            jw: this.getJwTokenValue()
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `/genericPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            body,
            callBack,
            kPIWIK.ChannelEnum.INFO
        );
    }

    citibusinessMobileLogout(callBack?: Function) {
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}citibusinessMobileLogout?_=${Date.now()}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            'citibusinessMobileLogout');
    }

    getJwTokenValue(): string | null {
        return window.localStorage.getItem(LOCAL_STORE_KEYS.JW);
    }

    hasJwToken(): boolean {
        return (window.localStorage.getItem(LOCAL_STORE_KEYS.JW) != null);
    }

    clearJwToken(clear: boolean = false): boolean {
        if (this.hasJwToken() && clear) {
            window.localStorage.removeItem(LOCAL_STORE_KEYS.JW);
            return true;
        }
        return false;
    }

    setJwToken(value: string | null) {
        if (value != null) {
            window.localStorage.setItem(LOCAL_STORE_KEYS.JW, value);
        }
    }

}

const mockResponseChangeUpdatePassword = {
    "success": true,
    "preSignOn": "",
    "genericException": false,
    "errors": [],
    "loading": "Loading",
    "leftMenu": {
        "back": "Back"
    },
    "rightMenu": {
        "includeLocales": true,
        "close": "Sign Off",
        "items": [
            { "icon": "grid", "action": "#contactUsPage", "name": "Contact Us" },
            { "icon": "fa-info-circle", "action": "#requireUserAgreementPage", "name": "CitiBusiness&#174; Online User Agreement" },

            { "icon": "fa-info-circle", "action": "#privacyPage", "name": "Privacy" }
        ]
    },
    "body": {
        "header": "Change Password",
        "ok": "OK",
        "successMessage": "Your password has been changed."
    }
}

const __instance__ = new LoginApi();

export default __instance__;
