import _ from 'lodash';
import __constants from '../common/constants';
import { ROUTES } from '../routes/routes';
import BUILD_UTIL from './build.util';
import securityCtx from '../context/security-context';
import { DEVICE_TYPE } from '../device/xdevice';

const analyticsMap = [
    { route: "/", page: 'signInPage', channel: __constants.ChannelEnum.LOGIN },
    { route: "/sign-on", page: 'signInPage', channel: __constants.ChannelEnum.LOGIN },
    { route: "/register-token", page: 'submitRegisterTokenPage', channel: __constants.ChannelEnum.LOGIN },
    { route: "/forgot-password", page: 'forgotPasswordPage', channel: __constants.ChannelEnum.LOGIN },
    { route: "/online-user-agreement", page: 'acceptUserAgreementPage', channel: __constants.ChannelEnum.LOGIN_PLUS },
    { route: "/change-pin", page: 'changePinPage', channel: __constants.ChannelEnum.MOBILE_TOKEN },
    { route: "/verification-code", page: 'fetchVerificationCode', channel: __constants.ChannelEnum.MOBILE_TOKEN },
    { route: "/contact-us", page: 'contactUsPage', channel: __constants.ChannelEnum.INFO },
    { route: "/faq", page: 'faqsPage', channel: __constants.ChannelEnum.LOGIN_PLUS },
    { route: "/terms-of-use", page: 'disclaimerPage', channel: __constants.ChannelEnum.INFO },
    { route: "/privacy-statement", page: 'privacyPage', channel: __constants.ChannelEnum.INFO },
    { route: "/signInHome", page: 'homePage', channel: __constants.ChannelEnum.LOGIN },
    { route: "/accounts/deposit-accounts", page: 'depositAccountPage', channel: __constants.ChannelEnum.DEPOSIT_ACCOUNTS },
    { route: "/deposit-accounts/checking", page: 'checkingAccountPage', channel: __constants.ChannelEnum.DEPOSIT_ACCOUNTS },
    { route: "/deposit-accounts/imma", page: 'immaAccountPage', channel: __constants.ChannelEnum.DEPOSIT_ACCOUNTS },
    { route: "/deposit-accounts/savings", page: 'savingsAccountPage', channel: __constants.ChannelEnum.DEPOSIT_ACCOUNTS },
    { route: "/deposit-accounts/cd", page: 'cdAccountPage', channel: __constants.ChannelEnum.DEPOSIT_ACCOUNTS },
    { route: "/accounts/credit-accounts", page: 'creditAccountPage', channel: __constants.ChannelEnum.CREDIT_ACCOUNTS },
    { route: "credit-accounts/loan", page: 'loanAccountPage', channel: __constants.ChannelEnum.CREDIT_ACCOUNTS },
    { route: "/action-required", page: 'actionRequiredPage', channel: __constants.ChannelEnum.ACTION_REQUIRED },
    { route: "/issue-new-password", page: 'IssueNewPasswordComponent', channel: __constants.ChannelEnum.SYSTEM_ADMINISTRATION },

    // TODO1 Forgot pin page and register token page are the same, need different path to distinguish them, once C41630-11765 is finished, need to add it

]
const EVENT_ACTION_ARRAY = ["Downloaded", "Selected", "Search", "Viewed", "Closed", "Initiated", "Clicked", "Entered"];

export function getPageInfo(key: string) {
    return analyticsMap.find(item => item.route === key);
}
export function getPiwikPageInfo(key: string) {
    return ROUTES.find(item => item.path === key);
}

export function getShortEventLabel(menuCode: string) {
    const piwikPageInfo = getPiwikPageInfo(menuCode);
    var shortEventLabel = "Page";
    if (piwikPageInfo !== undefined) {
        if (piwikPageInfo.analytics) {
            if (piwikPageInfo.analytics.eventCategory) {
                shortEventLabel = piwikPageInfo.analytics.eventCategory;
            }
        }
    } else {
        shortEventLabel = camelCasedToFormattedSpacedString(menuCode);
    }
    return shortEventLabel;
}
export function camelCasedToFormattedSpacedString(route: string) {

    // adding space between strings
    const result = route.replace(/([A-Z])/g, ' $1');

    // converting first character to uppercase and join it to the final string
    var formattedString = result.charAt(0).toUpperCase() + result.slice(1);

    if (!formattedString.endsWith("Page")) {
        formattedString += " Page";
    }

    console.log('The formatted string is -->' + formattedString);
    return formattedString;
}

export function analyticsBranded(eventActionArrayIndex: number, shortEventLabel: string, pageUrl: string = "", pageTrack: boolean = false, force: boolean = false) {
    /* try {

        if (!window.isAnalyticsPiwik && BUILD_UTIL.isDev() && BUILD_UTIL.environment() !== 'prod') {
            console.log(`PIWIK: ${EVENT_ACTION_ARRAY[eventActionArrayIndex]} ${shortEventLabel} on ${window.location.hash.split('#')[1]}`);
        }

        if (_.isFunction(window.GaTracker) && window.isAnalyticsPiwik) {//This is the hard toggle boolean coming over from the server
            if (!window.isPiwikInitialized) {//a global variable
                checkAndInitializePiwikIfNeeded();//Initializing piwikTracker, which is a global variable
            }
            let oSVersion = 'web';
            if (window.DEVICE_INFO !== undefined && window.DEVICE_INFO.oSVersion !== undefined) {
                oSVersion = window.DEVICE_INFO.oSVersion;
            }
            var eventAction = EVENT_ACTION_ARRAY[6];//default to clicked
            if (eventActionArrayIndex >= 0 && eventActionArrayIndex < EVENT_ACTION_ARRAY.length) {
                eventAction = EVENT_ACTION_ARRAY[eventActionArrayIndex];
            }

            if (pageUrl.length === 0) {
                pageUrl = window.location.hash
            }

            var brokenUrlArray = pageUrl.indexOf('#') > -1 ? pageUrl.split("#")[1] : pageUrl
            var menuCode = "/";
            var eventCategory = "Page";//default one
            if (brokenUrlArray.length > 1) {
                menuCode = brokenUrlArray
                if (force) {
                    eventCategory = shortEventLabel;
                } else {
                    const piwikPageInfo = getPiwikPageInfo(menuCode);
                    if (piwikPageInfo) {
                        if (piwikPageInfo.analytics) {
                            eventCategory = piwikPageInfo.analytics.eventCategory;
                        }
                    }
                }

            }

            var finalEventLabel: string = eventAction + " " + shortEventLabel;
            if (eventAction === "Clicked") {
                finalEventLabel += " from " + eventCategory;
            }


            if (pageTrack) {

                const page = {
                    PAGE_URL: pageUrl,
                    MENU_CODE: menuCode,
                    PAGE_HIERARCHY_PATH: menuCode,
                    TRACKING_SOURCE: "1",
                }
                window.piwikTracker.trackPageView(page);

            } else {

                var piwikEventInfo = {
                    EVENT_ACTION: eventAction,
                    EVENT_CATEGORY: eventCategory,
                    EVENT_LABEL: finalEventLabel,
                    MENU_CODE: menuCode,
                    PAGE_URL: pageUrl,
                    PAGE_HIERARCHY_PATH: menuCode,
                    TRACKING_SOURCE: "1",
                    APP_VERSION: window.DEVICE_INFO.appVersion,
                    OS_VERSION: oSVersion
                }
                window.piwikTracker.trackEvent(piwikEventInfo);

            }

            if (BUILD_UTIL.isDev() || BUILD_UTIL.environment() !== 'prod') {
                console.log(`PIWIK: ${finalEventLabel}`);
            }

        }
    } catch (e) {
        console.log('Error occurred in analyticsBranded call:' + e);
    } */
}
export function analytics(pageName: string, channel: string) {
    /* var hostname = window.location.hostname;
    var thirdLevelDomain = "";
    try {
        var hostname = window.location.hostname;
        thirdLevelDomain = hostname.split(".", 1)[0];
        if (hostname === '') {
            thirdLevelDomain = window.GLOBAL_ROOT.split(".", 1)[0];
            thirdLevelDomain = thirdLevelDomain.split("//")[1];
        }
        //console.log("isAnalyticsPiwik : "+isAnalyticsPiwik +" and the env is --> "+GLOBAL_ENVIRONMENT);
        //console.log("AJW 5-10-2019 typeof AppMeasurement = " + (typeof AppMeasurement));

        if (!_.isFunction(window.GaTracker)) {
            console.log("Something WRONG on PIWIK usage.min.js which is running on PIWIK side. Not executing PIWIK code as it will fail");
            console.log("_.isFunction(window.GaTracker) --> " + _.isFunction(window.GaTracker));
        }
        if (_.isFunction(window.GaTracker) && window.isAnalyticsPiwik) {//This is the hard toggle boolean coming over from the server
            if (!window.isPiwikInitialized) {//a global variable
                checkAndInitializePiwikIfNeeded();//Initializing piwikTracker, which is a global variable
            }
            let oSVersion = 'web';
            if (window.DEVICE_INFO !== undefined && window.DEVICE_INFO.oSVersion !== undefined) {
                oSVersion = window.DEVICE_INFO.oSVersion;
            }
        }
    } catch (e) {
        console.log('Error occurred in Analytics call:' + e);
    }
    if (typeof window.AppMeasurement != 'undefined' && thirdLevelDomain === "mobile") { //if(typeof AppMeasurement != 'undefined'){//
        var app = (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) ? "app" : "browser";
        console.log("app:" + app);
        var s = new window.AppMeasurement();
        s.account = "citicBusiness";
        s.currencyCode = "USD";
        s.trackDownloadLinks = true; s.trackExternalLinks = true; s.trackInlineStats = true;
        s.linkDownloadFileTypes = "zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";
        s.linkInternalFilters = "javascript:,citibusinessonline.com";
        s.linkLeaveQueryString = true; s.linkTrackVars = "None"; s.linkTrackEvents = "None"; //s.pageName=document.title	s.server=location.href

        console.log("AJW 5-10-2019 pageName = " + pageName);
        console.log("AJW 5-10-2019 location = " + window.location);
        console.log("AJW 5-10-2019 channel = " + channel);

        //Starts here...
        s.pageName = pageName + "/" + app;
        s.server = window.location.host; s.channel = channel; s.pageType = "pagetype";
        s.prop1 = app; s.prop2 = "prop2"; s.prop3 = "prop3"; s.prop4 = "prop4"; s.prop5 = "prop5";

        s.campaign = "campaign"; s.state = ""; s.zip = ""; s.events = ""; s.products = ""; s.purchaseID = ""; s.eVar1 = "eVar1"; s.eVar2 = "eVar2"; s.eVar3 = "eVar3"; s.eVar4 = "eVar4"; s.eVar5 = "eVar5";
        s.visitorNamespace = "citicorporate";
        s.trackingServer = "citicorporate.d2.sc.omtrdc.net";
        s.t();
        s.usePlugins = true;
        function s_doPlugins(s: any) {
            if (!s.campaign) {
                s.campaign = s.Util.getQueryParam('utm_campaign');
            }
            s.campaign = s.getValOnce(s.campaign, 's_campaign', 0);
        }
        s.doPlugins = s_doPlugins;
    }
    return; */
}

function checkAndInitializePiwikIfNeeded() {
    if (!_.isFunction(window.GaTracker)) {
        console.log("Something WRONG on PIWIK usage.min.js which is running on PIWIK side. Not executing PIWIK code as it will fail");
        console.log("_.isFunction(window.GaTracker) --> " + _.isFunction(window.GaTracker));
        return;
    }
    if (!window.isPiwikInitialized) {
        var siteId = __constants.piwikBrowserSiteId; //Site id for browser being set
        let userP86Id = securityCtx?.getUserP86Id();
        if ((window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) && window.DEVICE_INFO.registered === true) {
            if (userP86Id === '' || userP86Id === undefined) {
                userP86Id = securityCtx?.getUserP86Id(); //value being set here only if user is on mobile and is registered, If not mobile or registered, then p86 is set onPostSignIn
            }
        }
        if (window.DEVICE_INFO.mobileOs === __constants.ANDROID) {
            siteId = __constants.piwikAndroidSiteId;
        } else if (window.DEVICE_INFO.mobileOs === __constants.IOS) {
            siteId = __constants.piwikIosSiteId;
        }
        var site = {
            ENV: window.GLOBAL_ENVIRONMENT === 'prod' ? "PROD" : "UAT", //GLOBAL_ENVIRONMENT
            USER_ID: userP86Id,
            USER_TYPE: "Client",
            ZONE: "External",
            SITE_ID: siteId,
            SOURCE_TEAM: "CBusOL"
        }
        //piwikTracker is a global variable
        window.piwikTracker = new (window.GaTracker as any)(site, { disableAutoTrigger: true });
        if (userP86Id !== '') { //if user is logged in then same userP86 will be used throughout the session. So storing in a global variable
            window.isPiwikInitialized = true;
        }
    }
}


