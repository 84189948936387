import { Loading } from '@citi-icg-172888/icgds-react';
import LandingHeader from '../header/header.component';

function LoadingPanel() {
    return (
        <>
            <LandingHeader />
            
            <div id='react-content' className='react-container' style={{alignItems: 'center'}}>
                <Loading tip='Loading...' horizontal style={{fontSize: 20, marginTop: '30%'}} />
            </div>
        </>
    );
}

export default LoadingPanel;