import { removeChildForBottomNav } from '../utils/common.util';

export class SecurityContext {

    userP86Id?: string;
    tmxToolkitFriendly?: boolean;
    tmxTicketId?: string;
    tmxSessionId?: string;
    biometricsInfo?: { [key: string]: any } | null;
    showMobileApp?: boolean;
    signedInStatus?: boolean;
    isHome?: boolean;
    mobileTokenUser?: boolean; // check if the user has signed in from Mobile to see if the OTP2 is coming from softToken
    viewTemplateOptions?: Array<object>;
    leftMenus?: Array<{ [key: string]: string }> | null;
    resendEmailOrSMSInfo?: { [key: string]: string } | null;
    resendPhoneCallInfo?: Array<{ [key: string]: string }> | null;
    tmxTicketIdTime: number = 0;
    attemptsNumLeftForGetInfo?: number = 2;
    triggeredGetDeviceInfo?: boolean;
    cdcbLoginId?: string;
    cdcbEncryptSessionUrl?: string;
    isCdcbRebrand?: boolean = false;
    isCdcbRBMobileBanking?: boolean = false;
    tokenValidated: boolean = false;

    setUserP86Id(p86Id: string) {
        console.log('SecurityContext.setUserP86Id');
        this.userP86Id = p86Id;
    }

    getUserP86Id() {
        return this.userP86Id;
    }

    setTMXToolkitFriendly(toolkitFriendly: boolean) {
        console.log('SecurityContext.setTMXToolkitFriendly', toolkitFriendly);
        this.tmxToolkitFriendly = toolkitFriendly;
    }

    isTMXToolkitFriendly(): boolean {
        return this.tmxToolkitFriendly ? this.tmxToolkitFriendly : false;
    }

    setTMXTicketId(id: string) {
        // console.log('SecurityContext.setTMXTicketId', id);
        this.tmxTicketIdTime = (new Date()).getTime()
        this.tmxTicketId = id;
    }

    getTMXTicketId() {
        return this.tmxTicketId;
    }

    getTMXTicketIdTime() {
        return Math.abs(this.tmxTicketIdTime)
    }

    setTMXSessionId(id: string) {
        this.tmxSessionId = id;
    }

    getTMXSessionId() {
        return this.tmxSessionId;
    }

    setBiometricsInfo(biometricsInfo: { [key: string]: any } | null) {
        console.log('SecurityContext.setBiometricsInfo', biometricsInfo);
        this.biometricsInfo = biometricsInfo;
    }

    getBiometricsInfo() {
        return this.biometricsInfo;
    }

    setShowMobileApp(showMobileApp: boolean) {
        console.log('SecurityContext.setShowMobileApp', showMobileApp);
        this.showMobileApp = showMobileApp;
    }

    isShowMobileApp(): boolean {
        return this.showMobileApp ? this.showMobileApp : false;
    }

    setSignedIn(signedIn: boolean): void {
        console.log('SecurityContext.setSignedIn', signedIn);
        this.signedInStatus = signedIn;
        if (!signedIn) {
            window.DEVICE_INFO.pin = '';
            this.userP86Id = '';
        }
    }

    isSignedIn(): boolean {
        return this.signedInStatus ? this.signedInStatus : false;
    }

    setHomePage(isHomePage: boolean) {
        console.log('SecurityContext.setHomePage', isHomePage);
        this.isHome = isHomePage;
        if (!isHomePage) {
            removeChildForBottomNav();
        }
    }

    isHomePage(): boolean {
        return this.isHome ? this.isHome : false;
    }

    /**
     * @param options
     *
     * options returned from /homePage/** request.
     *
     * options is used to determine which request to send to get approval counts .
     */
    setViewTemplateOptions(options: Array<object>) {
        console.log('SecurityContext.setViewTemplateOptions', options);
        this.viewTemplateOptions = options;
    }

    getViewTemplateOptions(): Array<object> {
        return this.viewTemplateOptions ? this.viewTemplateOptions : [];
    }

    /**
     * @param menus
     *
     * example:
     * [
     *  {title: 'Change your password', href: '/userAdministrationChangePasswordPage'},
     *  {title: 'Mobile token management', href: '/mobileTokenManagementSummaryPage'},
     *  {
     *    title: 'System Administration',
     *    href: '/adminFunctionMenuPage',
     *    subMenus: [
     *      {
     *        title: 'Issue New Password',
     *        href: 'listOfUser/issueNewPassword/userId'
     *      },
     *      {
     *        title: 'System Admin Approvals',
     *        href: 'makerCheckerApprovalsPage'
     *      }
     *   ]
     *   }}
     * ]
     */
    setLeftMenus(menus: Array<{ [key: string]: string }> | null) {
        // console.log('SecurityContext.setLeftMenus', menus);
        console.log('SecurityContext.setLeftMenus');
        this.leftMenus = menus;
    }

    getLeftMenus(): Array<{ [key: string]: string }> {
        return this.leftMenus ? this.leftMenus : [];
    }

    /**
     * TMX Challenge - Email OTP / Phone(SMS) OTP
     * Resend verification code attempt is 2 and after that button should be disabled
     *
     * @param info
     *
     * info is used to record email / phone number that has been resend
     *
     * example - email info = {'emailId':'xxxxxx@xxx.com'}
     *           phone number info = {deviceId':'+xxxxxxxxxx1234'}
     */
    setResendEmailOrSMSInfo(info: { [key: string]: string } | null) {
        console.log('SecurityContext.setResendEmailOrSMSInfo');
        this.resendEmailOrSMSInfo = info;
    }

    getResendEmailOrSMSInfo() {
        return this.resendEmailOrSMSInfo;
    }

    /**
     * TMX Challenge - Phone(Call) OTP
     * Resend verification code attempt is 2 and after that button should be disabled
     *
     * @param info
     *
     * info is used to record phone number that has been resend
     *
     * example - info = [ {'deviceId':'+xxxxxxxxxx4208'}, {'deviceId':'+xxxxxxxxxx4208'} ]
     */
    setResendPhoneCallInfo(info: Array<{ [key: string]: string }> | null) {
        console.log('SecurityContext.setResendPhoneCallInfo');
        this.resendPhoneCallInfo = info;
    }

    getResendPhoneCallInfo() {
        return this.resendPhoneCallInfo;
    }

    setMobileTokenUser(mobileTokenUser: boolean) {
        this.mobileTokenUser = mobileTokenUser;
    }

    isMobileTokenUser(): boolean | undefined {
        return this.mobileTokenUser;
    }

    setAttemptsNumLeftForGetInfo(attemptsNum: number) {
        this.attemptsNumLeftForGetInfo = attemptsNum;
    }

    getAttemptsNumLeftForGetInfo(): number | undefined {
        return this.attemptsNumLeftForGetInfo;
    }

    setTriggeredGetDeviceInfo(isTriggered: boolean) {
        this.triggeredGetDeviceInfo = isTriggered;
    }

    isTriggeredGetDeviceInfo(): boolean | undefined {
        return this.triggeredGetDeviceInfo;
    }

    /**
     * CDCB - SiteMinder
     */
    setCdcbLoginId(userId: string) {
        this.cdcbLoginId = userId;
    }

    getCdcbLoginId(): string | undefined {
        return this.cdcbLoginId;
    }

    setCdcbEncryptSessionUrl(url: string) {
        this.cdcbEncryptSessionUrl = url;
    }

    getCdcbEncryptSessionUrl(): string | undefined {
        return this.cdcbEncryptSessionUrl;
    }

    /**
     * Set CDCB Rebrand boolean
     * @returns boolean
     */
    getCDCBRebrand(): boolean | undefined {
        return this.isCdcbRebrand;
    }

    setCDCBRebrand(isCdcbRebrand: boolean) {
        this.isCdcbRebrand = isCdcbRebrand;
    }

    getCDCBRBMobileBanking(): boolean | undefined {
        return this.isCdcbRBMobileBanking;
    }

    setCDCBRBMobileBanking(isCdcbRBMobileBanking: boolean) {
        this.isCdcbRBMobileBanking = isCdcbRBMobileBanking;
    }

    setTokenValidated(tokenValidated: boolean){
        this.tokenValidated = tokenValidated;
    }
     
    getTokenValidated(): boolean{
        return this.tokenValidated;
    }

    clearContext() {
        this.setHomePage(false);
        this.setSignedIn(false);
        this.showMobileApp = false;
        this.leftMenus = null;
        this.resendEmailOrSMSInfo = null;
        this.resendPhoneCallInfo = null;
        this.tokenValidated = false;
        this.isCdcbRBMobileBanking = false;
    }


}

const __instance__ = new SecurityContext();
export default __instance__;