import { lazy } from 'react';

const SignInHome = lazy(() => import(/* webpackChunkName:'signin-home' */'./secure/signin-home/signin-home.component'));
const ActionRequiredPage = lazy(() => import(/* webpackChunkName:'signin-home' */'./action-required/action-required.component'));

const AccountsPage = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/accounts.component'));
const AccountsList = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/accounts-content/accounts-content-list.component'));
const CreditAccountsList = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/accounts-content/credit-accounts-content-list.component'));
const AccountTransactionList = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/accounts-content/account-transaction-list.component'));

const ApprovalsHome = lazy(() => import(/* webpackChunkName:'signin-home' */'./approvals/approvals.component'));
const ApprovalsTransactionTypes = lazy(() => import(/* webpackChunkName:'signin-home' */'./approvals/transactions-awaiting/transaction-types/transaction-types.component'));
const ApprovalsTransactionList = lazy(() => import(/* webpackChunkName:'signin-home' */'./approvals/transactions-awaiting/transaction-list/transaction-list.component'));

const DepositCheque = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/cheque/landing.component'));
const DepositCheckHome = lazy(() => import(/* webpackChunkName:'signin-home' */'./accounts/cheque/home.component'));

const MobileTokenHome = lazy(() => import(/* webpackChunkName:'signin-home' */'./mfa/mobile-tokens/mobile-token-home.component'));
const MobileTokenManagementHome = lazy(() => import(/* webpackChunkName:'signin-home' */'./mfa/token-management/token-management.component'));
const MobileTokenManagementDetail = lazy(() => import(/* webpackChunkName:'signin-home' */'./mfa/token-management/token-management-detail.component'));

const PaymentsLanding = lazy(() => import(/* webpackChunkName:'signin-home' */'./payments-transfers/landing.component'));

const BillPayInitiate = lazy(() => import(/* webpackChunkName:'signin-home' */'./bill-payments/initiate/landing.component'));
const ReviewPastBillPayments = lazy(() => import(/* webpackChunkName:'signin-home' */'./bill-payments/review-past-bill-payments/review-past-bill-payments.component'));

const ITInitiateLanding = lazy(() => import(/* webpackChunkName:'signin-home' */'./internal-transfers/initiate/landing.component'));
const InternalTransferTransactionList = lazy(() => import(/* webpackChunkName:'signin-home' */'./internal-transfers/transactions/landing.component'));

const WiresInitiateLanding = lazy(() => import(/* webpackChunkName:'signin-home' */'./wires/initiate/landing.component'));
const WireTransactionsLanding = lazy(() => import(/* webpackChunkName:'signin-home' */'./wires/transactions/landing.component'));
const InstantPayments = lazy(() => import(/* webpackChunkName:'signin-home' */'./instant-payments/instant-payments.component'));
const SAApprovals = lazy(() => import(/* webpackChunkName:'signin-home' */'./system-admin/approvals/approvals-list.component'));

const MessagesLanding = lazy(() => import(/* webpackChunkName:'signin-home' */'./messages/message-landing.component'));
const MessageList = lazy(() => import(/* webpackChunkName:'signin-home' */'./messages/message-list.component'));

const PositivePay = lazy(() => import(/* webpackChunkName:'signin-home' */'./positive-pay/positive-pay-list.component'));
const PositivePayDetails = lazy(() => import(/* webpackChunkName:'signin-home' */'./positive-pay/positive-pay-details/positive-pay-details.component'));

const EmailCapturePrimaryPage = lazy(() => import(/* webpackChunkName:'signin-home' */'./capture-email-primary/capture-verify-email-primary-component'));
const RequireUserAgreement = lazy(() => import(/* webpackChunkName:'signin-home' */'./require-user-agreement/require-user-agreement.component'));
const RequireTokenRegistration = lazy(() => import(/* webpackChunkName:'signin-home' */'./require-token-registration/require-token-registration.component'));

const LoansBusinessInfo = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/business-info/business-info.component'));
const TypesOfLoans = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/loan-accounts/types-of-loans.component'));
const TLMortgages = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/loan-types/tl-mortgages/tl-mortgages.component'));
const LoCTrades = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/loan-types/loc-trades/loc-trades.component'));
const LoanDetails = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/loan-details/loan-details.component'));
const LoanActivities = lazy(() => import(/* webpackChunkName:'signin-home' */'./loans/loan-activities/loan-activities.component'));

const ForeignExchange = lazy(() => import(/* webpackChunkName:'signin-home' */'./foreign-exchange/fx-landing/fx-landing.component'));

const FXDemoVideo = lazy(() => import(/* webpackChunkName:'signin-home' */'./foreign-exchange/ui-kit/fx-demo-video/fx-demo-video.component'));

export {
    SignInHome,
    ActionRequiredPage,
    AccountsPage,
    AccountsList,
    CreditAccountsList,
    AccountTransactionList,
    ApprovalsHome,
    ApprovalsTransactionTypes,
    ApprovalsTransactionList,
    DepositCheque,
    DepositCheckHome,
    MobileTokenHome,
    MobileTokenManagementHome,
    MobileTokenManagementDetail,
    PaymentsLanding,
    BillPayInitiate,
    ITInitiateLanding,
    ReviewPastBillPayments,
    InternalTransferTransactionList,
    WiresInitiateLanding,
    WireTransactionsLanding,
    InstantPayments,
    SAApprovals,
    MessagesLanding,
    MessageList,
    PositivePay,
    PositivePayDetails,
    EmailCapturePrimaryPage,
    RequireUserAgreement,
    RequireTokenRegistration,
    LoansBusinessInfo,
    TypesOfLoans,
    TLMortgages,
    LoCTrades,
    LoanDetails,
    LoanActivities,
    ForeignExchange,
    FXDemoVideo
};
