export class RouterContext {

    history?: any;
    depositActions?: Array<string>;
    approvals?: any;

    __setHistory(h: any) {
        this.history = h;
    }

    __requireSetup() {
        return (this.history === null || this.history === undefined)
    }


    navigate(path: string, data?: any) {
        if (this.history !== undefined || this.history !== null) {
            this.history?.push({
                pathname: path,
                state: {
                    data: { ...data }
                }
            })
        }
    }

    /**
     * @param depositActions
     *
     * 1.#mobileCheckDepositPromoPage
     * 2.#mobileCheckDepositMenuPage/new/[current time]
     */
    setDepositActions(depositActions: Array<string>) {
        console.log('SecurityContext.setDepositActions', depositActions);
        this.depositActions = depositActions;
    }

    getDepositActions() {
        return this.depositActions;
    }
}


const __instance__ = new RouterContext();

export default __instance__;