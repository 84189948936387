import { isValidElement, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from '@citi-icg-172888/icgds-react';
import Button from '../../../ui-kit/button/button';
import { TMXChallengePhoneOTP, TMXChallengeEmailOTP } from '../../pre-signon.chunk.index';
import __constants from '../../../common/constants';
import ApplicationContext from '../../../context/application-context';
import { DEVICE_TYPE } from '../../../device/xdevice';
import { initializeTMX, updateRegistrationResult } from './biometrics.util';
import './biometrics.style.scss';
import { renderSessionSuspended } from '../../common-ui/common-ui.components';
import { logOut } from '../../../utils/common.util';

interface Props {
    onClose?: Function;
}

function BiometricsUnBindView(props: Props) {
    const context = useContext(ApplicationContext);
    const biometricsInfo = context?.Security.getBiometricsInfo();
    const defaultContent = (biometricsInfo?.supportedBiometricID.includes(__constants.BIOMETRICS_ID.FINGERPRINT))
        ? __constants.BIOMETRICS_UNENROLL.CONFIRM_CONTENT.replace(/Face ID/g, window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ? __constants.BIOMETRICS_ID.TOUCH_ID : __constants.BIOMETRICS_ID.FINGERPRINT)
        : __constants.BIOMETRICS_UNENROLL.CONFIRM_CONTENT;

    const [title, setTitle] = useState<string | null>(null);
    const [content, setContent] = useState(defaultContent);
    const [footer, setFooter] = useState(__constants.CONTINUE);
    const [showSessionSuspended, setShowSessionSuspended] = useState(false);

    const [showModal, setShowModal] = useState(true);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        context?.DEVICE.changeBioCatchContext(__constants.BIOMETRICS_CONTEXT.UNENROLL);

        if (context.Security?.isBiometricsDown()) {
            setContent(__constants.BIOMETRICS_OFFLINE.UNENROLL);
            setFooter(__constants.OK);
        }
    }, []);

    // ADA - disable browsing to outside modal using a screen reader
    useEffect(() => {
        const ele: Element | null = document.querySelector('#cbusol-view');
        if (ele) {
            if (showModal) {
                ele.setAttribute('aria-hidden', 'true');
            } else {
                ele.removeAttribute('aria-hidden');
            }
        }

        return () => {
            if (ele) {
                ele.removeAttribute('aria-hidden');
            }
        }
    }, [showModal]);

    function _onClose() {
        setShowModal(false);

        if (_.isFunction(props.onClose)) {
            props.onClose();
        }
    }

    function _onTMXChallengeVerifyDone() {
        setShowLoading(true);
        setTitle(null);
        setContent(defaultContent);
        setFooter(__constants.CONTINUE);

        _sendUnenollReq();
    }

    function _unenrollBiometryIdFailed(challengeType?: string) {
        setShowLoading(false);

        let content: any = __constants.REQ_FAILED;
        if (challengeType) {
            if (challengeType === __constants.BIOMETRICS_CHALLENGE_TYPE.EMAIL) {
                content = <TMXChallengeEmailOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === __constants.BIOMETRICS_CHALLENGE_TYPE.PHONE) {
                content = <TMXChallengePhoneOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === __constants.TMX_CHALLENGE_SESSION_SUSPENDED) {
                setShowSessionSuspended(true);
            }
        } else {
            updateRegistrationResult(false);
        }

        setContent(content);
        setFooter(__constants.OK);
    }

    function _sendUnenollReq() {

        context?.DEVICE.unenrollBiometrics((resp: any) => {
            if (_.get(resp, 'success', false)) {
                setShowLoading(false);

                const successTitle = biometricsInfo?.supportedBiometricID.includes(__constants.BIOMETRICS_ID.FINGERPRINT)
                    ? __constants.BIOMETRICS_UNENROLL.SUCCESS_TITLE.replace(/Face ID/g, window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ? __constants.BIOMETRICS_ID.TOUCH_ID : __constants.BIOMETRICS_ID.FINGERPRINT)
                    : __constants.BIOMETRICS_UNENROLL.SUCCESS_TITLE;
                setTitle(successTitle);
                setContent(__constants.BIOMETRICS_UNENROLL.SUCCESS_CONTENT);
                setFooter(__constants.OK);

                context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ policy: __constants.BIOMETRICS_POLICY.BIND } });

                updateRegistrationResult(true);
                return;
            }

            _unenrollBiometryIdFailed();
        }, (error: any) => {
            console.log('Transmit - Biometric - unenrollBiometrics - failed ', error);

            _unenrollBiometryIdFailed();
        });
    }

    function _handleFooterBtnClicked() {
        if (footer === __constants.CONTINUE) {
            setShowLoading(true);

            initializeTMX(__constants.BIOMETRICS_EVENT_TYPE.UNENROLL, false, _sendUnenollReq, _unenrollBiometryIdFailed);
            return;
        }

        _onClose();
    }

    function _renderFooter() {
        if (isValidElement(content)) {
            return null
        }

        return <>
            {
                footer === __constants.CONTINUE
                    ? <Button
                        id={`btn-biometric-unbind-${__constants.CANCEL}`}
                        color='secondary'
                        disabled={showLoading}
                        onClick={_onClose}>
                        {__constants.CANCEL}
                    </Button>
                    : null
            }
            <Button
                id={`btn-biometric-unbind-${footer}`}
                color='primary'
                disabled={showLoading}
                showLoading={showLoading}
                onClick={_handleFooterBtnClicked}>
                {footer}
            </Button>
            {showSessionSuspended ? renderSessionSuspended(logOut, false) : null}
        </>
    }

    return (<Modal
        width='90%'
        size='large'
        title={!isValidElement(content) ? title : null}
        visible={showModal}
        closable={false}
        maskClosable={false}
        wrapperClass={`biometrics-modal ${!title ? 'biometrics-null-title' : ''}`}
        footer={_renderFooter()}
    >
        {
            isValidElement(content)
                ? content
                : <span className='biometrics-content'>{content}</span>
        }
    </Modal>);
}

export default BiometricsUnBindView;