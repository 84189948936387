
const CitiRegEx = {
    serverDisplayAmount: function (s: string) { return new RegExp("^([0-9]){14}$").test(s); },
    amount: function (s: string) { return new RegExp("^[0-9]*\\.?[0-9]?[0-9]?$").test(s); },
    businessCode: function (s: string) { return new RegExp("^([0-9]){2,16}$").test(s); },
    userCode: function (s: string) { return new RegExp("^([0-9]){3,6}$").test(s); },
    onlyNumbers: function (s: string) { return new RegExp("^([0-9]){0,36}$").test(s); },
    setCharacterLimitFrom6To10: function (s: string) { return new RegExp("^[\\s|\\S]{6,10}$").test(s); },
    setCharacterLimitFrom8To10: function (s: string) { return new RegExp("^[\\s|\\S]{8,10}$").test(s); },
    atleastOneUpperCaseLetter: function (s: string) { return new RegExp("[A-Z]+").test(s); },
    atleastOneLowerCaseLetter: function (s: string) { return new RegExp("[a-z]+").test(s); },
    atLeast1Number: function (s: string) { return new RegExp("[0-9]+").test(s); },
    cannotContainmorethan2IdenticalCharacters: function (s: string) { return new RegExp("^([a-zA-Z0-9])\\1?(?!\\1)+([a-zA-Z0-9]*)$").test(s) },
    cannotContainSpecialCharactersOrSpaces: function (s: string) { return new RegExp("^([a-zA-Z0-9]+)$").test(s); },
    atleastOneSpecialCharacter: function (s: string) { return new RegExp("[~`!@#$^*()_\\-|/]+").test(s); },
    containOtherSpecialCharacter: function (s: string) { return new RegExp("[^a-zA-Z0-9~`!@#$^*()_\\-|/\\s]+").test(s); },
    containSpecialCharacter: function (s: string) { return new RegExp("^([a-zA-Z0-9\\\\~`!@#$^*()_\\-|/])*$").test(s); },
    containOnlyCharacters: function (s: string) { return new RegExp("^([a-zA-Z0-9\\'@#$*()-/])*$").test(s); },
    containSpaces: function (s: string) { return new RegExp("[\\s]+").test(s); },
    isUSD: function (s: string) { return new RegExp("^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|0)?\\.\\d{1,2}$").test(s); },
    numberOrLetter: function (s: string) { return new RegExp("^[a-zA-Z0-9]$").test(s); },
    date: function (s: string) { return new RegExp("^(0[1-9]|[1-9]|1[012])[- \\/.](0[1-9]|[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$").test(s); },
    tokenCode: function (s: string) { return new RegExp("^[0-9]{1,8}$").test(s); },
    tokenSerial: function (s: string) { return new RegExp("^([0-9]|[-]){8,14}$").test(s); },
    password: function (s: string) { return new RegExp("^[a-zA-Z0-9:;@#$%&()_+., -\\/\\[\\]]{1,20}$", "g").test(s); },
    accountNumber: function (s: string) { return new RegExp("^([a-zA-Z0-9]{6,34}|-1)$").test(s); },
    accountNumber_v2: function (s: string) { return new RegExp("^([a-zA-Z0-9@#$%&*()\\[\\];:',./?]{4,34}|-1)$").test(s); },
    //              this.accountNumber = function(s){return new RegExp("^(\\d{6,12}|-1)$").test(s);};
    triggerException: function (s: string) { return new RegExp("^[a-zA-Z]+$").test(s); },
    memo: function (s: string) { return new RegExp("^[a-zA-Z0-9:; '@#$()_+., \\-]{0,100}$", "g").test(s); },// AJW 3-22-2016 - removing  (space)-\\/\ from regex
    customerReferenceNumber: function (s: string) { return new RegExp("^[a-zA-Z0-9\\-'()*#@\\/]{0,10}$", "g").test(s); },
    additionalReference: function (s: string) { return new RegExp("^[a-zA-Z0-9\\-'()*#@\\/]{0,15}$", "g").test(s); },
    beneficiaryPhoneNumber: function (s: string) { return new RegExp("^[A-z0-9\\-:;\\*@#$%&'()+\\?\\.\\,\\/]{0,20}$", "g").test(s); },
    messageReply: function (s: string) { return new RegExp("^[a-zA-Z0-9:; '@#$%&()_+.,\\r\\n -\\/\\[\\]]{1,540}$", "g").test(s); },
    number: function (s: string) { return new RegExp("^([0-9]){1,999}$").test(s); },
    percent: function (s: string) { return new RegExp("^([0-9]){1,3}$").test(s); },//AJW 9-9-2016    100}$").test(s);};
    changePasswordNumeric: function (s: string) { return new RegExp("^[0-9]{1,10}$").test(s); },
    changePasswordRegEx: function (s: string) { return new RegExp("^[a-zA-Z0-9]{6,10}$").test(s); },
    changePasswordAlpha: function (s: string) { return new RegExp("^[a-zA-Z]{1,10}$").test(s); },
    email: function (s: string) { var atPosition = s.indexOf("@"); var dotPosition = s.lastIndexOf("."); if (atPosition < 1 || dotPosition < atPosition + 2 || dotPosition + 2 >= s.length) { return false; } else { return true; } },
    pin: function (s: string) { return new RegExp("^([0-9]){4,8}$").test(s); },
    alphanumericPin: function (s: string) { return new RegExp("^([a-zA-Z0-9]){6}$").test(s); },

    /* ************  */
    isAlphanumeric: function (s: string) { return new RegExp("^([a-zA-Z0-9])*$").test(s); },
    hasRepeatedCharacters: function (s: string) { return new RegExp(/^.*(.)\1{2}.*$/).test(s); },
    hasDigits: function (s: string) { return new RegExp("^.*\\d.*$").test(s); },
    hasSpecialCharacters: function (s: string) { return s.match(/[^\w]/gi); },
    hasUpperCase: function (s: string) { return new RegExp("^.*[A-Z].*$").test(s); },
    hasLowerCase: function (s: string) { return new RegExp("^.*[a-z].*$").test(s); },
    validateLength: function (s: string) { return new RegExp("^.{6,10}$").test(s); },
    validateAccountName: function (s: string) { return new RegExp("(?:\\b|_)([a-z])", 'g') },
    isNumber: function (s: string) { return new RegExp("^[0-9]*$").test(s) }
};

export default CitiRegEx;