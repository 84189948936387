import './error-banner.style.scss';

interface Props {
    text: string;
    closeCallBack?: Function
}

const WarningBanner = (p: Props) => {





    const onWarningClose = () => {

        if (p.closeCallBack) {
            p.closeCallBack();
        }
    }

    return <p className="top-error-banner">
        <span className="error-text">{p.text}</span>
        <i className="lmnicon lmnicon-close" onClick={onWarningClose}></i>
    </p>

}

export default WarningBanner;