import React, { FormEvent, useContext, useEffect, useState } from "react";

import __constants from '../../../common/constants';
import LandingHeader from "../../header/header.component";
import { ErrorMessages } from "../../shared/messages";


import './login-all.scss';
import ApplicationContext from "../../../context/application-context";
import CitiRegEx from "../../../utils/CitiRegEx.util";
import { APP_LOCATIONS } from "../../../routes/routes";
import { addLogoutTimer } from "../../../utils/timer.util";
import { postSignIn, compareVersion, preLogin } from '../../../utils/common.util';
import _ from '../../../lodash';
import CBLInput from '../../../ui-kit/cbusol-input/CBLInput'
import { DEVICE_TYPE } from "../../../device/xdevice";
import { FCheckBox } from "../../../ui-kit/checkbox/checkbox.component";
import Alert from "../../../ui-kit/alert/alert.component";
import Button from '../../../ui-kit/button/button';
import { maskMiddleText } from "../../../utils/mask.util";
import { WAIT_FOR_IOS_CALLBACK } from "../../../api/api.core";
import BUILD_UTIL from "../../../utils/build.util";
import securityCtx from '../../../context/security-context';
import { analyticsBranded } from "../../../utils/piwik.util";
import ios_app_store_badge from '../../../assets/images/ios_app_store_badge.png';
import android_play_store_badge from '../../../assets/images/android_play_store_badge.png';

/**
 * Component for Login Page (& Redirected from Switch Accounts)
 */

interface Props {
    history?: any,
    location?: any
}


// 4.5 mins to expire
const TMX_TIMEOUT_DELAY = 4.5 * 60 * 1000;
// 45 seconds to expire
const MOCK_TMX_TIMEOUT_DELAY = 45 * 1000;

const LoginAll = (props: Props) => {

    // context: ContextData | undefined;
    const context = useContext(ApplicationContext);

    let deviceDetector: any = undefined;

    // let hideAll: NodeJS.Timeout = setTimeout(() => { }, 0);

    // businessCode: string,
    const [businessCode, setBusinessCode] = useState<string>('');
    // userId: string,
    const [userId, setUserId] = useState<string>('');
    // rememberCodes: boolean,
    const [rememberCodes, setRememberCodes] = useState<boolean>(false);
    // password: string,
    const [password, setPassword] = useState<string>('');
    // otp1: string,
    const [otp1, setOtp1] = useState<string>('');

    //validation specific variables

    // errorMessage?: string,
    const [errorMessage, seterrorMessage] = useState<string | undefined>('');
    // warningMessage?: string,
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    // containsHyperlink: boolean,
    const [containsHyperlink, setContainsHyperlink] = useState<boolean>(false);

    //input field errors
    // businessCodeError ?: string,
    const [businessCodeError, setBusinessCodeError] = useState<string | undefined>();
    // userIdError ?: string,
    const [userIdError, setUserIdError] = useState<string | undefined>();

    // passwordError ?: string,
    const [passwordError, setPasswordError] = useState<string | undefined>();

    // isLoading ?: boolean
    const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

    // businessCodeUnmasked: string,
    const [businessCodeUnmasked, setBusinessCodeUnmasked] = useState<string>('');

    // userIdUnmasked: string,
    const [userIdUnmasked, setUserIdUnmasked] = useState<string>('');

    // jwtAvailable ?: boolean,
    const [jwtAvailable, setJwtAvailable] = useState<boolean | undefined>(context?.API.LOGIN_SERVICE.hasJwToken());

    // showCancelBtn ?: boolean,
    const [showCancelBtn, setShowCancelBtn] = useState<boolean | undefined>();

    // isSwitchAccounts ?: boolean
    const [isSwitchAccounts, setIsSwitchAccounts] = useState<boolean | undefined>();

    // tmxFetchComplete: boolean
    const [tmxFetchComplete, setTmxFetchComplete] = useState<boolean>(true);

    //on component mount
    useEffect(() => {
        // dom actions
        //only goto native page after event is called, otherwisw C41630-34176 issue will persist
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible" && window.isLogoutFlag) {
                console.log("visibilitychange event called...")
                window.isLogoutFlag = false
                context?.DEVICE.goToNativePage("mfa_logout");
            }
        });

        context?.Router?.__setHistory(props.history);
        if (!context?.Security.isTriggeredGetDeviceInfo()) {
            context?.Router.navigate(APP_LOCATIONS.HomePage);
            return;
        }

        context?.Security?.setSignedIn(false);
        context?.Security?.setHomePage(false);
        setJwtAvailable(context?.API.LOGIN_SERVICE.hasJwToken())

        deviceDetector = setInterval(deviceTypeTimerCallBack, 2000);

        const loc_data = props?.history?.location?.data;
        if (loc_data) {
            console.log('setting switch accounts state to', (loc_data.action === 'switch-accounts'));
            setIsSwitchAccounts((loc_data.action === 'switch-accounts'));
            window.isSwitchAccounts = (loc_data.action === 'switch-accounts')
        }

        if (context?.API.LOGIN_SERVICE.hasJwToken() && props.location.pathname === APP_LOCATIONS.SignOnPage)
            context?.API.LOGIN_SERVICE.fetchRememberedUserDetails(successFetchRememberedUserDetails).then(
                response => {
                    if (response.status !== WAIT_FOR_IOS_CALLBACK)
                        successFetchRememberedUserDetails(response.data)
                    else
                        console.log('waiting for ios call', response)
                }
            )
                .catch((error) => { console.log(error) });

        window.LOGOUT_TIMER_REACT = addLogoutTimer(props.location?.pathname);

        return () => {
            window.tmxUICallback = undefined
        }

    }, []);

    const deviceTypeTimerCallBack = () => {
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            setShowCancelBtn(true);
            clearInterval(deviceDetector);
        }
    }


    const successFetchRememberedUserDetails = (response: any) => {
        if (response.success) {
            const bCode = (response.businessCode as string).slice(1).replace(/^0+/, '');
            setBusinessCode(bCode);
            setBusinessCodeUnmasked(bCode);
            setUserId(response.userCode);
            setUserIdUnmasked(response.userCode);
            setIsSwitchAccounts(true);
            setRememberCodes((context?.API.LOGIN_SERVICE.hasJwToken() ? true : false));
        }
    }

    const onBusinessCodeBlur = () => {
        const bCode: string = businessCode;
        const masked = maskMiddleText(bCode);
        setBusinessCodeUnmasked(bCode);
        setBusinessCode(masked);
        setIsSwitchAccounts(true);
    }

    const onBusinessCodeFocus = () => {
        const bCode = businessCodeUnmasked;
        setBusinessCode(bCode);
        setIsSwitchAccounts(true);

        onInputRemoveErrorMessageFocus();
    }

    const onInputRemoveErrorMessageFocus = () => {
        if (errorMessage && errorMessage.length > 0) {
            seterrorMessage(undefined);
        }
    }

    const onInputKeyDown = (e: React.KeyboardEvent<Element>) => {

        if (disabledBtn()) {
            return;
        }

        if (e.key.toLowerCase() !== 'enter') {
            return;
        }

        setIsLoading(true);
        handleMobileBankingSignOn1();
    }

    const inputInfo = (): Object[] => {
        return ([
            {
                onChangeFunc: validateBusinessCode,
                label: __constants.INPUT_ENTER_BUSINESS_CODE,
                val: businessCode,
                error: businessCodeError,
                onBlur: onBusinessCodeBlur,
                onFocus: onBusinessCodeFocus,
                type: 'text',
                show: true,
                businessCode: true,
                disabled: isLoading,
                onlyNumbers: true
            },
            {
                onChangeFunc: validateUserId,
                label: __constants.INPUT_ENTER_USER_ID,
                val: userId,
                error: userIdError,
                onBlur: (e: Event) => {
                    setUserId(maskMiddleText(userIdUnmasked));
                },
                onFocus: (e: Event) => {
                    setUserId(userIdUnmasked);
                },
                type: 'text',
                show: true,
                disabled: isLoading,
                onlyNumbers: true
            },
            {
                onCheckChange: handleRememberCheckChange,
                label: __constants.REMEMBER_CODE_AND_ID,
                val: rememberCodes,
                type: 'checkbox',
                show: (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB),
                checked: rememberCodes,
                disabled: isLoading
            },
            {
                onChangeFunc: (e: Event) => {
                    setPassword((e.target as any).value)
                },
                label: __constants.INPUT_ENTER_PASSWORD,
                val: password,
                type: 'password', //window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? 'text' : 'password',
                onFocus: (e: Event) => { onPasswordFocus() },
                error: passwordError,
                enableForgotPasswordLink: true,
                show: true,
                maxLength: "10",
                disabled: isLoading,
                showHiddenIcon: true,
            },
            {
                onChangeFunc: validateOtp,
                label: __constants.INPUT_ENTER_OTP1,
                val: otp1,
                onFocus: (e: Event) => {
                    setOtp1('');
                },
                optional: true,
                type: 'password',//window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? 'text' : 'password',
                show: true,
                maxLength: "8",
                disabled: isLoading,
                onlyNumbers: true
            }
        ])
    }

    const onPasswordFocus = () => {
        setPassword('');
        const current = Math.abs((new Date()).getTime())
        const delay = BUILD_UTIL.isProduction() ? TMX_TIMEOUT_DELAY : MOCK_TMX_TIMEOUT_DELAY

        const ticketTime = context?.Security.getTMXTicketIdTime()


        if (ticketTime === 0) {
            preLogin(onTMXPreLoginComplete)
            return;
        }

        let delta = delay
        if (ticketTime && ticketTime > 0) {
            delta = current - ticketTime
            console.log('Delta: 304', delta);
        }
        console.log('delay: ', delay);
        console.log('Ticket time: ', ticketTime);
        console.log('Current time: ', current);
        console.log('Delta: ', delta);

        if (delta >= delay || securityCtx?.getTMXTicketId() === '') {
            console.log('Possible tmx ticket expiration, fetching again')
            setTmxFetchComplete(false);
            preLogin(onTMXPreLoginComplete)
        } else {
            onTMXPreLoginComplete(null, true)
        }

        // else do nothing you are all set to go
    }

    const onTMXPreLoginComplete = (data: any, success: boolean) => {

        setTmxFetchComplete(true);

        if (!success) {
            console.log('Tmx Call failed');
            return;
        }

        console.log('TMX renewal complete.. we are good to go');
    }


    const handleRememberCheckChange = (status: boolean) => {
        setRememberCodes(status);
        if ((!status) && context?.API.LOGIN_SERVICE.hasJwToken()) {
            console.log('clear jw token');
            context.API.LOGIN_SERVICE.clearJwToken(true);
        }
    }

    const renderInputView = (data: { [key: string]: any }) => {
        return (
            <>
                {
                    data.show ?
                        data.type === 'checkbox' ?
                            <FCheckBox label={data.label} onCheckedChange={data.onCheckChange} checked={data.checked} />
                            :
                            <CBLInput
                                label={data.label}
                                value={data.val}
                                key={data.label}
                                type={data.type}
                                placeholder={data.placeholder}
                                onChange={data.onChangeFunc}
                                onBlur={data.onBlur ? data.onBlur : null}
                                onFocus={data.onFocus ? data.onFocus : onInputRemoveErrorMessageFocus}
                                onKeyDown={onInputKeyDown}
                                required={!data.optional}
                                autoFocus={data.autoFocus}
                                isBusinessCode={data.businessCode}
                                disabled={data.disabled ? data.disabled : false}
                                maxLength={data.maxLength ? data.maxLength : null}
                                numericInput={data.onlyNumbers ? data.onlyNumbers : false}
                                showHiddenIcon={data.showHiddenIcon ? data.showHiddenIcon : false}
                            /> : null
                }
                {
                    data.enableForgotPasswordLink ?
                        <div className='link-end'>
                            <Button
                                color="ghost"
                                id="id-frgt-pwd-tip-login"
                                onBlur={() => { }}
                                onClick={handleForgotPassword}>
                                {__constants.FORGOT_PASSWORD}
                            </Button>
                        </div>
                        : null
                }
                {
                    data.error ?
                        <p className="error-message" key={`k${data.label}`}>
                            {data.error}
                        </p> :
                        null
                }
            </>
        );
    }

    const handleForgotPassword = () => {
        document.getElementById('id-frgt-pwd-tip-login')?.focus();

        props.history.push({
            pathname: APP_LOCATIONS.ForgotPasswordPage,
            state: {
                businessCode: businessCodeUnmasked,
                userId: userIdUnmasked
            }
        });
    }

    const handleSubmitButtonCick = (e: Event | FormEvent) => {

        analyticsBranded(6, "Button: Sign In", "")

        if (errorMessage && errorMessage.length > 0) {
            seterrorMessage(undefined);
        }

        e.preventDefault();
        const data = { "isBusinessCodeError": false, "isUserIdError": false };
        data.isBusinessCodeError = (businessCodeUnmasked == null || businessCodeUnmasked === "" || !CitiRegEx.businessCode(businessCodeUnmasked));

        data.isUserIdError = (userIdUnmasked == null || userIdUnmasked === "" || !CitiRegEx.userCode(userIdUnmasked));
        setBusinessCodeError(data.isBusinessCodeError ? 'Invalid Business Code' : undefined);
        setUserIdError(data.isUserIdError ? 'Invalid User ID' : undefined);

        if (data.isBusinessCodeError || data.isUserIdError) {
            return;
        }

        setIsLoading(true);

        handleMobileBankingSignOn1();

    }



    const handleSuccessMobileBankingSignOnResponse = (data: { [key: string]: any }) => {

        if (rememberCodes) {
            context?.API.LOGIN_SERVICE.setJwToken(data.jw);
        }

        context?.Security.setUserP86Id(_.get(data, 'userP86Id', ''));

        if (data.preSignOn === "NEW_USER_CBUSOLM_LOGIN_SECURITY_INFORMATION") {
            console.log("This is coming to NEW_USER_CBUSOLM_LOGIN_SECURITY_INFORMATION");
            handleFailedMobileBankingSignOnResponse(_.get(data, 'errors', null), true);
            return;

        }

        //Added PIWIK Call for successful login
        let device = 'Web';
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            device = window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID ? 'Android' : 'iOS'
        }
        analyticsBranded(3, `Successful Login CBusOL Mobile ${device}`, window.location.href, false, true)
        if (data.demo) {
            window.ROOT = window.GLOBAL_ROOT.includes("localhost") ? "demo/" : "/demo/";
        } else {
            window.ROOT = "";
        }

        securityCtx?.setTMXTicketId('');
        postSignIn(data);
    }

    const successallbackhandleSuccessMobileBankingSignOnResponse = (resp: { [key: string]: any }) => {
        if (_.get(resp, 'success', false)) {
            handleSuccessMobileBankingSignOnResponse(resp);
        } else {
            handleFailedMobileBankingSignOnResponse(_.get(resp, 'errors', null));
        }
        setIsLoading(false);
    }

    const handleMobileBankingSignOn1 = () => {
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            const args = [{ "foo": "bar" }];
            context?.DEVICE.getAkamai((response: any) => {
                const akamaiData = _.get(response, 'akamaiData', "");
                handleMobileBankingSignOn2(akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            handleMobileBankingSignOn2();
        }
    }

    const handleMobileBankingSignOn2 = (akamaiData?: string) => {
        console.log('signon...');
        context?.API.LOGIN_SERVICE.login(
            businessCodeUnmasked,
            userIdUnmasked,
            //window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? passwordUnmasked : 
            password,
            //window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? otp1Unmasked : 
            otp1,
            successallbackhandleSuccessMobileBankingSignOnResponse,
            rememberCodes,
            akamaiData
        )
            .then(resp => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK)
                    successallbackhandleSuccessMobileBankingSignOnResponse(resp.data)
                else
                    console.log('waiting for ios call', resp)
            })
            .catch(error => {
                handleFailedMobileBankingSignOnResponse(error);
            });
    }

    const handleFailedMobileBankingSignOnResponse = (error: Array<{ [key: string]: any }>, showServerError?: boolean) => {
        console.log('Sign on failed..', error);

        preLogin();

        seterrorMessage(_.get(error, '[0].value', ErrorMessages.LOGIN_ERROR));
        setContainsHyperlink(!showServerError);
        setIsLoading(false);
        setPassword('');
        setOtp1('');
    }

    const disabledBtn = () => {
        return (businessCode && userId && password?.length > 0 && tmxFetchComplete) ? false : true;
    };

    const handleCancelButtonClick = () => {

        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS || window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {

            if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {

                setBusinessCode('');
                setBusinessCodeUnmasked('');
                setUserId('');
                setUserIdUnmasked('');
                setPassword('');
                setOtp1('');
                seterrorMessage(undefined);
                setWarningMessage('');

            } else {

                setBusinessCode('');
                setBusinessCodeUnmasked('');
                setUserId('');
                setUserIdUnmasked('');
                setPassword('');
                setOtp1('');

            }
            context?.DEVICE.goToNativePage();
            context?.DEVICE.changeBrowserURL("mfa_logout", 1)
            const args = [{ "foo": "bar" }]
            context?.DEVICE.mobileTokenProvisioned((response: any) => {
                if (!window.DEVICE_INFO_BAK)
                    window.DEVICE_INFO_BAK = { ...response }
                if (response.success) {
                    context?.DEVICE.launchSecondBrowserURL('', 1);
                }
            }, (fail: any) => {
                console.log("common mobileTokenProvisioned FAILED");
            }, args);
        }
        else {
            props.history.push({
                pathname: APP_LOCATIONS.HomePage
            });
        }
    }

    const validateBusinessCode = (e: Event) => {
        const bCode: string = (e.target as any).value;

        if (CitiRegEx.onlyNumbers(bCode)) {
            setBusinessCode(bCode);
            if (jwtAvailable) {
                handleRememberCheckChange(false);
            }
        }
    }

    const validateOtp = (e: Event) => {
        const otp: string = (e.target as any).value;
        if (CitiRegEx.onlyNumbers(otp)) {
            setOtp1(otp);
        }
    }

    const validateUserId = (e: Event) => {
        const user_id: string = (e.target as any).value;
        if (CitiRegEx.onlyNumbers(user_id)) {
            setUserIdUnmasked(user_id);
            setUserId(user_id);
        }

        if (jwtAvailable) {
            handleRememberCheckChange(false);
        }
    }

    const render = () => {
        return (
            <>
                <LandingHeader title={__constants.LOG_IN_TO_BANKING} />

                <div className="react-container top-40 auto-overflow">
                    {
                        (errorMessage) ? <Alert type='error' content={errorMessage} containsHyperlink={containsHyperlink} /> :
                            warningMessage ? <Alert type='warning' content={warningMessage} /> :
                                null
                    }

                    <form autoComplete="off" onSubmit={handleSubmitButtonCick}>
                        {inputInfo().map((val) => {
                            return renderInputView(val);
                        })}

                        <div className="login-action-btn-container">

                            <Button
                                color="primary"
                                disabled={isLoading || disabledBtn()}
                                className='buttonWithMargin'
                                showLoading={isLoading}
                                role="button"
                                aria-label={__constants.SIGN_IN}
                                onMouseEnter={() => {
                                    try {
                                        if (window.BioCatchApi) {
                                            window.BioCatchApi.client.flush();
                                        }

                                        if (compareVersion('17')) {
                                            context?.DEVICE.bioCatchFlush();
                                        }
                                    } catch { }
                                }}
                                onTouchStart={() => {
                                    try {
                                        if (window.BioCatchApi) {
                                            window.BioCatchApi.client.flush();
                                        }

                                        if (compareVersion('17')) {
                                            context?.DEVICE.bioCatchFlush();
                                        }
                                    } catch { }
                                }}
                                onClick={handleSubmitButtonCick}>
                                {
                                    __constants.SIGN_IN
                                }
                            </Button>

                            {
                                showCancelBtn ?
                                    <Button
                                        onClick={handleCancelButtonClick}
                                        color="ghost"
                                        className='buttonWithMargin'>
                                        {__constants.CANCEL}
                                    </Button>
                                    : null
                            }
                        </div>
                    </form>

                    {/* App store badges C41630-41016*/}
                    {
                        window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB
                            ?
                            <div className="badge-container">

                                <a className="android-appBadge" href="https://play.google.com/store/apps/details?id=com.citibusiness.downloadable&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img className="android-appBadge-img" src={android_play_store_badge}
                                        alt="Download on the App Store" />
                                </a>

                                <a className="ios-appBadge" href="https://apps.apple.com/us/app/citibusiness-mobile/id1406516181?itsct=aps_box_badge&itscg=30200">
                                    <img src={ios_app_store_badge}
                                        alt="Get it on Google Play" />
                                </a>

                            </div>

                            : null
                    }

                </div>
            </>
        );
    }

    return render();
}

export default LoginAll;
