import _ from "lodash";
// import MOCKED_API from "../api/mocks/mocks";
import BUILD_UTIL from "../utils/build.util";
import securityCtx from "../context/security-context";
import { compareVersion } from "../utils/common.util";
let MOCKED_API: any;

export const DEVICE_TYPE = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
};

const FUNCTION_NAME = "functionName";
const SUCCESS_CALLBACK = "successCallback";
const ERROR_CALLBACK = "errorCallback";
const DATA_CALLBACK = "data";
const JS2IOS = "webviewurl:";

export class Device {
  enableOtp1: boolean = true;
  enableOtp2: boolean = true;
  enableOtp3: boolean = true;
  androidFlag: boolean = false;

  constructor() {
    const isProd = process.env.NODE_ENV === "production";
    // const file  =
    const m = require(isProd
      ? "../api/mocks/prod.mock.ts"
      : "../api/mocks/mocks.ts").default;
    MOCKED_API = m;
  }
  isAndroid = () => {
    return this.androidFlag;
  };
  isMobile = () => {
    return (
      window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ||
      window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID
    );
  };
  setAndroid = (a: boolean) => {
    this.androidFlag = a;
  };

  startOtpTimer = (callBack: any, time: number = 17) => {
    setTimeout(callBack, time * 1000);
  };

  startOtp3Timer = () => {
    console.log("starting otp3 timer");
    this.enableOtp3 = false;
    this.startOtpTimer(() => {
      this.enableOtp3 = true;
    }, 14);
  };

  triggerDecidePolicyForNavigationAction = (urlString: any) => {
    let newFrameElm: HTMLElement | undefined = document.createElement("IFRAME");
    newFrameElm.setAttribute("src", urlString);
    document.documentElement.appendChild(newFrameElm);
    newFrameElm.parentNode?.removeChild(newFrameElm);
    newFrameElm = undefined;
  };

  js2iosCall = (
    successCallback: any,
    errorCallback: any,
    functionName: any,
    json: any
  ) => {
    console.log("banking js2iosCall ... ", functionName);
    const data = { [FUNCTION_NAME]: functionName, [DATA_CALLBACK]: json };
    window.webkit?.messageHandlers?.cbusolm
      ?.postMessage(data)
      .then(
        successCallback
          ? successCallback
          : (resp: any) => {
            console.log(
              "cbusolm.postMessage succeed - functionName: ",
              functionName
            );
          }
      )
      .catch((resp: any) => {
        let result = resp;
        try {
          result = JSON.parse(resp?.message);
        } catch (error) {
          console.log("cbusolm.postMessage failed - ", error);
        }

        if (errorCallback) {
          errorCallback(result);
          return;
        }
        console.log("cbusolm.postMessage failed - ", resp);
      });

    // iOS - below code can be removed after 15.x and 16.x app are retired .
    const json_new = {
      [FUNCTION_NAME]: functionName,
      [SUCCESS_CALLBACK]: successCallback,
      [ERROR_CALLBACK]: errorCallback,
      [DATA_CALLBACK]: json,
    };
    const newJsonUrl = JS2IOS + JSON.stringify(json_new);
    console.log(
      "New json: " +
      JSON.stringify({
        [FUNCTION_NAME]: functionName,
        [SUCCESS_CALLBACK]: successCallback,
        [ERROR_CALLBACK]: errorCallback,
      })
    );
    this.triggerDecidePolicyForNavigationAction(newJsonUrl);
  };

  attach = (
    successCallback: any,
    errorCallback: any,
    functionName: string,
    androidActionName?: string
  ) => {
    console.log(functionName, "attach:w.a", window.Android);

    if (window.Android) {
      window.Android_BAK = window.Android;
    }

    if (
      (window.Android === undefined || window.Android === null) &&
      window.Android_BAK !== undefined
    ) {
      window.Android = window.Android_BAK;
      window.THE_DEVICE_TYPE = DEVICE_TYPE.ANDROID;
    }

    // if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID && (window.Android === undefined || window.Android === null)) {
    //     const msg = `${MSG_DEVICE_FAILURE}\nError Code: 45\nInfo:${btoa(functionName)}`;

    //     window.ANDROID_DEVICE_FAILURE_CALLBACK({ message: msg });
    // }

    if (
      window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS &&
      !compareVersion("16") &&
      !successCallback &&
      !errorCallback
    ) {
      return;
    }

    if (window.REACT_APPLICATION) {
      /**
       * @author Bharath Chinnam
       * @jira NA
       * Following code is introduced for 17.x apps
       * use hash based callbacks
       * All the 17.x apps will have DEVICE_CONFIG data readily available as soon as JS Interface is initialized with WebView
       * and majorVersion property will be 17
       */
      // if (
      //     window.DEVICE_CONFIG_DATA !== undefined &&
      //     /**
      //      * We want to enable hash based callbacks only on versions higher than 17
      //      */
      //     //Number(window.DEVICE_CONFIG_DATA.majorVersion) >= 17
      //     //(Number(window.DEVICE_CONFIG_DATA.majorVersion) >= 16 && )
      //    ( Number(window.DEVICE_CONFIG_DATA.majorVersion) >= 17 ||
      //     (Number(window.DEVICE_CONFIG_DATA.majorVersion) === 16 && window.DEVICE_CONFIG_DATA.appVersion === '16.1.1'))
      // ) {

      try {
        console.info("Attaching device callbacks version >17");

        /**
         * Sometimes action name of device calls are different on Android and iOS
         * if they are different use the below logic to identify and set the appropriate
         *  action name as the hash key
         */
        const androidAction =
          androidActionName === undefined || androidActionName === ""
            ? functionName
            : androidActionName;

        window.AndroidSuccessCallbackPool[androidAction] = successCallback;

        window.AndroidFailureCallBackPool[androidAction] = errorCallback;
      } catch (e) {
        console.info(e);
      }

      /**
       * return if the 17.x apps callback is complete
       *
       */

      //     return;
      // }

      /**
       * end of hash based callbacks
       */
      /**
       * Use the normal before 17.x apps way of handling native callbacks
       */
      // else {
      /**
       * This code is compatible with 16.X apps on android
       * Remove the following code after all the native apps is migrated to 17.X versions
       */
      try {
        window.ReactAndroidSuccessCallBack = successCallback;

        window.ReactAndroidFailCallBack = errorCallback;
      } catch (e) {
        console.info(e);
      }
      /**
       * End of 16.x callbacks
       */

      // }

      // add callback function to CALLBACK_POOL for iOS
      if (successCallback) {
        window.IOS_DEVICE_SUCCESS_CALLBACK_POOL[functionName] = successCallback;
      }
      if (errorCallback) {
        window.IOS_DEVICE_ERROR_CALLBACK_POOL[functionName] = errorCallback;
      }
    }
  };

  openInBrowser = (json: any) => {
    console.log("calling openInBrowser..." + JSON.stringify(json));
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "openinbrowser", json);
    }
  };

  getInfo = (successCallback: any, errorCallback: any) => {
    console.log("window.Android.getInfo.............::" + new Date());
    this.attach(successCallback, errorCallback, "getInfo", "getDeviceInfo");

    try {
      window.Android.execute(
        "getDeviceInfo",
        JSON.stringify([1, 2]),
        successCallback,
        errorCallback,
        false
      );
    } catch {
      // window.THE_DEVICE_TYPE = DEVICE_TYPE.WEB;
      console.log("getInfo...Android...failed");
    }
    try {
      this.js2iosCall(successCallback, errorCallback, "getInfo", null);
    } catch {
      // window.THE_DEVICE_TYPE = DEVICE_TYPE.WEB;
      console.log("getInfo...iOS...failed");
    }
  };

  getDeviceModelAndName = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.getDeviceModelAndName.............");
    this.attach(successCallback, errorCallback, "getDeviceModelAndName");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getDeviceModelAndName",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "getDeviceModelAndName",
        args[0]
      );
    }
  };

  getTokenSequenceAndSerialNumber = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.getTokenSequenceAndSerialNumber.............");
    this.attach(
      successCallback,
      errorCallback,
      "getTokenSequenceAndSerialNumber"
    );
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getTokenSequenceAndSerialNumber",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "getTokenSequenceAndSerialNumber",
        args[0]
      );
    }
  };

  getLocation = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.getLocation.............");
    this.attach(successCallback, errorCallback, "getLocation");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getLocation",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getLocation", args[0]);
    }
  };

  getOtp1 = (successCallback: any, errorCallback: any, pin: string) => {
    if (this.enableOtp1) {
      console.log("window.Android.getOtp1.....");
      this.enableOtp1 = false;
      console.log("current xdevice instance", this);
      this.attach(successCallback, errorCallback, "getOtp1");
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "getOtp1",
          JSON.stringify([{ pin }]),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(successCallback, errorCallback, "getOtp1", { pin });
      }
      this.startOtpTimer(() => {
        this.enableOtp1 = true;
      });
      return;
    }
    this.startOtpTimer(() => {
      this.enableOtp1 = true;
      this.getOtp1(successCallback, errorCallback, pin);
    });
  };

  changePin = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.changePin....."); // + JSON.stringify(args));
    this.attach(successCallback, errorCallback, "changePin");
    // argscheck.checkArgs('fF', 'Device.getInfo',// args);
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "changePin",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "changePin", args[0]);
    }
  };

  strongPinCheck = (successCallback: any, errorCallback: any, pin: any) => {
    // console.log("window.Android.strongPinCheck....." + pin);// + JSON.stringify(args));
    console.log("window.Android.strongPinCheck.....");
    try {
      this.attach(successCallback, errorCallback, "strongPinCheck");
      var requestArray = [{ pin: pin }];
      console.log("window.THE_DEVICE_TYPE::" + window.THE_DEVICE_TYPE);
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "strongPinCheck",
          JSON.stringify(requestArray),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(
          successCallback,
          errorCallback,
          "strongPinCheck",
          requestArray[0]
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  getOtp2 = (successCallback: any, errorCallback: any, pin: string) => {
    if (this.enableOtp2) {
      this.enableOtp2 = false;
      console.log("window.Android.getOtp2.....");
      this.attach(successCallback, errorCallback, "getOtp2");

      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "getOtp2",
          JSON.stringify([{ pin }]),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(successCallback, errorCallback, "getOtp2", { pin });
      }
      this.startOtpTimer(() => {
        this.enableOtp2 = true;
      });
      return;
    }
    this.startOtpTimer(() => {
      this.enableOtp2 = true;
      this.getOtp2(successCallback, errorCallback, pin);
    });
  };

  getOtp3 = (successCallback: any, errorCallback: any, pin: string) => {
    if (this.enableOtp3) {
      this.enableOtp3 = false;
      console.log("window.Android.getOtp3.....");
      this.attach(successCallback, errorCallback, "getOtp3");

      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "getOtp3",
          JSON.stringify([{ pin }]),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(successCallback, errorCallback, "getOtp3", { pin });
      }
      this.startOtpTimer(() => {
        this.enableOtp3 = true;
      });
      return;
    }
    this.startOtpTimer(() => {
      this.enableOtp3 = true;
      this.getOtp3(successCallback, errorCallback, pin);
    });
  };

  getESig1 = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.getESig1....."); // + JSON.stringify(args));
    this.attach(successCallback, errorCallback, "getESig1");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getESig1",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getESig1", args[0]);
    }
  };

  activatePassword0 = (successCallback: any, errorCallback: any, args: any) => {
    //alert("Step 0 native: device.activatePassword0");
    this.attach(successCallback, errorCallback, "activatePassword0");
    // console.log("window.Android.activatePassword0.....", args);
    console.log("window.Android.activatePassword0.....");
    // argscheck.checkArgs('fF', 'Device.getInfo',// args);
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "activatePassword0",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "activatePassword0",
        args[0]
      );
    }
  };

  activateTokenLicense1 = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    //alert("Step 1 native: device.activateTokenLicense1");
    console.log("window.Android.activateTokenLicense1.....");
    this.attach(successCallback, errorCallback, "activateTokenLicense1");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "activateTokenLicense1",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "activateTokenLicense1",
        args[0]
      );
    }
  };

  activateTokenInstance2 = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    //alert("Step 2&3 native: device.activateTokenInstance2");
    console.log("window.Android.activateTokenInstance2.....");
    this.attach(successCallback, errorCallback, "activateTokenInstance2");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "activateTokenInstance2",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "activateTokenInstance2",
        args[0]
      );
    }
  };

  mobileTokenProvisioned = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.mobileTokenProvisioned...");
    this.attach(successCallback, errorCallback, "mobileTokenProvisioned");
    try {
      console.log("mobileTokenProvisioned...Android...");
      window.Android.execute(
        "mobileTokenProvisioned",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } catch {
      console.log("mobileTokenProvisioned...Android...failed");
    }
    try {
      console.log("mobileTokenProvisioned...iOS...");
      this.js2iosCall(
        successCallback,
        errorCallback,
        "mobileTokenProvisioned",
        args[0]
      );
    } catch {
      console.log("mobileTokenProvisioned...iOS...failed");
    }
  };

  validatePin = (successCallback: any, errorCallback: any, args: any) => {
    if (this.enableOtp3) {
      console.log("window.Android.validatePin...");
      //this.enableOtp3 = false;
      this.attach(successCallback, errorCallback, "validatePin");

      console.log("window.THE_DEVICE_TYPE::" + window.THE_DEVICE_TYPE);
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "validatePin",
          JSON.stringify(args),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(successCallback, errorCallback, "validatePin", args[0]);
      }
      //this.startOtpTimer(() => { this.enableOtp3 = true; });
      return;
    }
    this.startOtpTimer(() => {
      this.enableOtp3 = true;
      this.validatePin(successCallback, errorCallback, args);
    });
  };

  checkDevice = (successCallback: any, errorCallback: any, args?: any) => {
    console.log("window.Android.checkDevice..."); //+ JSON.stringify(args));
    this.attach(successCallback, errorCallback, "checkDevice");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
      var checkDeviceResponseJSON = {
        deviceSerialNumber: "unknown",
        success: true,
        wirelessProtocol: "wifi",
      };
      successCallback(checkDeviceResponseJSON);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "checkDevice",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "checkDevice", args);
    }
  };

  setUserP86Id = (successCallback: any, errorCallback: any, args: any) => {
    console.log("xdevice  window.Android.setUserP86Id..."); //+ JSON.stringify(args));
    this.attach(successCallback, errorCallback, "setUserP86Id");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "setUserP86Id",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "setUserP86Id", args[0]);
    }
  };

  getUserP86Id = (successCallback: any, errorCallback: any, args: any) => {
    try {
      // console.log("window.Android.getUserP86Id...", args);//+ JSON.stringify(args));
      console.log("window.Android.getUserP86Id...");
      this.attach(successCallback, errorCallback, "getUserP86Id");
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
        var theResponseJSON = {};
        successCallback(theResponseJSON);
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "getUserP86Id",
          JSON.stringify(args),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(
          successCallback,
          errorCallback,
          "getUserP86Id",
          args[0]
        );
      }
    } catch (e) {
      window.ReactAndroidFailCallBack({});
    }
  };

  /**
   * @param args { cdcb: boolean, cdcbRebrand: boolean, cdcbLogin: boolean }
   */
  setCDCBFlags = (successCallback: any, errorCallback: any, args: object) => {
    console.log("xdevice  window.Android.setCDCBFlags...");
    this.attach(successCallback, errorCallback, "setCDCBFlags");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "setCDCBFlags",
        JSON.stringify([args]),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "setCDCBFlags", args);
    }
  };

  /**
   * response - {
   *  cdcb: true / false,
   *  cdcbLogin: true / false // new field. return from app version 17.5 and above
   * }
   */
  getCDCBFlag = (successCallback: any, errorCallback: any) => {
    console.log("calling xdevice.getCDCBFlag...");
    this.attach(successCallback, errorCallback, "getCDCBFlag", "getCDCBFlag");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getCDCBFlag", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getCDCBFlag",
        JSON.stringify([{ foo: "bar" }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * response - {
   *  cdcbRebrand: true / false
   * }
   */
  getCDCBRebrandFlag = (successCallback: any, errorCallback: any) => {
    console.log("calling xdevice.getCDCBRebrandFlag...");
    this.attach(successCallback, errorCallback, "getCDCBRebrandFlag", "getCDCBRebrandFlag");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getCDCBRebrandFlag", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getCDCBRebrandFlag",
        JSON.stringify([{ foo: "bar" }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  setOriginatingFunctionality = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.setOriginatingFunctionality..."); //+ JSON.stringify(args));
    this.attach(successCallback, errorCallback, "setOriginatingFunctionality");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "setOriginatingFunctionality",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "setOriginatingFunctionality",
        args[0]
      );
    }
  };

  getOriginatingFunctionality = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.getOriginatingFunctionality..."); //+ JSON.stringify(args));
    this.attach(successCallback, errorCallback, "getOriginatingFunctionality");
    //exec(successCallback, errorCallback, "Device", "getOriginatingFunctionality", args);
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getOriginatingFunctionality",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "getOriginatingFunctionality",
        args[0]
      );
    }
  };

  restartActivateToken = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.restartActivateToken..."); //+ JSON.stringify(args));
    this.attach(successCallback, errorCallback, "restartActivateToken");
    //exec(successCallback, errorCallback, "Device", "restartActivateToken", args);
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "restartActivateToken",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "restartActivateToken",
        args[0]
      );
    }
  };

  getDepositCheck = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.getDepositCheck...");
    this.attach(
      successCallback,
      errorCallback,
      "capturecheckImage",
      "depositCheck"
    );
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "depositCheck",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "capturecheckImage",
        args
      );
    }
  };

  getCaptureCheckImage = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("window.Android.getCaptureCheckImage...");
    this.attach(successCallback, errorCallback, "capturecheckImage");
    if (BUILD_UTIL.isDev()) {
      window.ReactAndroidSuccessCallBack({
        front: MOCKED_API.REMOTE_CHECK_DEPOSITS?.CHECK_IMG_FRONT_2,
        back: MOCKED_API.REMOTE_CHECK_DEPOSITS?.CHECK_IMG_BACK_2,
      });
    } else {
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "captureCheckImage",
          JSON.stringify(args),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(
          successCallback,
          errorCallback,
          "capturecheckImage",
          args
        );
      }
    }
  };

  updateSSL = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.updateSSL...");
    const STR_CALL = "updateSslCert";
    this.attach(successCallback, errorCallback, STR_CALL, STR_CALL);
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        STR_CALL,
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "updateSslCert", args);
    }
  };

  updateAppstore = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.updateAppstore...");
    this.attach(
      successCallback,
      errorCallback,
      "updateappstore",
      "updateAppStore"
    );
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "updateAppStore",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "updateappstore", args);
    }
  };

  sendCheckImgage = (successCallback: any, errorCallback: any, args: any) => {
    console.log("checkImageRetreiver...");
    this.attach(
      successCallback,
      errorCallback,
      "checkImageDialog",
      "checkImageRetreiverPage"
    );
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "checkImageRetreiverPage ",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "checkImageDialog", args);
    }
  };

  showCheckImagePreviewDialog = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("showCheckImagePreviewDialog...");
    this.attach(
      successCallback,
      errorCallback,
      "checkImageDialog",
      "showCheckImagePreviewDialog"
    );
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "showCheckImagePreviewDialog",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      if (_.isArray(args) && args.length > 1) {
        const argsJson = {
          frontCheckImage: args[0],
          backCheckImage: args[1],
          side: "front",
        };
        if (args.length > 2) {
          argsJson.side = args[2] ? "front" : "back";
        }
        this.js2iosCall(
          successCallback,
          errorCallback,
          "checkImageDialog",
          argsJson
        );
      }
    }
  };

  getInfoForDeluxe = (successCallback: any, errorCallback: any, args: any) => {
    console.log("window.Android.getInfoForDeluxe...");
    this.attach(
      successCallback,
      errorCallback,
      "infoForDeluxe",
      "getInfoForDeluxe"
    );
    if (BUILD_UTIL.isDev()) {
      const mocked = {
        version: "11",
        model: "device model",
        manufacturer: "device brand",
        latitude: 36.7792638,
        longitude: -88.0703459,
      };
      window.ReactAndroidSuccessCallBack(mocked);
    }
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getInfoForDeluxe",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "infoForDeluxe", args[0]);
    }
  };

  getAkamai = (successCallback: any, errorCallback: any, args: any) => {
    console.log("xdevice window.Android.getAkamai...");
    this.attach(successCallback, errorCallback, "getAkamai");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getAkamai",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (
      window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS &&
      compareVersion("17")
    ) {
      this.js2iosCall(successCallback, errorCallback, "getAkamai", args);
    } else {
      successCallback();
    }
  };

  updateBioCatchCSID = (args: any) => {
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      console.log("banking - calling startbiocatch..." + JSON.stringify(args));
      this.js2iosCall(null, null, "startbiocatch", args);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "startbiocatch",
        JSON.stringify([args]),
        {},
        {},
        false
      );
    }
  };

  changeBioCatchContext = (context: string) => {
    console.log("banking - calling changeBioCatchContext..." + context);

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "changeBioCatchContext", { context });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "changeBioCatchContext",
        JSON.stringify([{ context: context }]),
        {},
        {},
        false
      );
    }
  };

  bioCatchFlush = () => {
    console.log("banking - calling bioCatchFlush...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "bioCatchFlush", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "bioCatchFlush",
        JSON.stringify([{ foo: "bar" }]),
        {},
        {},
        false
      );
    }
  };

  sendJsonData = (successCallback: any, errorCallback: any, json: any) => {
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "sendjsondata", json);
    }
  };

  sendFormData = (successCallback: any, errorCallback: any, json: any) => {
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "sendformdata", json);
    }
  };

  clearSecureStorage = (
    successCallback: any,
    errorCallback: any,
    args: any
  ) => {
    console.log("calling xdevice.clearSecureStorage...");
    this.attach(successCallback, errorCallback, "clearSecureStorage");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "clearSecureStorage",
        JSON.stringify(args),
        successCallback,
        errorCallback,
        false
      );
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(
        successCallback,
        errorCallback,
        "clearSecureStorage",
        JSON.stringify(args)
      );
    }
  };

  //TODO this is the wrong place for this method
  calculateOTP1DelayTime = function () {
    if (window.DEVICE_INFO.submitPinGeneratedOTP2Timeout !== undefined) {
      var currentTime = new Date().getTime();
      var submitPinGeneratedOTP2Timeout =
        window.DEVICE_INFO.submitPinGeneratedOTP2Timeout.getTime();
      var difference = submitPinGeneratedOTP2Timeout - currentTime;
      if (difference < 0) {
        delete window.DEVICE_INFO.submitPinGeneratedOTP2Timeout;
        return -1;
      } else {
        return difference;
      }
    } else {
      return -1;
    }
  };

  getRedirections = (successCallback: any, errorCallback: any) => {
    try {
      this.attach(successCallback, errorCallback, "getReactRedirects");
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "getReactRedirects",
          JSON.stringify({}),
          successCallback,
          errorCallback,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        this.js2iosCall(
          successCallback,
          errorCallback,
          "getReactRedirects",
          {}
        );
      }
    } catch (e) {
      console.log("getRedirections...failed");
      successCallback({ redirect: "/" });
    }
  };

  changeBrowserURL = (url: string, browser: number = 1) => {
    try {
      window.ReactAndroidSuccessCallBack = () => { };
      window.ReactAndroidFailCallBack = () => { };
      if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
        window.Android.execute(
          "changeWVUrl",
          JSON.stringify([{ url: url, wvid: browser }]),
          window.ReactAndroidSuccessCallBack,
          window.ReactAndroidSuccessCallBack,
          false
        );
      } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
        var json = { url: url, wvid: browser };
        this.js2iosCall(null, null, "changeBrowserURL", json);
      }
    } catch (e) {
      console.log(e);
    }
  };

  launchSecondBrowserURL = (url: string, browser: number = 1) => {
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      var json = { url: url, wvid: browser };
      this.js2iosCall(null, null, "launchSecondBrowserURL", json);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.ReactAndroidSuccessCallBack = () => { };
      window.ReactAndroidFailCallBack = () => { };
      window.Android.execute(
        "loadUrlinBackground",
        JSON.stringify([{ url: url, wvid: browser }]),
        window.ReactAndroidSuccessCallBack,
        window.ReactAndroidSuccessCallBack,
        false
      );
    }
  };

  goToNativePage = (
    redirect?: string,
    pin?: string,
    isNewToken?: boolean,
    errorCallBack: Function = () => { }
  ) => {
    this.attach(
      () => { },
      () => { },
      "goToNativePage"
    );
    try {
      window.Android?.execute(
        "goToNativePage",
        JSON.stringify([
          { redirect: redirect, pin: pin, isNewToken: isNewToken },
        ]),
        window.ReactAndroidSuccessCallBack,
        window.ReactAndroidSuccessCallBack,
        false
      );
    } catch {
      console.log("call goToNativePage - not Android");
    }

    try {
      // if redirect is null, will back to pin / two-button page.
      // if isNewToken, it means user has successfully registered their Mobile Token, then show Rate App.
      this.js2iosCall(null, null, "goToNativePage", {
        redirect,
        pin,
        isNewToken,
      });
    } catch {
      console.log("call goToNativePage - not iOS");
    }
  };

  // implement 'relaunchApp()' on 17.x app
  relaunchApp = () => {
    console.log("calling xdevice.relaunchApp...", window.THE_DEVICE_TYPE);

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "relaunchApp", null);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
    }
  };

  reloadWebView = () => {
    console.log("calling xdevice.reloadWebView...", window.THE_DEVICE_TYPE);

    this.attach(() => { }, () => { }, "reloadWebView");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "reloadWebView", null);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute("reloadWebView", JSON.stringify([{ foo: "bar" }]), () => { }, () => { }, false);
    }
  };

  // Transmit - biometric

  /**
   * response - {
   *      enable: false / true, // boolean. enable/disable biometrics, no need to return other fields when this field is equal to false.
   *      enrolled: false / true,  // boolean. Check if user set up Biometry ID on their device.
   *      supportedBiometricID: [ "Face ID", "Fingerprint" ], // array.
   *      policy: "Bind" / "Login", // string. The policy to be executed.
   *      boundBiometric: "Face ID" / "Fingerprint" // string. Biometric ID used for login.
   *      ...
   *   }
   */
  getBiometricInfo = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.getBiometricInfo...");

    this.attach(successCallback, errorCallback, "getBiometricInfo");
    const userP86Id = securityCtx?.getUserP86Id();
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getBiometricInfo", {
        prefix: "cbs-",
        userP86Id,
      });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getBiometricInfo",
        JSON.stringify([{ userName: "cbs-" + userP86Id }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * response - {
   *   success: true / false,
   *   error: "" // string. error message from Transmit SDK.
   * }
   */
  bindBiometryId = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.bindBiometryId...");

    this.attach(successCallback, errorCallback, "bindBiometryId");
    const userP86Id = securityCtx?.getUserP86Id();
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "bindBiometryId", {
        prefix: "cbs-",
        userP86Id,
      });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "bindBiometryId",
        JSON.stringify([{ userName: "cbs-" + userP86Id }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * response - {
   *    success: true / false
   *    token: "eyJraWQiOiJUUyIsInR5cCI6IkpXVCIsImFsZyI6IkhTMjU2In0 ...",
   *    action: "", // string. action will be "canceled" if user select cancel option. action will be "noRegistered" if local authenticator changed / invalid.
   *    errorCode: "", // the value is same with 'action'. 'action' field has the same name as Android callback hash key, change it to 'errorCode'. will remove 'action' and replace with 'errorCode' on iOS and Web after Biometric VA testing is done (otherwire we have to distrubute new app)
   *    error: "" // string. error message from Transmit SDK.
   * }
   */
  getTransmitToken = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.getTransmitToken...");

    this.attach(successCallback, errorCallback, "getTransmitToken");
    const userP86Id = securityCtx?.getUserP86Id();
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getTransmitToken", {
        prefix: "cbs-",
        userP86Id,
      });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getTransmitToken",
        JSON.stringify([{ userName: "cbs-" + userP86Id }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * response - {
   *   success: true / false, // "success" field always is true on iOS, because there're no handler return from SDK
   *   error: "" // string. error message from Transmit SDK.
   * }
   */
  unenrollBiometrics = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.unenrollBiometrics...");

    this.attach(successCallback, errorCallback, "unenrollBiometrics");
    const userP86Id = securityCtx?.getUserP86Id();
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "unenrollBiometrics", {
        prefix: "cbs-",
        userP86Id,
      });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "unenrollBiometrics",
        JSON.stringify([{ userName: "cbs-" + userP86Id }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * response - {
   *   success: true / false,
   *   error: "" // string. error message from Transmit SDK.
   * }
   */
  logoutBiometrics = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.logoutBiometrics...");

    this.attach(successCallback, errorCallback, "logoutBiometrics");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "logoutBiometrics", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "logoutBiometrics",
        JSON.stringify([{ foo: "bar" }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  // Push Notification

  /**
   * response - {
   *    enable: false / true, // boolean. the toggle of push notification
   *    authorization: "notDetermined" / "denied" / "authorized", // string. "notDetermined" only happened on iOS. "notDetermined" was deprecated since we show OS promt on Moble Token screen.
   * 
   *    enrolled: false / true,  // boolean. Check if user enroll notification on server.
   *    tokenChanged: false / true, // boolean. Compare the newly received device token with enrolled device token, tokenChanged will be true if they don't match
   * 
   *    presentNotif: false / true, // boolean. the app is inactived or user hasn't signed in, user click a received notification, its content will be temporarily stored on client side, and presentNotif will be true (when notification content isn't empty).
   * 
   *    jwtToken: "" // string. Used to fetch the history list from the server
   * }
   */
  getPushNotifInfo = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.getPushNotifInfo...");

    this.attach(successCallback, errorCallback, "getPushNotifInfo");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getPushNotifInfo", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getPushNotifInfo",
        JSON.stringify([{ foo: "bar" }]),
        successCallback,
        errorCallback,
        false
      );
    }
  }

  /**
   * response - {
   *      deviceId: string,
   *      deviceType: string, // Example value: "android, SM-S9010","iPhone14-2". EPFNS validate "android/iphone/ipad" keywords.
   *      deviceToken: string,
   *      ...
   *  }
   */
  getPushNotifDeviceInfo = (successCallback: Function, errorCallback: Function) => {
    console.log("calling xdevice.getPushNotifDeviceInfo...");

    this.attach(successCallback, errorCallback, "getPushNotifDeviceInfo");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getPushNotifDeviceInfo", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "getPushNotifDeviceInfo",
        JSON.stringify([{ foo: "bar" }]),
        successCallback,
        errorCallback,
        false
      );
    }
  };

  /**
   * 
   * @param data {
   *    enable: false / true, // boolean. the toggle of push notification
   *    enrolled: false / true,  // boolean.
   *    enrolledToken: "", // string. 
   *    jwtToken: "" // string.
   * }
   */
  setPushNotifEnroll = (data: { [key: string]: any }) => {
    console.log("calling xdevice.setPushNotifEnroll...");

    this.attach(() => { }, () => { }, "setPushNotifEnroll");
    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "setPushNotifEnroll", data);
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "setPushNotifEnroll",
        JSON.stringify([data]),
        () => { },
        () => { },
        false
      );
    }
  };

  getPushNotifContent = (
    successCallback: Function,
    errorCallback: Function
  ) => {
    console.log("calling xdevice.getPushNotifContent...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(successCallback, errorCallback, "getPushNotifContent", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      // TODO
    }
  };

  /**
   * Clear the Push Notification content matained by the client after opening the notification
   */
  clearPushNotifContent = () => {
    console.log("calling xdevice.clearPushNotifContent...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "clearPushNotifContent", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      // TODO
    }
  };

  /**
   * decrease notification badge on the application icon
   * @param {number} badges number of badges to be decreased. if the badges is 0, will decrease all badges.
   */
  decreasePushNotifBadge = (badges: number = 1) => {
    console.log("calling xdevice.decreasePushNotifBadge...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "decreasePushNotifBadge", { badges });
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      // TODO
    }
  };

  openDeviceSettings = () => {
    console.log("calling xdevice.openDeviceSettings...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "openDeviceSettings", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "openDeviceSettings",
        JSON.stringify({}),
        () => { },
        () => { },
        false
      );
    }
  };


  /**
   * Open Cookie consent Preference screen
   */
  manageCookiePreferences = () => {
    console.log("calling xdevice.changeCookiePreferences...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "changeCookiePreferences", {});
    } else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
      window.Android.execute(
        "changeCookiePreferences",
        JSON.stringify({}),
        {},
        {},
        false
      );
    }
  };


  // load CP page

  /**
   * data: {
   *  reqUrl: string, // ex: "https://uat.commercialbanking.citidirect.com/portal-auth/samlSSO/ssoLogin"
   *  reqParams: {
   *    key1: value1,
   *  },
   *  reqMethod: string, // ex: "POST", "GET"
   *  reqContentType: string, // ex: "application/x-www-form-urlencoded",
   *  parentRouter: string, // ex: "payments-transfers"
   * }
   */
  loadCpPage = (data: object) => {
    console.log("calling xdevice.loadCpPage...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "loadCpPage", data);
    }
  };

  /**
   *  show Logout Timer in CP page
   */
  showLogoutTimer = (time: number) => {
    console.log("calling xdevice.showLogoutTimer...");

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS) {
      this.js2iosCall(null, null, "showLogoutTimer", { time });
    }
  };
}

const device = new Device();
export default device;
