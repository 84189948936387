import { useState, useContext, useEffect } from "react";
import _ from "lodash";
import __constants from "../../common/constants";
import { Tooltip, Icon } from "@citi-icg-172888/icgds-react";
import Button from "../../ui-kit/button/button";
import BiometricsBindView from "../secure/biometrics/biometrics-bind.component";
import BiometricsUnBindView from "../secure/biometrics/biometrics-unbind.component";
import Logo from "../../assets/images/logo_white.svg";
import LeftMenu from "./left-menu";
import MessageView from "./header-message/message-view";
import ShakingBell from "../push-notifications/ui-kit/sharking-bell.component";
import ApplicationContext from "../../context/application-context";
import {
  logOut,
  compareVersion,
  openCookieConsentPreferenceCenter,
} from "../../utils/common.util";
import { DEVICE_TYPE } from "../../device/xdevice";
import ContextData from "../../context/context-data.interface";
import "./header.style.scss";

interface Props {
  title?: string;
  subtitle?: string;
  titleMobile?: string;
  showLogoutBtn?: boolean;
  showGrayBg?: boolean;
  showBackBtn?: boolean;
  onBackClick?: React.MouseEventHandler;
  onLogoutClick?: ((e: Event) => void) | undefined;
  onLeftMenuSelect?: Function;
  context?: ContextData;
  onHover?: Function;
  onHoverOver?: Function;
  hideHeaderSpace?: boolean;

  showNotifIcon?: boolean;
  showNotifSwitch?: boolean;
  notifChecked?: boolean;
  handleNotifChecked?: Function;
}

const LandingHeader = (props: Props) => {
  const context = useContext(ApplicationContext);
  const biometricsInfo = context?.Security.getBiometricsInfo();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const {
    title,
    subtitle,
    showLogoutBtn,
    showGrayBg,
    showBackBtn,
    onBackClick,
    onLogoutClick,
    onHover,
    onHoverOver,
    hideHeaderSpace,

    showNotifIcon,
    showNotifSwitch,
    notifChecked,
    handleNotifChecked
  } = props;

  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showBiometricView, setShowBiometricView] = useState(false);
  const [biometricChecked, setBiometricChecked] = useState(false);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false); // to check if ellipsis is shown in title


  useEffect(() => {
    window.ExpireSession = logOut;
  });

  // ADA - fix Able to browse outside modal with screen reader
  useEffect(() => {
    const ele: Element | null = document.querySelector("#cbusol-view");
    if (ele) {
      if (showLeftMenu) {
        ele.setAttribute("aria-hidden", "true");
      } else {
        ele.removeAttribute("aria-hidden");
      }
    }

    return () => {
      if (ele) {
        ele.removeAttribute("aria-hidden");
      }
    };
  }, [showLeftMenu]);

  const _showLogoutBtn = () => {
    if (showLogoutBtn === undefined) {
      return context?.Security.isSignedIn();
    }

    return showLogoutBtn;
  };

  const _handleLogoutButtonClick = (e: any) => {
    if (_.isFunction(onLogoutClick)) {
      onLogoutClick(e);
      return;
    }

    setShowLoading(true);
    logOut();
  };

  const handleOnHover = (e: any) => {
    if (_.isFunction(onHover)) {
      onHover(e);
    }
  };

  const handleOnHoverOver = (e: any) => {
    if (_.isFunction(onHoverOver)) {
      onHoverOver(e);
    }
  };

  const checkEllipsis = (element: any) => {
    return element.scrollWidth > element.clientWidth;
  };

  /**
   * Handles mouse hover on title element
   * @param e 
   */
  const handleTitleMouseOver = (e: any) => {
    const element = e.target;
    setIsEllipsisActive(checkEllipsis(element));
    setTooltipVisible(true);
  };

  const hasSubtitle = () => {
    return subtitle !== null && subtitle !== undefined && subtitle.length > 0;
  };

  const hasTitle = () => {
    return title !== null && title !== undefined && title.length > 0;
  };

  return (
    <div
      id="react-header"
      className={`header-container ${showGrayBg ? "header-bg" : ""}`}
      style={{ marginLeft: "-5px" }}
    >
      <div className="header-top">
        <div className="header-menu-start-container">
          {showBackBtn ? (
            <i
              tabIndex={0}
              role="button"
              aria-label="Back, button"
              className="lmnicon lmnicon-left header-back-btn"
              onClick={onBackClick}
            />
          ) : null}

          <Icon
            type="menu"
            tabIndex={0}
            aria-label={`Navigation Menu, collapsed, ${window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS
              ? "double-tap to expand"
              : ""
              }`}
            alt="Navigation Menu"
            className="header-menu"
            onClick={() => setShowLeftMenu(true)}
            onKeyDown={(e) => e.key === "Enter" && setShowLeftMenu(true)}
          />
        </div>
        <img
          tabIndex={0}
          aria-label="Citi Group"
          src={Logo}
          height="28px"
          className="header-logo"
        />
        <div className="header-menu-end-container">
          {
            context?.Security.isHomePage()
              ? <MessageView /> :
              null
          }
          {
            (compareVersion("18") && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && window.DEVICE_INFO?.pushNotifEnabled && window.DEVICE_INFO?.autoGenerateSoftToken)
              ? <ShakingBell />
              : null
          }
        </div>
      </div>

      {!hideHeaderSpace
        ? <div
          className={
            hasSubtitle()
              ? "header-content truncate-header-content"
              : "header-content"
          }
        >
          {/* ADA - C41630-17770 */}
          <div
            className={
              hasSubtitle() ? "header-text" : "header-text header-text-whitespace"
            }
            tabIndex={0}
            aria-label={`Heading 1, ${hasTitle() ? title : ""} ${hasSubtitle() ? subtitle : ""}`}
          >
            {hasTitle() ? (

              <span
                onMouseOver={handleTitleMouseOver}
                onMouseLeave={() => setIsEllipsisActive(false)}>
                {isEllipsisActive ? (
                  <Tooltip
                    id="tooltip1"
                    placement="bottom"
                    data-cv-tracking-id="tooltip-tracking-id"
                    overlay={title}
                    visible={tooltipVisible}
                    trigger="click"
                    overlayClassName="header-tooltip"
                  >
                    <span
                      aria-hidden="true"
                    >
                      {" "}
                      {title}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    aria-hidden="true"
                  >
                    {" "}
                    {title}
                  </span>
                )}
              </span>) : null}

            {hasSubtitle() ? (
              <>
                <span
                  className="header-subtitle"
                  onMouseEnter={(e) => handleOnHover(e)}
                  onMouseLeave={(e) => handleOnHoverOver(e)}
                  aria-hidden="true"
                >
                  {subtitle}
                </span>
              </>
            ) : null}
          </div>
        </div>
        : null}


      <LeftMenu
        visible={showLeftMenu}
        menus={context?.Security.getLeftMenus()}
        footer={
          _showLogoutBtn() ? (
            <Button
              color="danger"
              className="button showLogoutBtnStyle"
              showLoading={showLoading}
              onClick={_handleLogoutButtonClick}
            >
              {__constants.LOG_OUT}
            </Button>
          ) : null
        }
        showBiometricsSwitch={
          biometricsInfo &&
            window.DEVICE_INFO?.autoGenerateSoftToken &&
            context?.Security.isSignedIn()
            ? true
            : false
        }
        biometricsChecked={
          biometricsInfo?.policy === __constants.BIOMETRICS_POLICY.LOGIN
            ? true
            : false
        }
        showNotifSwitch={showNotifSwitch}
        notifChecked={notifChecked}
        close={() => setShowLeftMenu(false)}
        handleMenuClick={(path: string) => {
          console.log("handle left menu click...");
          setShowLeftMenu(false);
          if (path === 'manageCookiePreferences') {
            if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS || (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID && compareVersion("18"))) {
              context.DEVICE.manageCookiePreferences();
            } else {
              openCookieConsentPreferenceCenter();
            }
          } else {
            context?.Router.navigate(path);
          }
        }}
        handleBiometricsChecked={(checkd: boolean) => {
          setShowBiometricView(true);
          setBiometricChecked(checkd);
        }}
        handleNotifChecked={handleNotifChecked}
      />

      {showBiometricView ? (
        biometricChecked ? (
          <BiometricsBindView
            onClose={() => {
              setShowBiometricView(false);
            }}
          />
        ) : (
          <BiometricsUnBindView
            onClose={() => {
              setShowBiometricView(false);
            }}
          />
        )
      ) : null}
    </div>
  );
};

export default LandingHeader;
