import kFX_PULSE from "./constants/FX_PULSE";
import kLOANS from "./constants/LOANS";
import kWIRES from "./constants/WIRES";
import kSYSTEM_ADMINISTRATION from "./constants/SYSTEM_ADMINISTRATION";
import kGENERAL from "./constants/GENERAL";

const Constants = {
    APP_VERSION: "17_0_0",

    //Button
    CANCEL: kGENERAL.CANCEL,
    RESET: kGENERAL.RESET,
    SUBMIT: kGENERAL.SUBMIT,
    DONE: kGENERAL.DONE,
    CONTINUE: kGENERAL.CONTINUE,
    RESTART: kGENERAL.RESTART,
    OK: kGENERAL.OK,

    //Header
    WELCOME_1: 'CitiBusiness® Online Mobile',
    WELCOME_HEADER_1: 'CitiBusiness® Online Mobile',
    WELCOME_CDCB_HEADER_1: 'CitiDirect® Commercial Banking',
    FORGOT_PIN_TITLE: "Forgot PIN",

    //MFA
    REGISTER_A_TOKEN: "Register a Token",
    LOG_IN_TO_BANKING: "Log in to \nCitiBusiness® Online Mobile Banking",

    //Input
    INPUT_ENTER_BUSINESS_CODE: "Business Code",
    INPUT_ENTER_USER_ID: "User ID",
    PASSWORD_EXPIRED_MESSAGE:
        "For security purposes please create a new password",
    TEMPORARY_PASSWORD_MESSAGE:
        "You signed in with a temporary password, please create a new password to proceed",
    CONDITIONS_APPLY: "The following conditions apply:",
    INPUT_ENTER_PASSWORD: "Password",
    INPUT_CURRENT_PASSWORD: "Current Password",
    INPUT_CURRENT_OR_TEMP_PASSWORD: "Current/Temporary Password",
    INPUT_ENTER_NEW_PASSWORD: "New Password",
    INPUT_RE_ENTER_NEW_PASSWORD: "Re-Enter New Password",

    INPUT_ENTER_OTP1: "One-Time Password (OTP1)",
    INPUT_ENTER_PASSCODE: "Enter Passcode",
    INPUT_ENTER_VERIFICATION_CODE: "Enter Verification Code",
    INPUT_CREATE_A_PIN: "Create a New 6-digit alphanumeric PIN",
    INPUT_RETYPE_PIN: "Re-type the new PIN",
    CHARACTERS: "Characters",

    //Register Token
    REGISTER_TOKEN: "Register Token",
    REGISTER_TOKEN_DONE: "You’re Almost done!",
    REGISTER_TOKEN_ENTER_CITI_INFO_TITLE:
        "Enter your CitiBusiness® Online information",
    REGISTER_TOKEN_VERIFICATION_CODE:
        "A verification code has been sent to you via email. Please enter that code below.",
    CREATE_A_PIN: "Create a PIN",
    REGISTER_TOKEN_SUCCESS: "Your Mobile Token has been successfully registered",
    FORGOT_PIN_SUCCESS: "You have successfully created a new PIN",
    VERIFY_TOKEN: {
        KEEP_ALIVE_TIME_OUT: 9,
    },
    EXCEED_ATTMPTS_ERROR:
        "You have exceeded the number of attempts allowed. Please restart the registration process.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING:
        "This is your last available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING_9:
        "This is your 9th available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING_10:
        "This is your 10th available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    VERIFICATION_CODE: "Verifcation Code",

    //Generate Token
    GENERATE_TOKEN_SWITCH_MOBILE_TOKEN: "Mobile Tokens",
    GENERATE_TOKEN_SWITCH_MOBILE_APP: "Mobile App",
    GENERATE_TOKEN_SWITCH_NOTIF: "Notifications",
    //CitiBusiness® Online
    GENERATE_TOKEN_MOBILE_APP_TITLE_1:
        "Log in to",
    GENERATE_TOKEN_MOBILE_APP_TITLE_2: 'CitiBusiness® Online Mobile Banking',
    GENERATE_TOKEN_CDCB_MOBILE_APP_TITLE_1:
        "Log in to",
    GENERATE_TOKEN_CDCB_MOBILE_APP_TITLE_2: 'CitiDirect® Commercial Banking',
    //change pin
    CHANGE_PIN_OLD_PIN: "Current 6-digit alphanumeric PIN",
    CHANGE_PIN_NEW_PIN: "New 6-digit alphanumeric PIN",
    CHANGE_PIN_REENTER_NEW_PIN: "Re-type new PIN",
    CHANGE_PIN_BUTTON_CHANGE_PIN: "Change PIN",
    CHANGE_PIN_BUTTON_CHANGING_PIN: "PIN Change in progress...",
    CHANGE_PIN_SUCCESS_MESSAGE: "PIN Successfully Changed.",
    CHANGE_PIN_PAGE: {
        ERRORS: {
            PINS_MATCH: "New and Confirm PINs must match",
            OLD_PIN_REQUIRED: "old PIN required",
            NEW_PIN_REQUIRED: "new PIN required",
            VERIFY_PIN_REQUIRED: "Verify PIN required",
            VALID_ALPHA_NUMERIC_PIN: "Enter a valid 6 digit alphanumeric PIN",
        },
    },

    //Sign On
    SIGN_IN: "Sign In",
    LOG_IN: "Log In",
    REMEMBER_CODE_AND_ID: "Remember Code and ID",

    //Log out
    LOG_OUT: "Log Out",

    //Forgot Password
    FORGOT_PASSWORD: "Forgot Password",
    SWITCH_ACCOUNTS: "Switch Accounts",
    FORGOT_PASSWORD_TIP: "To reset your password, please call\n 1-800-285-1709",

    //enter passcode
    GENERATE_ENTER_PASSCODE:
        "For token user, please generate and enter OTP1.\nFor new user or non-token user, please check your verified primary email for passcode.",

    //OTP1 or Enter Passcode
    OTP1_OR_ENTER_PASSCODE: "OTP1 or Enter Passcode",

    //Send Email for forgot password
    SEND_EMAIL_PASSWORD:
        "Your temporary password will be sent to the following email address:",
    SEND_EMAIL_PASSCODE:
        "Your passcode has been sent to the following email address:",
    SEND_EMAIL_SUCCEED:
        "Your temporary password has been sent to your email address.",

    //Error message
    ERROR_INCORRECT_INFORMATION:
        'The information provided does not match our records. Please contact customer service at <a href="tel:18002851709" >1(800) 285 1709</a> for further assistance.',
    INVALID_BCODE: "Invalid Business Code",
    INVALID_USER_ID: "Invalid User Id",
    INVALID_OTP1: "Invalid OTP1",
    INVALID_VERIFICATION_CODE: "Invalid Verification Code. Please re-enter.",
    ERROR_PIN_NOT_MATCH: "The two PINs you entered do not match",
    ERROR_NO_NETWORK_CONNECTION: "No network connection....",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_1:
        "Please check to ensure you have entered your credentials accurately. If this problem persists, try again or use",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_2: " Forgot Password",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_3:
        ". For assistance, contact your System Administrator or Customer Service at (800) 285 1709. For speech and/or hearing impaired callers, we accept 711 and other Relay Service calls.",
    ERROR_INCORRECT_EMAIL_FORMAT: "Enter correct email format",
    ERROR_CONFIRM_EMAIL_MISMATCH: "Confirm Primary Email cannot be different",

    //Session Suspended
    SESSION_SUSPENDED: "Session Suspended",
    SESSION_SUSPENDED_INFORMATION:
        "Your CitiBusiness® Online session has been suspended as it is launched in another tab. Please access the application in the other tab.",
    SESSION_SUSPENDED_INFORMATION_LOGIN:
        "Your account is locked due to maximum number of failed verification code challenges. Please contact your Citibank Representative or Customer Service at (800) 285 1709 for assistance. For TTY: We accept 711 or other Relay service.",
    SESSION_SUSPENDED_INFORMATION_WIRE_SETUP:
        "An error has occurred processing your request. Please contact Customer Service at (800) 285 1709 or your Citibank Representative for assistance. For hearing impaired call (800) 788 0002.",

    //Loading
    LOADING: kGENERAL.LOADING,
    LOADING_VERIFY: "Verifying...",


    //No data
    NOT_CASHMGMT_DATA:
        "Would you like to see today's activity? Contact your relationship manager for more information.",

    //Request
    REQ_FAILED: kGENERAL.REQ_FAILED,
    //No data
    NO_DATA: kGENERAL.NO_DATA,
    //Common Filter constants for Wires, Bill payments, Internal transfer
    FILTER_PARAMS: kGENERAL.FILTER_PARAMS,

    //Network
    NO_NETWORK: "noNetwork",

    RESET_PASSWORD: "Reset Password",

    //Left menu
    CONTACT_US: "Contact Us",
    FAQS: "Frequently Asked Questions",
    USER_AGREEMENT: "CitiBusiness® Online User Agreement",
    TERM_OF_USE: "Mobile Terms of Use",
    PRIVACY_STATEMENT: "Privacy Statement",
    CHANGE_PASSWORD: "Change Your Password",
    MOBILE_TKN_MANAGEMENT: "Mobile Token Management",
    SYSTEM_ADMIN: "System Administration",
    MANAGE_COOKIE_PREFERENCES: "Manage Cookie Preferences",
    COOKIE_POLICY: "Cookie Policy",

    LEFT_MENU_MAP: {
        USER_ADMIN: "User Administration",
        TOKEN_MANAGEMENT: "Mobile Token Management",
        SYSTEM_ADMIN: "System Admin Functions",
    },

    //Bottom Navigation
    NAVIGATION: {
        HOME: "Home",
        ACCOUNTS: "Accounts",
        APPROVALS: "Approvals",
        PAYMENTS: "Payments",
        DEPOSITS: "Deposits",
        DEPOSITS_MAP: "mobileCheckDeposit",
    },

    // Push Notifications
    PUSH_NOTIF: "Notifications",
    PUSH_NOTIF_AUTH: {
        NOT_DETERMINED: "notDetermined",
        DENIED: "denied",
        AUTHORIZED: "authorized"
    },
    PUSH_NOTIF_SOURCES: {
        CCB: "Citi Commercial Bank"
    },
    PUSH_NOTIF_ENROLL: {
        PROGRESS: "Enroll Notifications in progress...",
        SUCCESS: "You have been enrolled for Notifications",
    },
    PUSH_NOTIF_UNENROLL: {
        PROGRESS: "Unenroll Notifications in progress...",
        CONFIRM: "You're being unenrolled from Notifications",
        SUCCESS: "You have been Unenrolled for Notifications",
    },
    PUSH_NOTIF_HISTORY: {
        DEINED: {
            WARNING: "Warning",
            CONTENT: "Push Notifications are disabled on your device. Update your notification settings to receive push notifications.",
            CHANGE_BTN: "Change Settings"
        }
    },

    //Card for Home screen, Loans, Foreign Exchange
    NAVIGATION_CARD: {
        LOANS: {
            title: "Loans",
            details: "View Loans information",
        },
        FOREIGN_EXCHANGE: {
            title: "Foreign Exchange",
            details: "View rates for all foreign transactions",
        },
        CASH: {
            title: "Cash Accounts",
            details: "View Home page",
        }
    },

    // Transmit - Biometrics
    BIOMETRICS: "Biometrics",
    SET_UP_BIOMETRICS: "Set Up Biometrics",
    BIOMETRIC_LOGIN: "Biometric Login",
    BIOMETRICS_CONTEXT: {
        LOGIN: "Biometric Login",
        ENROLL: "Biometric Enroll",
        UNENROLL: "Biometric Unenroll"
    },
    BIOMETRICS_ID: {
        FACE_ID: "Face ID",
        TOUCH_ID: "Touch ID",
        FINGERPRINT: "Fingerprint"
    },
    BIOMETRICS_POLICY: {
        BIND: "Bind",
        LOGIN: "Login"
    },
    BIOMETRICS_ACTION: {
        CANCELED: "canceled",
        NOREGISTERED: "noRegistered"
    },
    BIOMETRICS_EVENT_TYPE: {
        ENROLL: "enroll",
        UNENROLL: "unenroll"
    },
    BIOMETRICS_CHALLENGE_TYPE: {
        EMAIL: "emailChallenge",
        PHONE: "phoneChallenge"
    },
    BIOMETRICS_FOOTER: {
        ENROLL: "Enroll"
    },
    BIOMETRICS_OFFLINE: {
        LOGIN: "Biometrics temporarily offline.  Enter password to log in or try back later for biometric log in.",
        ENROLL: "Biometrics temporarily offline.  Please try setting up biometrics later.",
        UNENROLL: "Biometrics temporarily offline.  Please try turning off biometrics later."
    },
    BIOMETRICS_LOGIN: {
        LOGIN: "Log In",
        SERVER_ERROR: "Biometric login is temporarily unavailable.",
        CHANGED: "Existing Face ID authentication is no longer valid.  It is necessary to re-enroll in Face ID on the CitiBusiness Mobile app."
    },
    BIOMETRICS_ENROLL: {
        TITLE: "Log In quickly with Biometrics",
        SUCCESS_TITLE: "You’ve turned on Biometrics",
        CONTENT: "When you Sign In to your account using Face ID, you don’t need to enter your password/credentials. Anyone enrolled into biometrics at the device level will have access to your accounts.\n\nWe don’t store your Face ID information.",
        SUCCESS_CONTENT: "You’ll be able to Sign In with Biometrics next time",
        ERROR_CONTENT: "You have opted out of biometrics"
    },
    BIOMETRICS_UNENROLL: {
        SUCCESS_TITLE: "You’ve turned off Face ID",
        CONFIRM_CONTENT: "You are about to turn off Face ID®",
        SUCCESS_CONTENT: "You’ll be able to Sign In with your username and password next time."
    },

    // TMX Challenge
    TMX_CHALLENGE_HEADER: "Confirm Your Identity",
    // TMX_CHALLENGE_CONTENT_1: 'In order to provide you with extra security, we occasionally need to ask for additional information when you use certain features in CitiBusiness® Online.',
    TMX_CHALLENGE_CONTENT_2:
        "If you are having problems completing this process, please call Customer Service at (800) 285 1709. For hearing impaired call (800) 788 0002.",
    TMX_CHALLENGE_EMAIL: {
        CONTENT: "Please enter the Verification Code sent to ",
        LABEL: "Verification Code",
    },
    TMX_CHALLENGE_SESSION_SUSPENDED: "Session Suspended",
    TMX_SESSION_SUSPENDED_LOGIN_CONTENT:
        "Your account is locked due to maximum number of failed verification code challenges. Please contact your Citibank Representative or Customer Service at (800) 285 1709 for assistance. For TTY: We accept 711 or other Relay service.",
    TMX_SESSION_SUSPENDED_WIRE_CONTENT:
        "An error has occurred processing your request. Please contact Customer Service at (800) 285 1709 or your Citibank Representative for assistance. For hearing impaired call (800) 788 0002.",

    //Banking Enrollment
    ENROLLMENT_MESSAGE:
        "Our records indicate you do not have access to use CitiBusiness® Mobile Banking App. Please request entitlement for access from your security administrator.",
    ENROLLMENT_RETURN: "Return to Home",

    //Action Required
    ACTION_REQUIRED_HEADER: {
        ACTION_REQUIRED: "Action Required",
        WARNING: "Warning!",
        VERIFY: "Verify your Phone Number",
        UPDATE: "Update your Phone Number",
        RECEIVE: "Receive Verification Code",
        SECURITY: "Verification Code",
        EMAIL: "Email Address",
        EMAIL_PIN: "Verification PIN",
        SUCCESS: "Success!",
    },
    ACTION_REQUIRED_REMAINING: {
        DAYS: "Days Remaining before you will be required to update your contact phone number before accessing CitiBusiness Online.",
        HAS_SA:
            " Please contact your System Administrator to update your information.",
        NO_SA:
            " If your phone number has changed, please contact your Service Officer or Customer Service 1-800-285-1709 to update your information.",
    },
    ACTION_REQUIRED_CONTENT: {
        COMMON:
            "Keeping your contact phone number associated with your CitiBusiness® Online Profile up to date helps us to provide you with an improved, more secure digital experience.\n\n",
        CONFIRM: "Please confirm your contact phone number now.\n\n",
        NEED_CONFIRM:
            "You need to confirm your contact phone number to be able to continue using CitiBusiness® Online.\n\nIf you have any questions, call Customer Service at 1-800-285-1709.\n\n",
    },
    ACTION_REQUIRED_WARNING:
        "Are you sure you want to close and cancel before updating your contact phone number?  Having an up to date phone number allows us to contact you in case any suspicious activity is detected.\n\nYou have <XX> days left to update your phone number after which time you will not be able to access CitiBusiness® Online.\n\nThis message will be presented each time you log on until this contact information is updated.",
    ACTION_REQUIRED_FOOTER: {
        SKIP: "Skip",
        BEGIN: "Begin",
        YES: "Yes, Complete at a future date",
        SEND_CODE: "Send Code",
        SUBMIT: "Submit",
        CLOSE: "Close",
        PREVIOUS: "Previous",
        SAVE: "Save & Continue",
    },
    ACTION_REQUIRED_APPLY_LABLE: {
        LOADING: "Loading",
        ENTER_TOKEN: "Please enter the correct OTP1 Token to active",
        ENTER_NUM: "Please enter the correct phone number to active",
        SELECT_NUM: "Please select one phone number to active",
        DELIVERY_METHOD:
            "Please select the delivery method of Verification code to active",
        ENTER_CODE: "Please enter the correct Verification Code to active",
    },
    ACTION_REQUIRED_STEPS: {
        STEP1: "Phone Number",
        STEP2: "Contact Information",
        STEP3: "Verification Code",
        STEP4: "Email Address",
    },
    ACTION_REQUIRED_SUCCESS:
        "Thank you, your contact information has been successfully updated in your CitiBusiness® Online Profile.",
    ACTION_REQUIRED_MAP: {
        INTL_PHONE: "intlPhone",
        COUNTRY_CODE: "countryCode",
        PHONE: "phone",
        MOBILE_1: "mobile1",
        MOBILE_2: "mobile2",
    },
    TOKEN_REQUIRED_CONTENT:
        "Keeping your contact phone number associated with your CitiBusiness® Online Profile up to date helps us to provide you with an improved, more secure digital experience. Having an up-to-date phone number allows us to contact you in case any suspicious activity is detected.\n\nPlease enter your One Time Password (OTP1) from your token in order to verify your phone number. If you do not have your token now, select cancel to do this later.\n\n",
    TOKEN_REQUIRED_DAYS:
        "days left to update your phone number after which time you will not be able to access CitiBusiness® Online.",
    TOKEN_REQUIRED_INPUT_LABEL: "Enter OTP1 Token",
    TOKEN_VERIFE_FAILED: "The information you have entered is not correct.",
    PHONE_NUM_TYPE: {
        BUSINESS_PHONE: "Business Phone",
        INTL_PHONE: "International Phone",
        PHONE_1: "Mobile Phone 1",
        PHONE_2: "Mobile Phone 2",
    },
    VERIFY_PHONE_NUM: {
        NORMAL:
            "\n\nIf your phone number has changed, you may update it through Update Your Contact Info page under System Administration by logging on to the CitiBusiness® Online website.",
        UPDATED:
            "\n\nIf your phone number has changed, you may update it below and then follow the verification steps.",
        HAS_SA:
            "\n\nIf your phone number has changed, please contact your System Administrator to update your information.",
        NO_SA:
            "\n\nIf your phone number has changed, please contact your Service Officer or Customer Service 1-800-285-1709 to update your information.",
    },
    UPDATE_PHONE_NUM_CONTENT:
        "At least one active and valid phone number you use with your business is required for authentication purposes as part of ongoing security enhancements to CitiBusiness® Online and other Citi websites. The verified phone number will be used to provide a security passcode when required to access your accounts online. You must provide a phone number that dials direct to you to receive this passcode.",
    UPDATE_PHONE_NUM_SELECT_DEFAULT: "Select",
    UPDATE_PHONE_NUM_INPUT_TIPS: {
        BUSINESS_PHONE:
            "Allows for only US & Canada landline numbers. Enter area code in the first box. Phone numbers with an extension may not be able to confirm identity, so if a number with extension is used you must add at least one more phone number.",
        INTL_PHONE:
            'Allows for both international mobile & landline numbers. Enter country code in the first box without the "+" , i.e. use 91 for India. Phone numbers with an extension may not be able to confirm identity, so if a number with extension is used you must add at least one more phone number.',
        PHONE_1: "Allows for only US & Canada mobile numbers.",
        PHONE_2: "Allows for only US & Canada mobile numbers.",
    },
    UPDATE_PHONE_NUM: {
        DROPDOWN_LABEL: "Select Phone Type",
        INPUT_LABEL: "Phone Number",
    },
    RECEIVE_CODE_CONTENT:
        "Please select how you would like to receive your Verification Code.",
    RECEIVE_CODE_FOOTER:
        "By selecting “Send Code”, you agree to receive a phone call, prerecorded message, or text message from an automated dialing system at the phone number listed to receive your verification code. Your mobile carrier’s phone or data usage fees may apply.",
    RECEIVE_CODE_PREFIX: {
        TEXT: "Text me at",
        CALL: "Call me at",
    },
    SECURITY_CODE_TYPE: {
        SMS: "SMS",
        PHONE: "PHONE",
    },
    SECURITY_CODE: {
        SEND: "A Verification Code was sent to",
        INPUT_LABEL: "Enter your 8 Digit Verification Code",
        RESEND: "Resend Verification Code",
        INVALID: "Invalid Verification Code. Please re-enter.",
        CALL: "We are currently calling",
        ENTER:
            "Please enter the verification code below on your phone when prompted.",
        GENERATE: "Generate a new Verification Code.",
    },
    PHONE_VERITY_ERROR: {
        TITLE: "Couldn't Verify Verification Code",
        CONTENT:
            "We are experiencing a problem validating your verification code on the phone.\n\nPlease tap “Generate a new Verification Code” below to verify.",
    },
    EMAIL_ADDRESS_CONTENT:
        "CitiBusiness® Online will soon be integrated with other Citi Commercial Bank websites to provide you with a seamless online banking experience. As new features become available, we will send you information about how to access these sites.\n\nIf you change your email address here, you will be required to verify before saving.",
    EMAIL_ADDRESS_LABEL: {
        EMAIL: "Email",
        RE_ENTER_EMAIL: "Re-enter Email",
    },
    EMAIL_ADDRESS_VALID: {
        TAKEN: "Your email has been taken, please try another email address.",
        VALID_FORMAT: "Email address is valid format (abcd123@email.com).",
        MATCH: "Email addresses must match.",
    },
    EMAIL_ADDRESS_NOTE_CONTENT:
        "Your email address has been updated. If you have subscribed to account alerts via email, you will need to set up alerts to your email address in your Alerts settings in order to continute to receive notifications.",
    EMAIL_ADDRESS_NOTE_PRE: "Please Note: ",
    EMAIL_ADDRESS_VERIFY: {
        CONTENT_1: "Please enter the PIN sent to ",
        CONTENT_2: " to confirm your email address.",
        LABEL: "Enter PIN",
        RESEND: "Resend Verification PIN",
        INVALID: "Invalid Verification PIN. Please re-enter.",
    },

    // change your password
    CHANGE_YOUR_PASSWORD: {
        CHARACTER_SIX_TO_TEN_LIMIT: "Must be between 6 - 10 characters",
        CHARACTER_LIMIT: "Must be between 8 - 10 characters",
        NUMBER_LIMIT: "Must contain at least 1 number",
        UPPERCASE_LIMIT: "Must contain at least 1 upper case letter",
        LOWERCASE_LIMIT: "Must contain at least 1 lower case letter",
        IDENTICAL_CHARACTER_LIMIT:
            "Cannot have more than 2 consecutive identical characters",
        SPACES_LIMIT: "Cannot contain spaces",
        // CONTAIN_SPECIAL_CHARACTER: 'Must contain at least one special character:\n~ ` ! @ # $ ^ & * ( ) _ - \\ / |',
        CONTAIN_SPECIAL_CHARACTER:
            "Must contain at least one special character:\n~ ` ! @ # $ ^ * ( ) _ - / |",
        SPECIAL_CHARACTER_OR_SPACES_LIMIT:
            "Cannot contain special characters or spaces",
        PASSWORD_MATCH: "New passwords must match",
        PASSWORD_MATCH_LAST_6_PASSWORDS_LIMT:
            "Passwords are case sensitive and cannot match any of your last 6 passwords",
    },

    //Message on Header
    MESSAGE_TYPE: {
        MESSAGE: "CITI_MESSAGE",
    },

    //Signin Home
    VIEW_ONLY_MODE: "You are in View-Only mode",
    ENTER_TOKEN:
        "Please enter an OTP token during log-in to access full features.",

    //Actions
    QUICK_ACTIONS: "Quick Actions",
    TRANSACTIONS_APPROVALS: "Transactions Awaiting Approval",
    POSITIVE_PAY_EXCEPTION: "ARP Positive Pay Exceptions",
    SYSTEM_ADMIN_APPROVALS: "System Admin Approvals",
    ARP_DISPOSITIONED_SUMMARY: "ARP Positive Pay Dispositioned Summary",

    INSTANT_PAYMENTS: "Instant Payments",
    INSTANT_PAYMENTS_INITIATE: "Instant Payments Initiate",
    INSTANT_PAYMENTS_TRANSACTION_DETAILS: "Instant Payments Transaction Details",

    //Accounts
    ACCOUNTS: "Accounts",
    ACCOUNTS_AVAILABLE: "Current Available",
    ACCOUNTS_CURRENT_VAL: "Start Day Ledger",
    ACCOUNTS_PAYOFF: "Payoff Amount",
    ACCOUNTS_TYPE: {
        DEPOSIT: "DEPOSIT",
        CHECKING: "CHECKING",
        IMMA: "IMMA",
        SAVINGS: "SAVINGS",
        CD: "CD",
        TOTAL: "TOTAL",
        LOAN: "LOAN",
        CREDIT: "CREDIT",
        CREDIT_CARD: "CREDIT_CARD",
        PERSONAL_READY_CREDIT: "PERSONAL_READY_CREDIT",
        PERSONAL_CHECKING_PLUS: "PERSONAL_CHECKING_PLUS",
        CHECK: "CHECK",
        LINE_OF_CREDIT: "CREDIT",
    },
    ACCOUNTS_PREFIX: {
        CHECKING: "Checking",
        IMMA: "IMMA",
        SAVINGS: "Savings",
        CD: "CDs",
        LINES_OF_CREDIT: "Line of Credit Account",
        LOAN_ACCOUNT: "Loan Account",
        CREDIT_CARD: "Credit Card",
        PERSONAL_CHECKING_PLUS: "Personal Checking Plus",
    },
    ACCOUNTS_REQUEST: {
        DEPOSIT_ACCOUNT: "DEPOSIT_ACCOUNT",
        CREDIT_ACCOUNT: "CREDIT_ACCOUNT",
        CONTROL_ACCOUNT: "CONTROL_ACCOUNT",
    },
    ACCOUNTS_ITEM_KEY: {
        START_OF_DAY_LEDGER: "Start Day Ledger",
        START_OF_DAY_AVAILABLE: "Start Day Available",
        START_OF_DAY_AVAIL: "Start Day Avail...",
        LEDGER: "Current Ledger",
        AVAILABLE: "Current Available",
        VALUE: "Current Value",
        RATE: "Rate",
        ACCRUED_INTEREST: "Accrued Interest",
        YTD_INTEREST: "YTD Interest",
        TERM: "Term",
        MATURITY_DATE: "Maturity Date",
        INTEREST_RATE: "Interest Rate",
        ANNUAL_YIELD: "Annual Yield",
        ATTORNEY_NAME: "Attorney Name/Code",
        REFERENCE_NAME: "Reference Name/Code",
        EXCHANGE_NAME: "Exchange Manager Name/Code",
        LANDLORD_NAME: "Landlord Name/Code",
        CLIENT_CODE: "Client Code",
        TENANT_CODE: "Tenant Code",
    },
    ACCOUNTS_ACTIVITY_TYPE: {
        DEBIT_AND_CREDIT: "All",
        DEBIT: "Debit",
        CREDIT: "Credit",
        PAID_CHECKS: "Check",
    },
    ACCOUNTS_ACTIVITY_DATE: {
        ALL: "Previously Posted Data",
        TODAY: "Today",
    },
    ACCOUNTS_ITEM_KEY_TRUNCATION_AMOUNT: 999999999.99,

    //Deposit Accounts
    DEPOSIT_ACCOUNTS: "Deposit Accounts",
    DEPOSIT_ACCOUNTS_TITLE: {
        CHECKING: "Checking Account(s)",
        IMMAS: "IMMA(s)",
        SAVINGS: "Savings Account(s)",
        CD: "CD(s)",
        ALL: "All Deposit Account(s)",
        SUMMARY: "Deposit Summary(USD)",
    },
    DEPOSIT_ACCOUNTS_LIST_TITLE: {
        CHECKING: "Checking Accounts",
        IMMAS: "Insured Money Market Account(s)",
        SAVINGS: "Savings Accounts",
        CD: "CDs Accounts",
    },

    //Credit Accounts
    CREDIT_ACCOUNTS: "Credit Accounts",
    CREDIT_ACCOUNTS_TITLE: {
        LOAN: "Loan(s)",
        CREDIT: "Line(s) of Credit",
        CREDIT_CARD: "Credit Card(s)",
        PERSONAL_READY_CREDIT: "Personal Ready Credit",
        PERSONAL_CHECKING_PLUS: "Personal Checking Plus",
        ALL: "All Credit Account(s)",
        SUMMARY: "Credit Summary(USD)",
    },
    CREDIT_ACCOUNTS_PAGE: {
        LOAN: "loanAccountPage",
        CREDIT: "creditLineAccountPage",
        CREDIT_CARD: "creditCardAccountPage",
        PERSONAL_READY_CREDIT: "readyCreditPage",
        PERSONAL_CHECKING_PLUS: "creditAccountPage",
    },
    CREDIT_ACCOUNTS_SUMMARY: {
        UNPAID: "Unpaid Amount",
        PAYOFF: "Payoff Amount",
        CREDIT_LINE: "Credit Line",
        CREDIT_USED: "Credit Used",
        CREDIT_AVAILABLE: "Credit Available",
    },
    CREDIT_ACCOUNTS_LIST_ITEM_TITLE: {
        LINES_OF_CREDIT: "Line(s) of Credit",
        LOANS: "Loan(s)",
        CREDIT_CARD: "Credit Card(s)",
    },
    ACCOUNT_TRANSACTIONS: {
        DISPLAY_ACTIVITY_TYPE: "Display Activity Type",
        DISPLAY_ACTIVITY_DATE: "Display Activity Date",
        ALL: "All",
        DEBIT: "Debit",
        CREDIT: "Credit",
        CHECK: "Check",
        TODAY: "Today",
        PREVIOSULY_POSTED_DATA: "Previously Posted Data",
        LOAD_MORE: "Load More",
    },

    //Control Accounts
    CONTROL_ACCOUNTS: "Control Accounts",
    CONTROL_ACCOUNTS_GROUP_NAME: "CONTROL",
    CONTROL_ACCOUNTS_TITLE: {
        ESCROW_ACCOUNTS: "CitiEscrow Account(s)",
        DEPOSIT_ADMINISTRATOR_ACCOUNTS: "Deposit Administrator Account(s)",
        DEPOSIT_ADMINISTRATOR_ACCOUNTS_LIST: "Deposit Admin Account(s)",
        EXCHANGE_MANAGEMENT_ACCOUNTS: "Exchange Management Account(s)",
        EXCHANGE_MANAGEMENT_ACCOUNTS_LIST: "Exchange Mgmt Account(s)",
        SECURITY_DEPOSIT_ACCOUNTS: "Security Account(s)",
    },
    CONTROL_ACCOUNTS_TYPE: {
        ESCROW_ACCOUNTS: "ESCROW_ACCOUNTS",
        DEPOSIT_ADMINISTRATOR_ACCOUNTS: "DEPOSIT_ADMINISTRATOR_ACCOUNTS",
        EXCHANGE_MANAGEMENT_ACCOUNTS: "EXCHANGE_MANAGEMENT_ACCOUNTS",
        SECURITY_DEPOSIT_ACCOUNTS: "SECURITY_DEPOSIT_ACCOUNTS",
    },

    DEPOSIT_CHEQUE: {
        ERROR_ENTITLEMENT_HEADER:
            "Sorry! Looks like you’re not entitled to use CitiBusiness® Mobile Deposit yet",
        ERROR_ENTITLEMENT_EROR_DESCRIPTION:
            "Please reach out to your Citi Representative to sign up and start depositing anytime and anywhere within the U.S.”",
        EXTRA_INFO_2:
            "You can now deposit your checks in the Citibusiness Mobile App.",
        EXTRA_INFO: "DID YOU KNOW?",
        DISCLAIMER_DESC:
            "© 2021 Citigroup Inc. Citibank, N.A. Member FDIC. Equal Credit Opportunity Lender. All rights reserved. Citi, Citi and Arc Design and other marks used herein are service marks of Citigroup Inc. or its affiliates, used and registered throughout the world.",
        DISCLAIMER_DESC_2:
            "Please see terms and conditions related to Remote Check Deposit here",
    },

    ACCOUNT: "Account",
    NAME_NUMBER_TYPE: "Name, Number, Type",
    MANDATORY_SIGN: "*",
    REQUIRED: "Required",

    //Internal Transfers
    INTERNAL_TRANSFER: "Internal Transfers",

    //Approvals
    APPROVALS: "Approvals",
    APPROVALS_HEADER: {
        APPROVALS: "Approvals",
        TRANSACTION_AWAITING: "Transactions Awaiting Approval",
        DETAILS: "Approval Details",
    },
    APPROVALS_TOTAL: "Total Pending",

    //Approvals - Transaction Awaiting Approval
    APPROVALS_TRANSACTION_TOTAL: "Total Approvals Pending",
    APPROVALS_SORT_ORDER: {
        AMOUNT_HIGH_FIRST: "AMOUNT_HIGH_FIRST",
        AMOUNT_LOW_FIRST: "AMOUNT_LOW_FIRST",
        PROCESSING_DATE_NEW_FIRST: "PROCESSING_DATE_NEW_FIRST",
        PROCESSING_DATE_OLD_FIRST: "PROCESSING_DATE_OLD_FIRST",
        SETUP_DATE_NEW_FIRST: "SETUP_DATE_NEW_FIRST",
        SETUP_DATE_OLD_FIRST: "SETUP_DATE_OLD_FIRST",
        ADDITIONAL_REFERENCE_HIGH_FIRST: "ADDITIONAL_REFERENCE_HIGH_FIRST",
        ADDITIONAL_REFERENCE_LOW_FIRST: "ADDITIONAL_REFERENCE_LOW_FIRST",
    },
    APPROVALS_TRANSACTION_TYPES: {
        WIRES_NON_BATCH: "WIRES_NON_BATCH",
        WIRES_FX: "WIRES_FX",
        WIRES_BATCH: "WIRES_BATCH",
        WIRE_MODELS_INDIVIDUAL: "WIRE_MODELS_INDIVIDUAL",
        WIRE_DIRECT_FILE: "WIRE_DIRECT_FILE",
        TRANSFER_NON_BATCH: "TRANSFER_NON_BATCH",
        TRANSFER_BATCH: "TRANSFER_BATCH",
        BILL_PAYMENTS: "BILL_PAYMENTS",
        ACH: "ACH",
        OPEN_ESCROW: "OPEN_ESCROW",
        OPEN_IMMA: "OPEN_IMMA",
        OPEN_CD: "OPEN_CD",
        LIQUIDATE: "LIQUIDATE",
        INSTANT_PAYMENTS_UNHANDLED: "INSTANT_PAYMENTS_UNHANDLED",
        INSTANT_PAYMENTS: "INSTANT_PAYMENTS",
    },
    APPROVALS_SORT_LABEL: "Sort Order",
    APPROVALS_EXPAND_LABEL: "Expand",
    APPROVALS_DEFAULT_SORT: "Amount - Highest First",
    APPROVALS_CUTOFF_TIME: "Cutoff time is",
    APPROVALS_VIEW_DETAILS: "View Additional Details",
    APPROVALS_NOTIFY: "Chase Approval",
    APPROVALS_DEFER_WARNING: "* All approvals are set to defer",
    APPROVALS_FX_FIELD: "Y",
    APPROVALS_RECURRING: "Recurring",
    APPROVALS_SCHEDULING: "Scheduling and Amount",
    APPROVALS_WIRES_FX: {
        INFO_KEY: "**FX Wires must be approved one at a time",
        INFO_VALUE: `To proceed, select "View Additional Details" option to view and/or approve an instruction.`,
        EXCHANGE_RATES_MESSAGE: "When this exchange rate expires, a new rate will be applied.\n\nFluctuation in FX rates may cause the US Dollar equivalent for this transaction to change. As a result, if you are the transaction approver, please be aware that the US Dollar equivalent may now exceed the entitlement limits for the initiating user."
    },
    APPROVALS_LIQUIDATION: {
        MESSAGE1: "This account has a balance of",
        MESSAGE2:
            "and cannot be liquidated at this time. You may leave this instruction pending approval until the balances are sufficient for liquidation or you may reject this instruction. Instructions left pending approval will be automatically rejected after 90 days.",
    },
    APPROVALS_REQUESTED_ACTION: {
        CANCEL: "cancel",
        SUSPEND: "suspend",
    },
    APPROVALS_SWITCH: {
        DEFER: "defer",
        APPROVE: "approve",
        REJECT: "reject",
        DEFER_ALL: "Defer All",
        APPROVE_ALL: "Approve All",
        REJECT_ALL: "Reject All",
    },
    APPROVALS_SUBMIT: {
        HEADER: {
            CREDENTIALS: "Enter your credentials",
            SUCCESS: "Success",
        },
        LABEL: {
            OTP2: "OTP2",
            PASSWORD: "Password",
        },
        FOOTER: {
            APPLY: "Apply",
            NEW: "New Approval",
            CLOSE: "Close",
        },
        STATUS: {
            FRAUD_QUEUE:
                "The following actions require immediate attention. To complete the execution of your wire request, contact CitiBusiness Online Customer Service at 1-866-583-2354 between 8AM and 8PM ET. Your wire could be delayed or canceled if you do not contact Customer Service. If the wire is canceled, the funds will be returned to the source account.",
        },
        INPUT_ERROR:
            "The security information you entered is not valid. Please try again.",
        RESPONSE_CODE: "APPROVED_6",
        NSF_RESPONSE_CODE: "INSUFFICIENT_FUNDS_3",
        ADDITIONAL_APPROVAL_CODE: "NEEDS_ADDITIONAL_7",
        FRAUD_QUEUE: "FRAUD_QUEUE_0",
        OPEN_ESCROW_NSF: "OPEN_ESCROW_NSF_4",
        NSF_MESSAGE: "The source account has insufficient fund",
        TOKEN_INSTRUCTIONS: "Token Instructions:",
        PRESS_OTP_BUTTON: "Press/touch OTP2 button",
        TOKEN_PASSCODE: "Enter the Token passcode into the Token field",
        APPROVALS_CONFIRMATION: "Approvals Confirmation",
        APPROVALS_DETAILS: "Details",
        BAD_REQUEST: "BAD_REQUEST"
    },

    //Bill Payments - make payments
    BILL_PAYMENTS: "Bill Payments - Initiate",
    BILL_PAYMENTS_EDIT: "Bill Payments - Edit",
    BILL_PAYMENTS_SETUP_TRANSFER: "Setup a New Transfer",
    BILL_PAYMENTS_LABEL: {
        AMOUNT: "Amount",
        FROM: "From Account",
        TO: "To Payee",
        DATE: "Payment Date",
        PAYEE: "Payee",
        TRANSACTION_DATE: "Transaction Date",
        START_DATE: "Start Date",
        HOW_OFTEN: "How Often",
        SCHEDULE: "Schedule",
        FREQUENCY: "Frequency",
        STOP: "Stop Recurring Transaction",
        SECOND_DATE: "Second Date",
        NUMBER_OF_TRANSACTIONS: "Number of Transactions",
        TOTAL_AMOUNT_PAID: "Total Amount Paid",
    },
    BILL_PAYMENTS_PLACEHOLDER: {
        DATE: "mm/dd/yyyy",
        SELECT: "Select",
        SEARCH: "Search",
        SELECT_DAY: "Select day",
        DAY: "dd",
    },
    BILL_PAYMENTS_FOOTER: {
        DATE: "Hint: schedule payments 5 - 7 business days before due date",
        MEMO: "Please Note: Memo is not sent for electronic payments.",
    },
    BILL_PAYMENTS_BTN: {
        PROCEED: "Proceed to Review",
    },
    BILL_PAYMENTS_ERROR: {
        MIN_NUM: "The number of payments cannot be less than 1.",
        MIN_AMOUNT: "The payment amount cannot be less than $1.00.",
        TOTAL_AMOUNT: "The total amount cannot be less than one payment.",
        MEMO: "The MEMO contains invalid characters. Only upper and lower case letters, numbers, spaces and the following special characters are allowed: :;'@#$()_+.,-",
    },
    BILL_PAYMENTS_ACCOUNT: {
        SELECT: "Select an Account",
        AVAILABLE: "Balance Available",
        DRAWER_TITLE: "Select a From Account",
        SEARCH_TITLE: "Search From Account",
        CHECKING_ACCOUNT_NOTE: "Primary Fee account must be a Checking account to be eligible for Bill Payment\n"
    },
    BILL_PAYMENTS_PAYEE: {
        SELECT: "Select a Payee",
        DRAWER_TITLE: "Select a Payee",
        SEARCH_TITLE: "Search Payee",
    },
    BILL_PAYMENTS_HOW_OFTEN: {
        ONCE: "Once",
        RECURRING: "Recurring",
    },
    BILL_PAYMENTS_RECURRING: {
        TYPE: {
            EMPTY: "EMPTY",
            UNTIL: "UNTIL",
            NUMBER: "NUMBER",
            AMOUNT: "AMOUNT",
        },
        SELECT_TRANSACTION_FREQUENCY: "Select Transaction Frequency",
        FREQUENCY: {
            ONCE_A_WEEK: "Once a Week",
            EVERY_TWO_WEEKS: "Twice a Month",
            MONTHLY: "Monthly",
            QUARTERLY: "Quarterly",
            EVERY_SIX_MONTHS: "Every 6 Months",
            ONCE_A_YEAR: "Once a year",
        },
        STOP: {
            UNTIL: "When Cancelled",
            NUMBER: "After Number of Transactions",
            AMOUNT: "After Total Amount Paid",
        },
        DRAWER_TITLE: {
            TRANSACTION_FREQUENCY: "Transaction Frequency",
            STOP: "Stop",
        },
    },
    BILL_PAYMENTS_SUBMIT: {
        ENTER_PASSWORD: "Password",
        ERROR_INFO: "An incorrect password has been entered.  Please try again.",
        MAKEANOTHERBUTTON: "Make Another Bill Payment",
        SUCCESS_TITLE: "Your bill payment instruction has been accepted.",
        SUCCESS_TITLE_RECURRING: "Your Recurring Payment is set up.",
        SUCCESS_TITLE_ONCE: "Request sent to Approvals.",
        SUCCESS_INFO:
            "\nA bill payment instruction can be changed or cancelled by 11:45 PM ET the day before the scheduled payment date.\n\nImportant: Once processing begins, a payment made via electronic funds transfer (EFT) cannot be stopped; bill payments made by check can be stopped, but ONLY by using the Report a Bill Payment Problem menu option on the CitiBusiness Online Desktop Application.\n\n",
        SUCCESS_INFO_RECURRING_EDIT: "\nYour Request has been sent to Approvals.\n",
        SUCCESS_INFO_RECURRING:
            "\nPayments must be approved by 11:59 PM of the day Before they are scheduled in order for the payment to be made.",
        ERROR_AMOUNT_LIMIT: 'Not entitled for that amount'
    },

    BILL_PAY_TRANSACTION_DETAILS: {
        CANCEL_WARNING_TEXT: "Once you confirm, you can not undo this action.",
        CANCEL_SUCCESS_TEXT1: "Your recurring payment for ",
        CANCEL_SUCCESS_TEXT2: " is cancelled",
        CANCEL_FAIL_TEXT:
            "Failed to cancel recurring payment. Please try again in sometime.",
    },

    BILL_PAYMENTS_HISTORY: {
        STATUS: {
            OPEN: "OPEN",
            CLEARED: "CLEARED",
            CANCELLED: "CANCELLED",
            RECURRING: "Recurring Payment",
            SINGLE: "Single Payment",
            SCHEDULED: "Scheduled",
            PENDING: "Pending",
            CONFIRM_CANCEL: "Confirm Cancel",
        },
        CANCEL: "Cancel",
        HEADER: "Bill Payments - Transaction Details",
    },

    //Privacy
    NOTICE_AT_COLLECTION: "Notice at Collection",
    CA_PRIVACY_HUB: "CA Privacy Hub",
    PRIVACY_LINKS: {
        PRIVACY_STATEMENT: "https://icg.citi.com/icghome/privacy", //original link - https://www.citibank.com/commercialbank/en/privacy.html'
        NOTICE_AT_COLLECTION:
            "https://icg.citi.com/rcs/icgPublic/storage/public/ICG-Global-PN-California-Supplement.pdf", //original link - https://www.citibank.com/commercialbank/en/privacy.html#information
        CA_PRIVACY_HUB: "https://online.citi.com/US/ag/dataprivacyhub/home",
    },
    VIEW_LINK: "View link on your Browser",

    //Cookie Policy
    COOKIE_POLICY_LINK: {
        COOKIE_POLICY: "https://www.citigroup.com/global/institutional-clients/cookiepolicy",
    },


    // Piwik
    ANDROID: "Android",
    IOS: "iOS",

    BUILD_CONSTANTS: {
        ENV: {
            DEV: "development",
            TEST: "test",
            PROD: "production",
        },
        CITI_ENV: {
            PROD: "https://mobile.citibusinessonline.com",
            RELATIVE: "/",
        },
    },

    //ContactUsVersion
    CONTACTUS_VERSION: "Version",
    CONTACTUS_DATE: "Date",
    CONTACTUS_ENDPOINT: "Endpoint",

    //Mobile Token Management
    MOBILE_TOKEN_MANAGEMENT: {
        HEADER: "Mobile Token Management",
        HEADER_2: "Mobile Token Registration Summary",
        HEADER_CURRENT_ACTIVE: "Currently Active",
        HEADER_AVAILABLE_USE: "Available for Use",
        NOTE_HEADER: "Please Note",
        NOTE_CONTENT:
            "You can only register 10 mobile token at one time. Please delete the registrations that you are no longer using.",
        BTN_VIEW_DETAILS: "View Details",
        BTN_CANCEL: "Close",
        BTN_DELETE_TOKEN: "Delete",
        HEADER_ACTIVE_REGISTRATIONS: "Active Registrations",
    },

    REMOTE_CHECK_DEPOSITS: {
        BTN_COMPLETE_DEPOSIT: "Complete Deposit",
        BTN_CANCEL: "Cancel",
        BTN_SELECT_AN_ACCOUNT: "Select a Account",
        BTN_TAP_TO_CHANGE: "Tap to Change Account",
        TAP_TO_TAKE_PHOTO: "Tap to take photo of your check",
        MENU_DEPOSIT_CHECK: "Deposit Check",
        MENU_DEPOSIT_HISTORY: "Deposit History",
        LABEL_DEPOSIT_TO: "Deposit to",
        LABEL_CHECK_IMAGE: "Check Image",
        LABEL_ENTER_AMOUNT: "Enter Amount",
        LABEL_MEMO: "Memo (Optional)",

        CHECK_DEPOSIT_SUCCESS_MODAL_TEXT: "Your check has been deposited.",
    },

    POSITIVE_PAY_EXCEPTIONS: {
        NON_DISPOSITIONED_MESSAGE: 'There are no exception items for today.',
        DISPOSITIONED_MESSAGE: 'No items have been dispositioned today.'
    },

    CURRENCY_CONVERTER: {
        FROM: 'From',
        TO: 'To',
        AMOUNT: 'Amount',
        CALCULATE: 'Calculate',
        EXCHANGE_FROM_TO_CURRENCY: 'Exchange From and To Currency',
        SELECT_FROM_CURRENCY: 'Select From Currency',
        SELECT_TO_CURRENCY: 'Select To Currency',
    }
};

export default Constants;
