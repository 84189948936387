import { useState, useEffect } from "react";
import __constants from '../../common/constants';
import __req from '../../api/messages.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import './message-text-page.style.scss';
import { Drawer } from "@citi-icg-172888/icgds-react";
import { renderError, renderLoader } from "../common-ui/common-ui.components";
import _ from "lodash";
import Button from "../../ui-kit/button/button";
import Alert from "../../ui-kit/alert/alert.component";
import { Loading } from "@citi-icg-172888/icgds-react";
import { addLogoutTimer } from '../../utils/timer.util';

interface Props {
    show: boolean;
    messageData: any;
    savedMessages: boolean,
    displayIndex: string,
    onClose?: Function,
}

function MessageTextPage(p: Props) {
    const {
        to,
        referenceNumber,
        key,
        index,
        from,
        text,
        messageDate
    } = p.messageData;

    const [showLoading, setShowLoading] = useState(true);
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');
    const [responseBody, setResponseBody] = useState<any>();
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        _sendReqForMessageText();

        //focus to header on page load
        setTimeout(() => {
            const headerEle: HTMLElement | null = document.querySelector('.detail-header-container');
            if (headerEle) {
                headerEle.focus();
            }
        }, 150);
    }, []);

    //After delete.save success response, focus to status alert
    useEffect(() => {
        const alertContainerEle: HTMLElement | null = document.querySelector(".alert-container");
        if (alertContainerEle) {
            alertContainerEle.setAttribute("tabIndex", "0");
            alertContainerEle.focus();
        }
    }, [alertText]);

    function userAction() {
        window.LOGOUT_TIMER_REACT = addLogoutTimer();
    }

    /**
     * API calls
     */
    function _sendReqForMessageText() {
        userAction();

        const params = {
            "referenceNumber": referenceNumber,
            "key": key,
            "index": index,
            "savedMessages": p.savedMessages,
            "displayIndex": p.displayIndex
        };

        console.log("Loading");
        __req
            .citibankMessageTextPage(params, _handleMessageTextResp)
            .then((resp) => {
                console.log("Loading false");
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleMessageTextResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setShowLoading(false);
                setErrorInfo(__constants.REQ_FAILED);
            });
    }

    function _handleMessageTextResp(resp: { [key: string]: any }) {
        setShowLoading(false);

        if (resp && resp.success && resp.body) {
            setResponseBody(resp.body);
        }
    }

    const onDeleteClick = () => {
        userAction();

        const params = {
            "referenceNumber": referenceNumber,
            "key": key,
            "index": index,
            "savedMessages": p.savedMessages,
            "displayIndex": p.displayIndex
        };

        setIsActionInProgress(true);
        console.log("Loading");
        __req
            .citibankMessageDeletePage(params, _handleMessageDeleteResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleMessageDeleteResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setIsActionInProgress(false);
                setErrorInfo(__constants.REQ_FAILED);
            });
    }

    function _handleMessageDeleteResp(resp: { [key: string]: any }) {
        setIsActionInProgress(false);

        if (resp && resp.success && resp.body) {
            setAlertText(resp.body.status);
        }
    }

    const onSaveClick = () => {
        userAction();

        const params = {
            "referenceNumber": referenceNumber,
            "key": key,
            "index": index,
            "savedMessages": p.savedMessages,
            "displayIndex": p.displayIndex
        };

        setIsActionInProgress(true);
        console.log("Loading");
        __req
            .citibankMessageSavePage(params, _handleMessageSaveResp)
            .then((resp) => {
                console.log("Loading false");
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleMessageSaveResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setIsActionInProgress(false);
                setErrorInfo(__constants.REQ_FAILED);
            });
    }

    function _handleMessageSaveResp(resp: { [key: string]: any }) {
        setIsActionInProgress(false);

        if (resp && resp.success && resp.body) {
            setAlertText(resp.body.status);
        }
    }

    //UI-------
    const onCloseClick = (e: any) => {
        if (p.onClose) {
            //Refresh message list in case of delete/save success
            p.onClose(alertText !== '');
        }
    }

    const _renderHeader = () => {
        return ( responseBody !== undefined && <>
            {isActionInProgress ?
                <div className="detail-header-loading">
                    <Loading />
                    <span className="detail-header-text">Loading...</span>
                </div>
                :
                <div className="detail-header-container"
                    tabIndex={0}
                    aria-label={`${responseBody.header}: ${from} ${text}: ${responseBody.subject}`}>
                    <span aria-hidden="true" className="detail-header-text">{responseBody.header}: {from}</span>
                    <span aria-hidden="true" className="detail-header-text">{responseBody.subject}: {text}</span>
                </div>
            }
        </>)
    }

    //TODO----------------------------
    const _renderFooter = () => {
        return (
            responseBody !== undefined && responseBody.tokenValidated &&
            <div>
                <div className="message-footer-button-wrap">
                    {alertText !== '' ?
                        <Button color={'primary'}
                            style={{ flex: 1 }}
                            tabIndex={0}
                            onClick={onCloseClick}>
                            Close
                        </Button>
                        :
                        <>
                            <Button outline
                                disabled={isActionInProgress}
                                tabIndex={0}
                                style={{ color: '#0066b3', borderColor: '#0066b3', flex: 1, margin: '0px 10px' }}
                                onClick={onDeleteClick}>
                                {responseBody.delete}
                            </Button>
                            <Button outline
                                disabled={isActionInProgress}
                                tabIndex={0}
                                style={{ color: '#0066b3', borderColor: '#0066b3', flex: 1, margin: '0px 10px' }}
                                onClick={onSaveClick}>
                                {responseBody.save}
                            </Button>
                        </>
                    }
                </div>
            </div>
        )
    }

    const _renderMessageData = () => {

        const messageDataArray = {
            "TO": to,
            "Date": messageDate,
            "Ref#": referenceNumber
        };

        return <div className="message-data-container">
            <div className="message-data-body">
                {
                    _.map(messageDataArray, (val: string, key: string) => {
                        return (
                            <div className="info-item"
                                tabIndex={0}
                                key={key}
                                aria-label={`${key} ${val.toString()}`}>
                                <div aria-hidden="true" className="info-key">{key}</div>
                                <div aria-hidden="true" className="info-val">{val.toString()}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    }

    const _renderMessageText = () => {
        const messageText = responseBody.messages !== undefined ? responseBody.messages.join("") : '';

        return (
            <div className="message-text-container">
                <textarea
                    aria-label={messageText}
                    tabIndex={0}
                    className="text-medium select-label"
                    readOnly>
                    {messageText}
                </textarea>
            </div>
        )
    }

    const _renderAlert = () => {
        return alertText !== '' && <Alert type={'success'!} content={alertText} ariaNotifyDisabled />
    }

    const _renderBody = () => {
        return (
            <div className="body-container">
                {
                    _renderAlert()
                }
                <div className="message-detail-container">
                    <div className="message-detail">
                        {_renderMessageData()}
                        {_renderMessageText()}
                    </div>

                </div>
            </div>)
    }

    return (
        <>
            <Drawer
                title={_renderHeader()}
                height="80%"
                className={'common-txn-detail-drawer'}
                placement={'bottom'}
                enableBodyScroll
                closable
                onClose={onCloseClick}
                visible={p.show}
                footer={(!(showLoading || errorInfo !== '')) && _renderFooter()}
            >

                {
                    showLoading
                        ? renderLoader('Gathering details...', 10)
                        : (errorInfo !== ''
                            ? renderError(errorInfo)
                            : _renderBody()
                        )
                }

            </Drawer>
        </>
    );
}

export default MessageTextPage;