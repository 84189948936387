import __constants from '../../common/constants';
import LeftMenuAccordion from "../../ui-kit/left-menu-accordion/left-menu-accordion.component";

const CookiePolicy = () => {

    const cookiePolicy = [
        {
            header: __constants.COOKIE_POLICY,
            content: __constants.COOKIE_POLICY_LINK.COOKIE_POLICY
        }
    ];


    const createUI = () => {
        return <>
            <LeftMenuAccordion accordionContent={cookiePolicy} />
        </>
    }
    return createUI();
}

export default CookiePolicy;