const CookieConsentScriptLoader = (env: string, callBack: Function) => {

    let url = "";
    let dataDomain = "3c3e7186-e6ab-4056-95e2-73cf7756503f";


    if (env === 'uat') {
        url = `https://uat.citigroup.com/global/cookieconsent/static/eppublic/cookieConsent.js`;
        dataDomain = "242b211a-f754-47a8-a12e-7756410fb3b0";
    } else if (env === 'qstg') {
        url = `https://uat.citigroup.com/global/cookieconsent/static/eppublic/cookieConsent.js`
        dataDomain = "2b41d671-e181-4d0e-a7c1-4fecd06bd6ef"
    } else {
        // prod
        url = `https://www.citigroup.com/global/cookieconsent/static/eppublic/cookieConsent.js`
    }

    const cScript = document.getElementById("cookieConsentScript");

    if (url === undefined || url === null || url.trim().length <= 0) {
        console.error('Invalid cookie-consent url. Failed to initialize cookie-consent');
        if (callBack) {
            callBack(false, 'fatal');
        }
        return;
    }

    if (!cScript) {
        const script = document.createElement('script');
        script.src = url;

        script.id = 'cookieConsentScript';
        script.setAttribute('data-domain-script', dataDomain);
        script.setAttribute("charset", "UTF-8");

        document.body.appendChild(script);


        script.onload = () => {
            if (window.OneTrust != null) {
                // window.BioCatchApi = window.cdApi;
                console.log('Cookie Consent Script initialized.');
            }
            if (callBack) callBack(true, 'success');
        }

    }

    if (cScript && callBack) callBack(true, 'alive');

}


export const CookieBlockerScriptLoader = (callBack: Function) => {

    const url = `https://${window.location.host}/js/17_0/cookieBlocking.js`;

    const cScript = document.getElementById("cookieBlockingScript");

    if (url === undefined || url === null || url.trim().length <= 0) {
        console.error('Invalid cookie-blocking url. Failed to initialize cookie-blocking');
        if (callBack) {
            callBack(false, 'fatal');
        }
        return;
    }

    if (!cScript) {
        const script = document.createElement('script');
        script.src = url;

        script.id = 'cookieBlockingScript';
        // script.setAttribute('data-domain-script', dataDomain);
        // script.setAttribute("charset", "UTF-8");

        document.body.appendChild(script);


        script.onload = () => {
            if (window.consentedCookieCategories !== undefined && Array.isArray(window.consentedCookieCategories)) {
                // window.BioCatchApi = window.cdApi;
                console.log('Cookie Blocking Script initialized.');
            }
            if (callBack) callBack(true, 'success');
        }

    }

    if (cScript && callBack) callBack(true, 'alive');

}


export default CookieConsentScriptLoader;
