import { useEffect, useState } from "react";
import { Modal } from "@citi-icg-172888/icgds-react";
import Button from "../../../ui-kit/button/button";
import __constants from "../../../common/constants";
import "./confirmation-modal.style.scss"

interface ConfirmationProps {
    userName: string,
    title: string,
    show: boolean,
    onContinue?: Function,
    continueButtoTitle?: string,
    onCancel?: Function,
    cancelButtoTitle?: string,
    showLoading: boolean,
    cancellable?: boolean
}

const ConfirmationModal = (p: ConfirmationProps) => {
    const [isCancellable, setIsCancellable] = useState(true);

    useEffect(() => {
        setIsCancellable((p.cancellable !== undefined ? p.cancellable : true))
    }, [p.cancellable]);

    const onContinueClick = () => {
        if (p.onContinue) p.onContinue()
    }

    const onCancelClick = () => {
        if (p.onCancel) p.onCancel()
    }

    const renderTitle = () => {
        return <>

            <div
                className="title-container"
                tabIndex={0}
                aria-label={`${p.userName.length > 0 ? p.userName : ''} ${p.title}`}>
                <span aria-hidden="true">{p.userName.length > 0 ? p.userName : null}</span>
                <p aria-hidden="true" className='text-small confirmation-text'>{p.title}</p>
            </div>
        </>
    }

    const uiRenderFooter = () => {

        return <div className="footer-wrap">
            <div className="footer-button-wrap">
                {isCancellable &&
                    <Button outline
                        disabled={p.showLoading}
                        style={{ color: '#0066b3', borderColor: '#0066b3' }}
                        onClick={onCancelClick}>{p.cancelButtoTitle ? p.cancelButtoTitle : __constants.CANCEL}
                    </Button>
                }
                <Button color={'primary'}
                    disabled={p.showLoading}
                    onClick={onContinueClick}
                    showLoading={p.showLoading}
                >
                    {p.continueButtoTitle ? p.continueButtoTitle : __constants.CONTINUE}
                </Button>
            </div>
        </div>
    }

    const ui = () => {
        return <Modal
            centered
            visible={p.show}
            width='80%'
            wrapperClass="confirmation-modal-container"
            title={renderTitle()}
            closable={false}
            footer={uiRenderFooter()}>
        </Modal>
    }


    return ui();
}


export default ConfirmationModal;