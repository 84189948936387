import __httpApi from './api.core';
import { RequestMethod } from './api.core';
import __constants from '../common/constants';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { getUserAgent1 } from '../utils/api.util';
import { DEVICE_TYPE } from '../device/xdevice';
// import  
let WIRES_MOCKS: any
//  from './mocks/wires.mock';

export interface WiresResponse {

}

export class WiresApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        // const file  = 
        // const mockPath = isProd ? './mocks/prod.mock.ts':'./mocks/wires.mock';
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/wires.mock.ts').default
        WIRES_MOCKS = m
    }

    wiresDetialsPage(params: { [key: string]: any }, callBack?: Function) {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    data: params,
                    config: {},
                    headers: {},
                    status: 200,
                    statusText: "OK",
                })
            }, 300)
        });
    }

    plannedAndPendingWiresListPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfers/retrieve/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'plannedAndPendingWiresListPage',
            WIRES_MOCKS?.PLANNED_PENDING_LIST, null, 120000) as Promise<AxiosResponse<WiresResponse>>;
    }
    actionOnWireTransaction(refId: string, action: 'SUSPEND' | 'REACTIVATE' | 'CANCEL', callBack?: Function) {
        const payload = {
            transferReferenceNumber: refId,
            transferIndicator: "WIRE_TRANSFER",
            actionType: action
        }
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}/transfers/pendingActivity/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            payload,
            callBack,
            __constants.ChannelEnum.WIRES,
            'actionOnWireTransaction',
            WIRES_MOCKS?.ACTION_SUSPEND_TRANSACTION) as Promise<AxiosResponse<WiresResponse>>;
    }


    completedWiresPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfers/retrieve/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'completedWiresPage',
            WIRES_MOCKS?.COMPLETED_LIST_PAGE_2, null, 120000) as Promise<AxiosResponse<WiresResponse>>;
    }



    accountsInformationPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}user/accounts/information/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'accountsInformationPage',
            WIRES_MOCKS?.ACCOUNTS) as Promise<AxiosResponse<WiresResponse>>;
    }
    // WIRE Initiate

    setUpWireAccountPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}setUpWireAccountPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireAccountPage',
            WIRES_MOCKS?.WIRE_ACCOUNT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpWireSchedulingAmountPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}setUpWireSchedulingAmountPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireSchedulingAmountPage',
            WIRES_MOCKS?.WIRE_SCHEDULING_AMOUNT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpWireBeneficiaryPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireBeneficiaryPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireBeneficiaryPage',
            WIRES_MOCKS?.WIRE_BENEFICIARY_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }


    //https://mobileuat.citibusinessonline.com/setUpWireGetBankFromABA/16/en
    //{aba:"123456789"}
    getBankFromABA(aba: string, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireGetBankFromABA/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            { 'aba': aba },
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireUSBankSearchPage',
            WIRES_MOCKS?.WIRE_SEARCH_ABA) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{stateToSearch: "CO", searchFilterName: "J.P", searchFilterCondition: "CONTAINS"}
    setUpWireUSBankSearchPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireUSBankSearchPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireUSBankSearchPage',
            WIRES_MOCKS?.WIRE_US_SEARCH_BANK_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"key":"212d207b-ffe9-414c-bfcd-cf3cf09ebebe","futureDate":"3/1/2023","startDay":1,"otherDay":null,"transferCount":"","stopDate":"","timeEnum":"NONE","scheduleTypeEnum":"IMMEDIATE","frequencyTypeEnum":"IMMEDIATE","fixedAmount":"1.00","flextAmountTypeEnum":"NON_FLEX"}
    setUpWireConfirmTransferPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireConfirmTransferPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireConfirmTransferPage',
            WIRES_MOCKS?.WIRE_CONFIRM_TRANSFER_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"citiKey":"b0e3303d-c74e-4afc-bb99-df8920528d42","transferAccountResponseVo":{"accountName":"ACH","accountType":"CHECKING","accountTypeName":"Checking","balance":3557.36,"balanceAvailable":true,"fimp":"000","key":"212d207b-ffe9-414c-bfcd-cf3cf09ebebe","maskedNumber":"-2312","personalAccount":false},"beneficiaryVo":{"checkOrWireEnum":"WIRE_TRANSFER","beneficiaryName":"Test","beneficiaryAddress1":"No address","beneficiaryAddress2":"No address","beneficiaryAddress3":"","beneficiaryPhone":"1234567890","beneficiaryAccount":"123456789"},"bankVo":{"bankTypeEnum":"US_BANK","aba":"307070021","bankName":"AIR ACADEMY FCU","bankAddress":"","bankCity":"Colorado","bankState":"Colorado","bankStateCode":"CO","beneCurr":" ","bankInfo":"B"},"specialInstructionsVo":{"specialInstruction1":"","specialInstruction2":"","specialInstruction3":"","statementDesc":"","customerReferenceNumber":"","additionalReference":""},"wireScheduleVo":{"scheduleTypeEnum":"IMMEDIATE","futureDate":"3/1/2023","frequencyTypeEnum":"IMMEDIATE","startMonth":2,"startDay":1,"otherDay":null,"transferCount":"","stopDate":"","timeEnum":"NONE"},"countryCurrencyVo":{"currencyCodePlus":"USDT","currencyCountry":"USUSD","destinationaCountry":"US"},"wireAmountVo":{"amountType":"fixed","fixedAmount":"1.00","amountDesignationEnum":"AS_USD","flextAmountTypeEnum":"NON_FLEX"},"passwordRequired":true}
    setUpWireSubmitPage(params: { [key: string]: any }, callBack?: Function, akamaiData?: string) {
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                "x-acf-sensor-data": akamaiData,
                "user-agent1": getUserAgent1()
            }
        }

        let fixedAmount = params?.wireAmountVo?.fixedAmount;
        if (_.isString(fixedAmount) && fixedAmount.includes(',')) {
            fixedAmount = fixedAmount.replace(/,/g, '');

            const wireAmountVo = { ...params.wireAmountVo, fixedAmount };
            params = { ...params, wireAmountVo }
        }

        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireSubmitPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            headers,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireSubmitPage',
            WIRES_MOCKS?.WIRE_SUBMIT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //Wire using Model

    setUpWireUsingModelPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireUsingModelPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireUsingModelPage',
            WIRES_MOCKS?.WIRE_USING_MODEL_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpWireConfirmTransferUsingModelPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireConfirmTransferUsingModelPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireConfirmTransferUsingModelPage',
            WIRES_MOCKS?.WIRE_CONFIRM_TRANSFER_USING_MODEL_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"request":"send","password":"Welcome4","token":"123456","citiKey":"d2932a43-24cf-4c31-8bb6-5c427b4b9888","autoGenerateSoftToken":false,"autoTokenCode":"-1","clientType":"browser"}
    setUpWireInstructionSummaryPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireInstructionSummaryPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.WIRES,
            'setUpWireInstructionSummaryPage',
            WIRES_MOCKS?.WIRE_SETUP_INSTRUCTION_SUMMARY_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

}

const __instance__ = new WiresApi();

export default __instance__;
