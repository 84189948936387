import _ from 'lodash';
import { Drawer } from '@citi-icg-172888/icgds-react';

interface PushNotifContentProps {
    title: string;
    data: { [key: string]: any };
    onClose: Function;
}

const PushNotifContent = (props: PushNotifContentProps) => {
    const {
        title,
        data,
        onClose
    } = props;

    return <Drawer
        title={title}
        height='80%'
        visible={true}
        maskClosable={false}
        wrapperClass='notif-wrapper'
        onClose={() => onClose()}
        placement='bottom'
    >
        <div style={{ display: 'flex', height: '30px' }}>
            <div className='text-medium' style={{ flex: 1, textAlign: 'left' }}>From</div>
            <div className='text-medium' style={{ flex: 1, textAlign: 'left' }}>UAT - INCOMING WIRE</div>
        </div>
        <div style={{ display: 'flex', height: '30px' }}>
            <div className='text-medium' style={{ flex: 1, textAlign: 'left' }}>Global ID</div>
            <div className='text-medium' style={{ flex: 1, textAlign: 'left' }}>N1900010101234</div>
        </div>
    </Drawer>
}

export default PushNotifContent;