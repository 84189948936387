import "./fdic-logo.style.scss";
import FDIC from "../../assets/images/FDIC.png";

const FDICLogoComponent = () => {

    const FDICImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAQCAYAAAB6Hg0eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJYSURBVHgBrVZNbtpAFP7eGDWRunFv4N7ADV10F+cE7SqwCz1BxQkKJ0h6grCEZFE4Qdx9sJwbODewUFVVKMz0je0mMx5sFMQnjbHnffP8eeb9QOj2rtEKtQDoszNNKoekRwhKcT+NLVv4xYd3dPnCRY772dDiRMxZHQ3YdgqFsOJl7DdFR/zoQKkB2vHI4lyOqi6Sx8k5O8QQy5t5aTz2oeTA4GZ8fRHWPb/AClds8Es/z7yAR4T15kHgMNAOf7LA7zuZmqMw4Tu/jXYoYf8xwsd+1GgtbSNnnpCCKOa7HI3CkhuyhhBxjZEV83L9jpd/Za+ZZVWqedekunZ8CXHGIfABy9kZ+y19Ci/rYF+kc/11E96FjF94ZwiLgE3gfLPeLSkDS5RkUck0s3jJdKJ/XGFmllKxtQu0QWdktxeXgioIETk8qUJ7go8urYky4Aozs1Rnk6B2YQVPPvCLImNdwB9VVxbAmpS/0IIDBT/lOymKalkoXpuVOpirQbXAbtRFp/azyrdwUvsZbtE24B5lMntvPbelv8anfoC1rHE8FiHtKd0hpFFNdUxq3/WuocFdYf+s1NCO11tKgETsnIUWcNLLWFHwPCclF+XeGJ4X4+2fDL+PQxb8DSu52EeYz1l4xw5CdrwlTtS4cSWpYdEhTF9Ql9g8Aas3MHZ5sU/w+1Vp2Ba8YyS3k8aVZS9tFm7gUC2Jg52GXLlHO5kFh7loSSyivFP1qGbQJgeJeKsYJflvjzfH09+06gSmc3NNZtmS2RXC/pwTIuLjvSjqXrGGe6aSCyxv5/8AkILrfuKKdx0AAAAASUVORK5CYII="
    
    const createUI = () => {
        return (
            <>
                <div className="FDIC-container">
                    <div
                        className="lmn-d-flex lmn-align-items-center"
                        style={{ gap: "8px", marginBottom: "4px" }}
                    >
                        <img alt="FDIC image" src={FDIC} style={{ width: "70px" }} />
                        <div
                            className="lmn-font-italic lmn-font-weight-normal fdic-text"
                        >
                            FDIC-Insured - Backed by the full faith
                            <br /> and credit of the U.S. Government
                        </div>
                    </div>
                    <div>
                        <div
                            className="lmn-font-weight-normal fdic-text citi-line-height"
                        >
                            Citibank, N.A.
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return createUI();
};
export default FDICLogoComponent;
