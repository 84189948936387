import _ from 'lodash';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import device from '../device/xdevice';
import { DEVICE_TYPE } from '../device/xdevice';
import BUILD_UTIL from '../utils/build.util';
import __constants from '../common/constants';
import { analytics } from '../utils/piwik.util';
import { analyticsBranded } from '../utils/piwik.util';
import { getShortEventLabel } from '../utils/piwik.util';
import { logOut } from '../utils/common.util';
import { interceptAllRequest, interceptAllResponse, interceptErrorResponse } from './interceptors/response.interceptor';
import iosCallBackHash from '../context/ios-callback-hash.context';
import toastProvider from '../context/toast-provider.context';
import { compareVersion } from '../utils/common.util';

const MODEL = "model";
const MOCK_THROTTLE_DELAY = 5000;

export const WAIT_FOR_IOS_CALLBACK = 500165;

export enum RequestMethod {
    POST = "post",
    POST_FORM = "postForm",
    GET = "get",
    PUT = "put",
    OPTIONS = "options",
    DELETE = "delete"
}

export interface ResponseBase {
    success: boolean,
    genericError: any
}

export class HttpApi {

    http!: AxiosInstance;

    constructor() {
        this.http = axios.create({
            //baseURL: BUILD_UTIL.isProduction()?"/":"/",
            baseURL: "/",
            withCredentials: true,
            timeout:5000
        });
        this.http.interceptors.response.use(interceptAllResponse, interceptErrorResponse);
        this.http.interceptors.request.use(interceptAllRequest, interceptErrorResponse);
    }

    makeHttpRequest(method: RequestMethod,
        url: string,
        headers: any,
        body: any,
        callBack: Function = () => { },
        channel: string,
        callBackHash: string = "",
        mockResponse?: any,
        errorCallbackForIOS?: Function | null,
        timeout:number=55000
    ): Promise<AxiosResponse<any>> {

        //console.log(callBack.name);

        /*
        ----------------------------Start of Dev Capabilities-----------------------------
         */

        if (BUILD_UTIL.isDev()) {

            if (callBack.name === "callBack") {
                console.clear();
                console.warn('POTENTIAL iOS BUG DETECTED: Looks like you missed iOS callback for ', url);
                console.log('POTENTIAL iOS BUG DETECTED: Looks like you missed iOS callback for ', url);
                console.info('POTENTIAL iOS BUG DETECTED: Looks like you missed iOS callback for ', url);
            }
        }

        if (mockResponse && BUILD_UTIL.isDev()) {

            console.log('mocking response for url', url, 'with payload ', body);
            console.log('Payload', body);

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        data: mockResponse,
                        config: {},
                        headers: {},
                        status: 200,
                        statusText: "OK",
                    })
                }, MOCK_THROTTLE_DELAY)
            });
        }

        /*
        ----------------------------------End of Dev Capabilities------------------------
        */

        return new Promise((resolve, reject) => {
            this.makeHttpRequestFunction(method, url, headers, body, callBack, callBackHash, errorCallbackForIOS).then((resp) => {
                if (channel !== null && channel !== undefined && channel.length > 0) {
                    analytics(url, channel);
                    var index: number = 0;
                    if (url.startsWith("/")) {
                        index = 1;
                    }
                    var brokenUrlArray = url.split("/");
                    var menuCode = "/";
                    var shortEventLabel = "Page";
                    if (brokenUrlArray.length > 1) {
                        menuCode = brokenUrlArray[index]
                        shortEventLabel = getShortEventLabel(menuCode);
                    }
                    console.log('the shortEventLabel from makeHttpRequest is --> ' + shortEventLabel)
                    analyticsBranded(3, shortEventLabel, window.location.href);
                }
                resolve(resp);
            }).catch((error: any) => {
                if (BUILD_UTIL.isDev() || BUILD_UTIL.isTest()) {
                    reject(error);
                    return;
                }
                const scode = _.get(error, 'response.status', '');
                const index = _.indexOf([400, 404, 408, 500], scode);
                //for testing, I think this was breakking token regestration
                if(url.search("submitActivateTokenInstancePage")){
                    console.log("submitActivateTokenInstancePage error:");
                    reject(error);
                }
                if (index >= 0) {
                    logOut();
                    //reject(error);
                    return;
                }

                if (scode === 418) {
                    console.log('API ERROR:', error);
                    const timeout = _.get(error, 'uiDisplayTime', 3500);
                    const msg = _.get(error, 'message', "Something went wrong");
                    toastProvider.showToastUI(msg, timeout);
                    toastProvider.showToastUI("Something went wrong. Try again", 3500);
                }

                reject(error);
            })
        });
    }

    makeHttpRequestFunction(method: RequestMethod,
        url: string,
        headers: any,
        body: any,
        callBack: Function = () => { console.debug('No function configured'); },
        callBackHash: string = "",
        errorCallbackForIOS?: Function | null,
        timeout:number=55000
    ): Promise<AxiosResponse<any>> {

        // region for ios http
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS && !compareVersion('16')) {
            if (callBackHash !== "" && callBackHash.length > 3) {
                window.CALLBACK_POOL[callBackHash] = callBack;
                iosCallBackHash.addHash(callBackHash, url);
                //window.CALLBACK_POOL[callBackHash](response);
            } else {
                window.IOS_NETWORK_API_RESPONSE = callBack;
            }
            const errorCallback = (data: object) => {
                const scode: number = _.get(data, 'status', 0);
                const index = _.indexOf([400, 404, 408, 500], scode);
                console.log('iOS | send request failed - url: , scode:', url, scode);
                if (index >= 0) {
                    logOut();
                }
                if (scode === 418) {
                    // private String message;
                    // private int uiDisplayTime = 3500;
                    console.log('API ERROR:', data);
                    const timeout = _.get(data, 'uiDisplayTime', 3500);
                    const msg = _.get(data, 'message', "Something went wrong");
                    toastProvider.showToastUI(msg, timeout);
                }

                errorCallbackForIOS && errorCallbackForIOS(data);
            }
            window.IOS_NETWORK_API_RESPONSE_FAILURE = errorCallback;
            window.ERROR_CALLBACK_POOL[callBackHash.length > 3 ? callBackHash : 'defaultHash'] = errorCallback;

            //const device = new DeviceAPI()
            let baseUrl = this.http.defaults.baseURL;
            baseUrl = baseUrl === "/" ? window.location.origin : baseUrl;
            let newUrl = url.startsWith("/") ? url : "/" + url
            let json: { [key: string]: any } = { "url": baseUrl + newUrl, "parameter": body, "httpMethod": method, "backbone": false, "callBackHash": callBackHash };
            if (method === RequestMethod.POST_FORM) {// only for citibusinessMobileLogin
                device.sendFormData(callBack, errorCallback, json);
            }
            else {
                device.sendJsonData(callBack, errorCallback, json);
            }
            return new Promise((resolve, reject) => {
                resolve({
                    data: { ios: true },
                    config: {},
                    headers: {},
                    status: WAIT_FOR_IOS_CALLBACK,
                    statusText: "",
                })
            });
        }
        switch (method) {
            case RequestMethod.POST_FORM:
                const params = new URLSearchParams()
                params.append(MODEL, JSON.stringify(body));

                const deviceType = this.checkDeviceType(body)
                console.log("login device check: " + deviceType)
                switch (deviceType){
                    case "WEB":
                    case "APP_REGISTERED":
                    case "APP_REGISTERED_BIOMETRIC":
                    case "APP_UNREGISTERED":
                    case "APP_SWITCH_ACCOUNTS":
                        return this.http.post(url, params, { headers: headers, timeout:0 });
                    case "APP_REGISTERED_NO_OTP":
                    default:
                        return new Promise((resolve, reject) => {
                            reject({
                                data:"noOtp"
                            })
                        });
                }
            case RequestMethod.POST:
                // console.log(body);
                return this.http.post(url, body, { headers: headers, timeout: timeout });
            case RequestMethod.PUT:
                return this.http.put(url, body, { headers: headers, timeout: timeout });
            case RequestMethod.DELETE:
                return this.http.delete(url, { headers: headers, timeout: timeout });
            case RequestMethod.OPTIONS:
                return this.http.options(url, { headers: headers, timeout: timeout });
            case RequestMethod.GET:
                return this.http.get(url, { headers: headers, timeout: timeout });
            default: ;
                return this.http.get(url, { headers: headers, timeout: timeout });
        }
    }

    checkDeviceType(body:any):String{
        const transmitToken = _.get(body, "transmitToken", "");
        if (transmitToken.length > 0) {
            return "APP_REGISTERED_BIOMETRIC";
        }

        if(window.p86Cache == null)
            return "WEB"
        if(window.isSwitchAccounts===true)
            return "APP_SWITCH_ACCOUNTS"
        else if(window.p86Cache !== null && window.p86Cache.softTokenSerialNumber !== undefined && window.p86Cache.softTokenSerialNumber !== ""){
            const otp1 = _.get(body, 'j_tokencode', 0);
            if(otp1 !== undefined && otp1 !== "")
                return "APP_REGISTERED"
            else
                return "APP_REGISTERED_NO_OTP"
        }
        else 
            return "APP_UNREGISTERED"
    }

}

let httpApi = new HttpApi();

export default httpApi