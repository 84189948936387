import __httpApi from './api.core';
import { RequestMethod } from './api.core';
import __constants from '../common/constants';
import { AxiosResponse } from 'axios';

let SYSTEM_ADMINISTRATION_MOCKS: any

export interface SystemAdministrationResponse {

}

export class SystemAdministrationApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/system-administration.mock.ts').default
        SYSTEM_ADMINISTRATION_MOCKS = m
    }

    getUsersList(params: { [key: string]: any }, callBack?: Function) {
        /*Change mock response based on screen Issue new password/Suspend reactivate user
        SYSTEM_ADMINISTRATION_MOCKS?.USERS_LIST
        SYSTEM_ADMINISTRATION_MOCKS?.USERS_LIST_SUSPEND_REACTIVATE_USER
        */
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}userListPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'userListPage',
            SYSTEM_ADMINISTRATION_MOCKS?.USERS_LIST_SUSPEND_REACTIVATE_USER) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    issueNewPassword(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}issueNewPasswordConfirmPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'issueNewPasswordConfirmPage',
            SYSTEM_ADMINISTRATION_MOCKS?.ISSUE_NEW_PASSWORD_SUCCESS) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    updateSuspendUserOldDetails(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}updateSuspendUserOldDetails/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'updateSuspendUserOldDetails',
            SYSTEM_ADMINISTRATION_MOCKS?.UPDATE_SUSPEND_USER_OLD_DETAILS) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    submitUpdateSuspendUserNewDetails(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}submitUpdateSuspendUserNewDetails/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'submitUpdateSuspendUserNewDetails',
            SYSTEM_ADMINISTRATION_MOCKS?.SUBMIT_UPDATE_SUSPEND_USER_NEW_DETAILS) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    /**
 * 
 * @returns get call for makerCheckerApprovalsPage
 */

    makerCheckerApprovalsPage(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}makerCheckerApprovalsPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            null,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'makerCheckerApprovalsPage',
            SYSTEM_ADMINISTRATION_MOCKS?.APPROVALS?.MAKERCHECKERAPPROVALS) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    /**
     * 
     * @returns post call for makerCheckerDetailsPage
     */

    makerCheckerDetailsPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}makerCheckerDetailsPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'makerCheckerDetailsPage',
            SYSTEM_ADMINISTRATION_MOCKS?.APPROVALS?.MAKERCHECKERDETAILS) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }

    /**
    * 
    * @returns post call for makerCheckerDetailsSubmitPage
    */

    makerCheckerDetailsSubmitPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}makerCheckerDetailsSubmitPage/${__constants.APP_VERSION_DIR}/${__constants.LANG}`,
            null,
            params,
            callBack,
            __constants.ChannelEnum.SYSTEM_ADMINISTRATION,
            'makerCheckerDetailsSubmitPage',
            SYSTEM_ADMINISTRATION_MOCKS?.APPROVALS?.MAKERCHECKERDETAILSSUBMIT) as Promise<AxiosResponse<SystemAdministrationResponse>>;
    }
}

const systemAdministrationApi = new SystemAdministrationApi()

export default systemAdministrationApi;
